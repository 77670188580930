import { createSlice } from "@reduxjs/toolkit";

const adminSlice = createSlice({
  name: "adminStore",
  initialState: {
    backendPagination: {
      page_Index:0,
      total_pages:0,
      records_displayed:0,
      total_elements:0,
    },
    dynamicUser: {},
    userFunctionalGroups: [],
    userModuleAuthorities: [],
    administrators: [],
    error: "",
    is_authenticated: false,
  },
  reducers: {    
    replaceOutdatedAdmin: (state, actions) => {
      let res = state.administrators.findIndex(
        (admin) => admin.administrator_id === actions.payload.administrator_id
      );
      state.administrators.splice(res, 1, actions.payload);
    },
    setAdministratorBackendPagination: (state, actions) => {
      state.backendPagination = actions.payload
    },
    updateFirstName: (state, action) => {
      state.dynamicUser.first_name = action.payload;
    },
    updateLastName: (state, action) => {
      state.dynamicUser.last_name = action.payload;
    },
    updateEmail: (state, action) => {
      state.dynamicUser.primary_email = action.payload;
    },
    updatePhone: (state, action) => {
      state.dynamicUser.primary_phone_number = action.payload;
    },
    updateUsername: (state, action) => {
      state.dynamicUser.username = action.payload;
    },
    removeAdministrator: (state, action) => {
      state.administrators = state.administrators.filter(
        (administrator) => administrator.administrator_id !== action.payload
      );
      state.total_elements -= 1;
    },
    removeFunctionalGroupAdmin: (state, action) => {
      state.dynamicUser.functional_groups =
        state.dynamicUser.functional_groups.filter(
          (fg) => fg.functional_group_name !== action.payload
        );
    },
    updateAuthority: (state, action) => {
      let index = state.dynamicUser.module_authorities
        .map((e) => e.module_name)
        .indexOf(action.payload);
    },
    updateAdministratorDetails: (state, action) => {
      state.administrators = state.administrators.map((administrator) => {
        if (
          action.payload.administrator_id === administrator.administrator_id
        ) {
          state.dynamicUser = action.payload;
        }
      });
    },
    addFunctinalGroups: (state, action) => {
      if (state.userFunctionalGroups.includes(action.payload)) {
        let index = state.userFunctionalGroups.indexOf(action.payload);
        state.userFunctionalGroups.splice(index, 1);
      } else {
        state.userFunctionalGroups.push(action.payload);
      }
    },
    setAdministrators: (state, action) => {
      for (let index = 0; index < action.payload.length; index++) {
        const element = action.payload[index];
        if (
          !state.administrators.some(
            (e) => e.administrator_id === element.administrator_id
          )
        ) {
          state.administrators.push(element);
        }
      }
    },
    setAdminFunctionalGroups: (state) => {
      if (state.dynamicUser.hasOwnProperty("functional_groups")) {
        state.userFunctionalGroups.push(
          ...state.dynamicUser.functional_groups.map(
            (functional_group) => functional_group.functional_group_name
          )
        );
      }
    },
    addModuleAuthorities: (state, action) => {
      if (state.userModuleAuthorities.includes(action.payload)) {
        let index = state.userModuleAuthorities.indexOf(action.payload);
        state.userModuleAuthorities.splice(index, 1);
      } else {
        state.userModuleAuthorities.push(action.payload);
      }
    },
    setCurrentAdministrator: (state, action) => {
      state.dynamicUser = action.payload;
    },
    setAuthenticationLogs:(state, action) => {
      state.authenticationLogs = action.payload;
    },
    setModuleStatus: (state) => {
      state.modulesStatus = "completed";
    },
    updateAdminList: (state, actions) => {
      state.administrators.splice(0, 0, actions.payload);
      state.total_elements += 1;
    },
  },
});

export const {
  resetDynamicUser,
  replaceOutdatedAdmin,
  replaceOutdatedAccountOfficer,
  appendAccountOfficer,
  resetDynamicStatus,
  updateAdminList,
  callAccountOfficers,
  setPage,
  setTotalPages,
  setTotalDataElements,
  setShareholderAccountOfficers,
  addFName,
  addLName,
  addPrimaryEmail,
  addSecondaryEmail,
  addPrimaryPhone,
  addSecondaryPhone,
  addPrimaryAddress,
  addSecondaryAddress,
  addAUsername,
  addOfficerCode,
  addFirstName,
  addShareholderPayMode,
  addShareholderCode,
  updateFirstName,
  addLastName,
  updateLastName,
  addEmail,
  updateEmail,
  addCompanyID,
  setDynamicUser,
  setShareholderQrCode,
  setAuthenticationLogs,
  setAdminFunctionalGroups,
  setShareholderHistory,
  addFunctinalGroups,
  addModuleAuthorities,
  addPhone,
  updatePhone,
  addUsername,
  updateUsername,
  setModuleStatus,
  setCurrentCountry,
  removeAdministrator,
  removeFunctionalGroupAdmin,
  updateAdministratorDetails,
  setShareholders,
  setAdministrators,
  setAccountOfficerStatus,
  setAccountOfficers,
  removeAccountOfficer,
  setExcelFile,
  setExcelFileError,
  setExcelData,
  setFileName,
  setFileType,
  removeShareholder,
  addShareholderName,
  addShareholderTaxRate,
  addShareholderOtherName,
  addShareholderComNumber,
  addShareholderScd,
  addShareholderPrimaryPhone,
  addShareholderPrimaryEmail,
  addShareholderSecondaryPhone,
  addShareholderSecondaryEmail,
  addShareholderPaymentMode,
  addShareholderPaymentAccount,
  addShareholderPaymentAccountName,
  addShareholderPaymentBranchName,
  addShareholderPaymentBankName,
  addShareholderPaymentBranchAddress,
  addShareholderTin,
  addShareholderComment,
  addShareholderCategory,
  addBrokerCode,
  addShares,
  searchShareholders,
  setAdministratorBackendPagination
} = adminSlice.actions;

export default adminSlice.reducer;
