import {
  Delete,
  Download,
  Edit,
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
  RemoveRedEye,
  Report,
  Upload,
} from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputBase,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  alpha,
  styled,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import * as XLSX from "xlsx";

import { green, orange, red } from "@mui/material/colors";
import { API } from "../../config/API";
import { checkAuthorities } from "../../service/securityService";
import {
  ShareholdersAdvancedSearch,
  getAllShareholders,
} from "../../service/userService";
import {
  openAlert,
  setAlertMessage,
  setAlertTitle,
} from "../../store/alertSlice";
import {
  removeShareholder,
  setDynamicUser,
  setExcelData,
  setExcelFile,
  setExcelFileError,
  setFileName,
  setFileType,
  setPage,
  setShareholder,
  setShareholderBackendPagination,
  setShareholders,
} from "../../store/userSlice";
import AlertModal from "../alertModal";
import { ShareholderSelect } from "../forms/FormComponents";

const TablePaginationActions = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { count, page, rowsPerPage, onPageChange } = props;
  const userState = useSelector((state) => state.user);

  const userBackendPagination = userState.backendPagination;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    if (page >= Math.ceil(count / rowsPerPage) - 1) {
      getAllShareholders({
        size: requiredResults,
        pageIndex: userBackendPagination.page_Index + 1,
      }).then((response) => {
        dispatch(setShareholders(response.data.data));
        dispatch(
          setShareholderBackendPagination({
            page_Index: response.data.page_index,
            total_pages: response.data.total_pages,
            records_displayed: response.data.records_displayed,
            total_elements: response.data.total_elements,
          })
        );
      });
    }
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={
          page >=
          Math.ceil(userBackendPagination.total_elements / rowsPerPage) - 1
        }
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
      </IconButton>
    </Box>
  );
};

const requiredResults = 20;
const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const ListShareholders = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchField, setSearchField] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searching, setSearching] = useState(false);
  const [advanceSearched, setAdvanceSearched] = useState(false);
  const [advanceSearching, setAdvanceSearching] = useState(false);
  const [backendPagination, setBackendPagination] = useState({
    page_Index: null,
    total_pages: null,
    records_displayed: null,
    total_elements: null,
  });
  const [downloading, setdownloading] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const alert = useSelector((state) => state.alert);
  const user = useSelector((state) => state.user);
  const userBackendPagination = user.backendPagination;

  const shareholders = user.shareholders;
  const page = user.page_index;
  const headers = ["Shareholder Id", "Name", "Type", "Email", "Phone"];

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseDialog = () => setOpen(false);

  const fields = [
    "shareholder_code",
    "shareholder_scd_number",
    "shareholder_com_number",
    "shareholder_name",
    "broker_code",
    "primary_phone_number",
    "primary_email",
    "primary_address",
    "created_by",
    "created_on",
  ];

  useEffect(() => {
    checkAuthorities({
      can_create: "SHAREHOLDER_MODULE:CAN_CREATE",
      can_read: "SHAREHOLDER_MODULE:CAN_READ",
      can_update: "SHAREHOLDER_MODULE:CAN_UPDATE",
      can_delete: "SHAREHOLDER_MODULE:CAN_DELETE",
      can_export: "SHAREHOLDER_MODULE:CAN_EXPORT",
      can_approve: "SHAREHOLDER_MODULE:CAN_APPROVE",
    });
    getAllShareholders({ size: requiredResults, pageIndex: 0 }).then(
      (response) => {
        console.log('Shareholder response: ', response);
        dispatch(setShareholders(response.data?.data));
        dispatch(
          setShareholderBackendPagination({
            page_Index: response.data.page_index,
            total_pages: response.data.total_pages,
            records_displayed: response.data.records_displayed,
            total_elements: response.data.total_elements,
          })
        );
      }
    );
  }, []);

  const handleDeleteShareholder = (shareholder) => {
    setOpen(true);
    dispatch(setDynamicUser(shareholder));
  };
  const handleViewShareholder = (shareholder) => {
    dispatch(setShareholder(shareholder));
    dispatch(setDynamicUser(shareholder));
    navigate(`/shareholder-details/${shareholder.shareholder_code}`, {
      replace: false,
    });
  };

  const handleEditShareholder = (shareholder) => {
    dispatch(setDynamicUser(shareholder));
    navigate(`/admin-shareholder-edit/${shareholder.shareholder_code}`, {
      replace: false,
    });
  };
  const confirmDeleteShareholder = async () => {
    let response;
    try {
      response = await API.delete(
        `/shareholders/${user.dynamicUser.shareholder_code}`
      );
      dispatch(setAlertTitle("Success"));
      dispatch(setAlertMessage(response.data.operation_description));
      dispatch(removeShareholder(user.dynamicUser.shareholder_code));
    } catch (error) {
      dispatch(setAlertTitle("ERROR!"));
      dispatch(setAlertMessage(response.data.operation_description));
      dispatch(openAlert());
      setOpen(false);
      navigate("/shareholders", {
        replace: true,
      });
    }
    setOpen(false);
    dispatch(openAlert());
    navigate("/shareholders");
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - shareholders.length) : 0;

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFile = (e) => {
    let filePath = e.target.value;
    let pathParts = filePath.split("\\fakepath\\");
    pathParts.shift();
    let fileName = pathParts.join("\\");
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && fileType.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          dispatch(setExcelFileError(null));
          dispatch(setExcelFile(e.target.result));
          dispatch(setFileName(fileName));
          dispatch(setFileType(selectedFile.type));
        };
      } else {
        dispatch(setExcelFileError("Please select only excel file types"));
        dispatch(setExcelFile(null));
      }
    } else {
    }
  };

  // view bulk shareholders function
  const viewBulkShareholders = (e) => {
    e.preventDefault();
    navigate("/administrator-shareholder-bulkupload-view", {
      replace: true,
    });
    if (user.excelFile !== null) {
      const workbook = XLSX.read(user.excelFile, { type: "buffer" });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const data = XLSX.utils.sheet_to_json(worksheet);
      dispatch(setExcelData(data));
    } else {
      dispatch(setExcelData(null));
    }
  };

  // download template
  const downloadTemplate = async () => {
    setdownloading(true);
    try {
      const response = await API.get(`shareholders/download-template/`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        responseType: "blob",
      });
      if (response.status === 200) {
        setdownloading(false);
        const fileName = response.headers.filename;
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: response.data.type })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    } catch (error) {
      setdownloading(false);
    }
  };

  // download register
  const downloadRegister = async () => {
    setdownloading(true);
    try {
      const response = await API.get(`shareholders/download-register/`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        responseType: "blob",
      });
      if (response.status === 200) {
        setdownloading(false);
        const fileName = response.headers.filename;
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: response.data.type })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    } catch (error) {
      setdownloading(false);
    }
  };

  const makeAdvancedSearch = () => {
    setLoading(true);
    let data = { [searchField]: searchTerm };
    let objToPass = {
      size: 20,
      pageIndex: 0,
      data,
    };
    ShareholdersAdvancedSearch(objToPass).then((response) => {
      setSearchResults(response.data.data);
      setAdvanceSearched(true);
      setBackendPagination({
        page_Index: response.data.page_index,
        total_pages: response.data.total_pages,
        records_displayed: response.data.records_displayed,
        total_elements: response.data.total_elements,
      });
      dispatch(
        setShareholderBackendPagination({
          page_Index: response.data.page_index,
          total_pages: response.data.total_pages,
          records_displayed: response.data.records_displayed,
          total_elements: response.data.total_elements,
        })
      );
      setLoading(false);
    });
  };
  const searchChange = (e) => {
    setSearchTerm(e.target.value);
    setSearching(true);
    if (
      searching &&
      shareholders
        ?.filter((shareholder) =>
          shareholder.shareholder_code?.includes(e.target.value.toUpperCase())
        )
        .length() <= 0
    ) {
    }
    if (searchTerm === "") {
      setSearchResults([]);
    } else {
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={8} md={4}>
          {advanceSearched ? (
            <>
              <Typography variant="h1">
                {`${searchResults?.length} of ${userBackendPagination.total_elements?.toLocaleString()} Search results`}
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="h1">
                {`${shareholders?.length} of ${userBackendPagination.total_elements?.toLocaleString()} Shareholders`}
              </Typography>
            </>
          )}
        </Grid>
        <Grid item xs={4} md={8}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <FormControl
                variant="standard"
                sx={{ width: "100%", marginBottom: "10px" }}
              >
                <BootstrapInput
                  id="searchTxt"
                  size="small"
                  type="text"
                  placeholder="Search"
                  onChange={searchChange}
                  sx={{ backgroundColor: "#FFFFFF" }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <Tooltip title='Download shareholder register'>
                <span>
                <Button
                  variant="contained"
                  endIcon={<Download />}
                  sx={{
                    backgroundColor: "#FFFFFF",
                    color: "#052A6C",
                    margin: "2% 1% 2% 0",
                    width: "100%",
                    fontSize: 14,
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#FFFFFF",
                    },
                  }}
                  onClick={downloadRegister}
                  disabled={downloading || sessionStorage.getItem("can_export") === "false"}
                >
                  {downloading ? <CircularProgress />:<>Register</>}
                </Button>
                </span>
              </Tooltip>
            </Grid>

            <Grid item xs={12} md={3}>
              
            <Tooltip title='Download shareholder bulk template'>
              <span>
              <Button
                variant="contained"
                endIcon={<Download />}
                sx={{
                  backgroundColor: "#FFFFFF",
                  color: "#052A6C",
                  // margin: "2% 1% 2% 0",
                  height: "78%",
                  width: "100%",
                  fontSize: 14,
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#FFFFFF",
                  },
                }}
                onClick={downloadTemplate}
                disabled={
                  downloading ||
                  sessionStorage.getItem("can_export") === "false"
                }
              >
                Template
              </Button>
              </span>
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={3}>              
              <Tooltip title='Upload filled in shareholder template'>
                <span>
                <Button
                  variant="contained"
                  endIcon={<Upload />}
                  sx={{
                    backgroundColor: "#FFFFFF",
                    color: "#052A6C",
                    margin: "2% 1% 2% 0",
                    width: "100%",
                    fontSize: 14,
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#FFFFFF",
                    },
                  }}
                  onClick={handleShow}
                  disabled={downloading || sessionStorage.getItem("can_read") === "false"}
                >
                  Upload
                </Button>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {searchTerm !== "" && (
        <Grid container>
          <Grid item xs={6} md={4}>
            <Typography variant="h1">Advanced Search By</Typography>
          </Grid>
          <Grid item xs={6} md={8}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <FormControl sx={{ width: "100%", marginTop: 2 }}>
                  <ShareholderSelect
                    required
                    name="fieldSelect"
                    id="field-select"
                    onChange={(event) => {
                      setSearchField(event.target.value);
                    }}
                    sx={{ height: "100%" }}
                  >
                    {fields?.map((field) => (
                      <MenuItem key={field} value={field}>
                        {field}
                      </MenuItem>
                    ))}
                  </ShareholderSelect>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl
                  variant="standard"
                  sx={{ width: "100%", marginBottom: "10px" }}
                >
                  <BootstrapInput
                    disabled
                    id="searchTxt"
                    size="small"
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                    sx={{ backgroundColor: "#FFFFFF" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#AA0050",
                    margin: "2% 1% 2% 0",
                    width: "100%",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#B150C5",
                    },
                    fontSize: 14,
                  }}
                  onClick={makeAdvancedSearch}
                >
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <Typography>Search</Typography>
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      {alert.open === true ? (
        <AlertModal
          sx={{ margin: "20px", width: "50%", align: "right" }}
        ></AlertModal>
      ) : (
        <></>
      )}

      <TableContainer component={Paper}>
        <Table aria-label="custom pagination table" size="small">
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell
                  key={header}
                  align="left"
                  sx={{ fontWeight: "bold" }}
                >
                  {header}
                </TableCell>
              ))}
              <TableCell align="center" sx={{ fontWeight: "bold" }} colSpan={3}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          {searchTerm !== "" && searchResults?.length > 0 ? (
            <TableBody>
              {searchResults
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((shareholder) => (
                  <TableRow key={shareholder.shareholder_code}>
                    <TableCell component="th">
                      {shareholder.shareholder_code}
                    </TableCell>
                    <TableCell align="left">{`${shareholder.shareholder_name
                      .toLowerCase()
                      .substring(0, 15)}...`}</TableCell>
                    <TableCell align="left">
                      {shareholder.company === true ? (
                        <Typography>Company</Typography>
                      ) : (
                        <Typography>Individual</Typography>
                      )}
                    </TableCell>
                    {shareholder &&
                    typeof shareholder?.primary_email === "string" ? (
                      <TableCell align="left">
                        {`${shareholder?.primary_email
                          ?.toLowerCase()
                          .substring(0, 15)}...`}
                      </TableCell>
                    ) : (
                      <TableCell align="left">{""}</TableCell>
                    )}
                    <TableCell align="left">
                      {shareholder.primary_phone_number}
                    </TableCell>

                    <TableCell sx={{ padding: 0 }}>
                      <Tooltip title="View shareholder">
                        <span>
                        <IconButton
                          disabled={
                            downloading ||
                            sessionStorage.getItem("can_read") === "false"
                          }
                          onClick={() => {
                            handleViewShareholder(shareholder);
                          }}
                          style={{
                            color:
                              !downloading ||
                              sessionStorage.getItem("can_read") === "true"
                                ? green[500]
                                : "gray",
                          }}
                        >
                          <RemoveRedEye fontSize="small" />
                        </IconButton>
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell sx={{ padding: 0 }}>
                      <Tooltip title="Edit shareholder">
                        <span>
                        <IconButton
                          disabled={
                            downloading ||
                            sessionStorage.getItem("can_update") === "false"
                          }
                          onClick={() => {
                            handleEditShareholder(shareholder);
                          }}
                          style={{
                            color:
                              !downloading ||
                              sessionStorage.getItem("can_update") === "true"
                                ? orange[500]
                                : "gray",
                          }}
                        >
                          <Edit fontSize="small" />
                        </IconButton>
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell sx={{ padding: 0 }}>
                      <Tooltip title="Delete shareholder">
                        <span>
                        <IconButton
                          disabled={
                            downloading ||
                            sessionStorage.getItem("can_delete") === "false"
                          }
                          onClick={() => {
                            handleDeleteShareholder(shareholder);
                          }}
                          style={{
                            color:
                              !downloading ||
                              sessionStorage.getItem("can_delete") === "true"
                                ? red[500]
                                : "gray",
                          }}
                        >
                          <Delete fontSize="small" />
                        </IconButton>
                        </span>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          ) : (
            <TableBody>
              {shareholders
                ?.filter((val) => {
                  if (searchTerm === "") {
                    return val;
                  } else if (
                    val.shareholder_code?.toLowerCase()?.includes(searchTerm) ||
                    val.shareholder_name?.toLowerCase()?.includes(searchTerm) ||
                    val.primary_email?.toLowerCase()?.includes(searchTerm) ||
                    val.primary_phone_number?.includes(searchTerm)
                  ) {
                    return val;
                  }
                })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((shareholder) => (
                  <TableRow key={shareholder.shareholder_code}>
                    <TableCell component="th">
                      {shareholder.shareholder_code}
                    </TableCell>
                    <TableCell align="left">
                      {`${shareholder.shareholder_name
                        .toLowerCase()
                        .substring(0, 15)}...`}
                    </TableCell>
                    <TableCell align="left">
                      {shareholder.company === true ? (
                        <Typography>Company</Typography>
                      ) : (
                        <Typography>Individual</Typography>
                      )}
                    </TableCell>
                    {shareholder &&
                    typeof shareholder?.primary_email === "string" ? (
                      <TableCell align="left">
                        {`${shareholder?.primary_email
                          ?.toLowerCase()
                          .substring(0, 15)}...`}
                      </TableCell>
                    ) : (
                      <TableCell align="left">{""}</TableCell>
                    )}
                    <TableCell align="left">
                      {shareholder.primary_phone_number}
                    </TableCell>
                    <TableCell sx={{ padding: 0 }}>
                      <Tooltip title="View shareholder">
                        <span>
                        <IconButton
                          disabled={
                            downloading ||
                            sessionStorage.getItem("can_read") === "false"
                          }
                          onClick={() => {
                            handleViewShareholder(shareholder);
                          }}
                          style={{
                            color:
                              !downloading ||
                              sessionStorage.getItem("can_read") === "true"
                                ? green[500]
                                : "gray",
                          }}
                        >
                          <RemoveRedEye fontSize="small" />
                        </IconButton>
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell sx={{ padding: 0 }}>
                      <Tooltip title="Edit shareholder">
                        <span>
                        <IconButton
                          disabled={
                            downloading ||
                            sessionStorage.getItem("can_update") === "false"
                          }
                          onClick={() => {
                            handleEditShareholder(shareholder);
                          }}
                          style={{
                            color:
                              !downloading ||
                              sessionStorage.getItem("can_update") === "true"
                                ? orange[500]
                                : "gray",
                          }}
                        >
                          <Edit fontSize="small" />
                        </IconButton>
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell sx={{ padding: 0 }}>
                      <Tooltip title="Delete shareholder">
                      <span>
                        <IconButton
                          disabled={
                            downloading ||
                            sessionStorage.getItem("can_delete") === "false"
                          }
                          onClick={() => {
                            handleDeleteShareholder(shareholder);
                          }}
                          style={{
                            color:
                              !downloading ||
                              sessionStorage.getItem("can_delete") === "true"
                                ? red[500]
                                : "gray",
                          }}
                        >
                          <Delete fontSize="small" />
                        </IconButton>
                        </span>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          )}

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={4}
                count={shareholders.length}
                rowsPerPage={rowsPerPage}
                page={page}
                users={shareholders}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                sx={{
                  "&  .MuiTablePagination-spacer": {
                    display: "none",
                  },
                }}
                backendPagination={backendPagination}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .css-hlj6pa-MuiDialogActions-root": {
            paddingRight: "24px",
            paddingLeft: "24px",
          },
          "& .css-hlj6pa-MuiDialogActions-root>:not(:first-of-type)": {
            marginLeft: "60%",
          },
        }}
      >
        <DialogContent>
          <FormControl>
            <Input
              type="file"
              onChange={handleFile}
              required
              inputProps={{ accept: fileType }}
            />
            {user.excelFileError && (
              <Box style={{ color: "red" }}>{user.excelFileError}</Box>
            )}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{ backgroundColor: "#B20808" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={viewBulkShareholders}
            autoFocus
            sx={{ backgroundColor: "#052A6C" }}
            disabled={user.fileName == null || user.fileType !== fileType}
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .css-hlj6pa-MuiDialogActions-root": {
            paddingRight: "24px",
            paddingLeft: "24px",
          },
          "& .css-hlj6pa-MuiDialogActions-root>:not(:first-of-type)": {
            marginLeft: "60%",
          },
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Report color="error" /> Are you sure you'd like to remove this
            shareholder?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleCloseDialog}
            sx={{ backgroundColor: "#B20808" }}
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={confirmDeleteShareholder}
            autoFocus
            sx={{ backgroundColor: "#052A6C" }}
            disabled={!sessionStorage.getItem("can_delete")}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ListShareholders;
