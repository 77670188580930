import React from 'react';
import SettingsCard from '../../components/settingsCard';
import MiniDrawer from '../../components/newSideDrawer/miniSideDrawer';
import AlertModalTest from '../../components/alertModalTest';
import { useSelector } from 'react-redux';

function AdministratorSettingsPage(props) {
  const alert = useSelector((state) => state.alert);
  return (
    <MiniDrawer>
    {alert.open === true ? (
     <AlertModalTest
       sx={{ margin: "20px", width: "50%", align: "right" }}
     ></AlertModalTest>
   ) : (
     <></>
   )}
      <SettingsCard />
    </MiniDrawer>
  )
}
export default AdministratorSettingsPage