import {
  Card,
  CardContent,
  Tab,
  Tabs,
  styled
} from "@mui/material";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkAuthorities } from "../service/securityService";
import {
  getAllCountries,
  getAllPayModes,
  getAllSystemModules,
  getAllTaxRates
} from "../service/settingsService";
import { openAlert, setAlertMessage, setAlertTitle } from "../store/alertSlice";
import {
  setCountries,
  setModules,
  setPaymentModes,
  setTaxRates
} from "../store/settingsSlice";
import FlyTable from "./tables/FlyTable";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTypography = styled(Typography)(() => ({
  width: "100%",
  textAlign: "left",
  fontSize: 16,
  fontWeight: 400,
}));

const StyledTableCell = styled(TableCell)(() => ({
  color: "#0A2240",
  fontWeight: 500,
  fontSize: 16,
}));

const DataStyledTableCell = styled(TableCell)(() => ({
  color: "#474848",
  fontWeight: 500,
  fontSize: 16,
}));

const StyledBox = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  right: "-24%",
  transform: "translate(-40%, -50%)",
  width: {
    md: "40%",
    sm: "100%",
    xs: "100%",
  },
  borderRadius: 8,
  boxShadow:
    "inset 0 -3em 3em #00000010, 0 0 0 2px #00000020, 0.3em 0.3em 1em #00000050",
}));

let backPageIndex = 0;
let requestedSize = 240;
const sessionToken = sessionStorage.getItem("token");

function SettingsCard() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [countryList, setCountryList] = useState([]);
  const [taxList, setTaxList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paymentModeList, setPaymentModeList] = useState([]);
  const dispatch = useDispatch();
  const actions = []

  
  useEffect(() => {
    setLoading(true);
    checkAuthorities({
      can_create: "SETTINGS_MODULE:CAN_CREATE",
      can_read: "SETTINGS_MODULE:CAN_READ",
      can_update: "SETTINGS_MODULE:CAN_UPDATE",
      can_delete: "SETTINGS_MODULE:CAN_DELETE",
      can_export: "SETTINGS_MODULE:CAN_EXPORT",
      can_approve: "SETTINGS_MODULE:CAN_APPROVE",
    });
    getAllCountries({ size: requestedSize, pageIndex: backPageIndex }).then(
      (response) => {
        console.log('Countries response: ', response);
        if (response.data.operation_code === 0) {
          setCountryList(...countryList,response.data.data.map((element) => ({
            iso: element.iso_code,
            id: element.country_code,
            name: element.country_name
          })))
          dispatch(setCountries(response.data.data));
          // setTotalCountries(response.data.total_elements);
          dispatch(setAlertTitle("Success"));
          dispatch(setAlertMessage(response.data.operation_description));
          dispatch(openAlert());
        } else {
          dispatch(setAlertTitle("Error"));
          dispatch(setAlertMessage(response.data.operation_description));
        }
        dispatch(openAlert());
      }
    );
    getAllPayModes({ size: requestedSize, pageIndex: backPageIndex }).then(
      (response) => {
        console.log('Paymodes response: ', response);
        if (response.data.operation_code === 0) {
          setPaymentModeList(...paymentModeList,response.data.data.map((element) => ({
            
            id: element.payment_mode_code,
            name: element.payment_mode_name,
            type: element.payment_type,
            swift_code: element.swift_code,
          })));
          dispatch(setPaymentModes(response.data.data));
          dispatch(setAlertTitle("Success"));
          dispatch(setAlertMessage(response.data.operation_description));
        } else {
          dispatch(setAlertTitle("Error"));
          dispatch(setAlertMessage(response.data.operation_description));
        }
        dispatch(openAlert());
      }
    );    
    getAllTaxRates({ size: requestedSize, pageIndex: backPageIndex }).then(
      (response) => {
        console.log('Tax Rates response: ', response);
        if (response.data.operation_code === 0) {  
          console.log('Success in tax: ', response);        
          setTaxList(...taxList,response.data.data.map((element) => ({
            id: element.tax_rate_code,
            description: element.description,
            rate: element.tax_rate.toString()
          }))); 
          // dispatch(setTaxRates(response.data.data));
          dispatch(setAlertTitle("Success"));
          dispatch(setAlertMessage(response.data.operation_description));
        } else {
          dispatch(setAlertTitle("Error"));
          dispatch(setAlertMessage(response.data.operation_description));
        }
        dispatch(openAlert());
      }
    );
    getAllSystemModules({ size: requestedSize, pageIndex: backPageIndex }).then(
      (response) => {
        if (response.data.operation_code === 0) {
          dispatch(setModules(response.data.data));
          dispatch(setAlertTitle("Success"));
          dispatch(setAlertMessage(response.data.operation_description));
        } else {
          dispatch(setAlertTitle("Error"));
          dispatch(setAlertMessage(response.data.operation_description));
        }
        dispatch(openAlert());
      }
    );
    setLoading(false);
  }, []);

  return (
    <Card>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Countries" {...a11yProps(0)} />
          <Tab label="Payment Modes" {...a11yProps(1)} />
          <Tab label="Tax rates" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <CardContent>
          {loading ? 
          <Skeleton
            variant="rectangle"
            height={"80vh"}
            width={"80vw"}
          ></Skeleton> : 
          <FlyTable
            header='Countries'
            data={countryList}
            headers={['ISO', 'Code', 'Name']}
            actions={actions}
            handleNextOrPreviousPage={(page) => {
              console.log('Page: ', page);
            }} />}
          {/* <TaxRatesTable /> */}
        </CardContent>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <CardContent>
          {loading ? 
          <Skeleton
            variant="rectangle"
            height={"80vh"}
            width={"80vw"}
          ></Skeleton> : 
          <FlyTable
            header='Payment Modes'
            data={paymentModeList}
            headers={["Code", "Name", "Type", "Swift Code"]}
            actions={actions}
            handleNextOrPreviousPage={(page) => {
              console.log('Page: ', page);
            }} />}
          {/* <ListPaymodesTable /> */}
        </CardContent>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <CardContent>
          {loading ? 
          <Skeleton
            variant="rectangle"
            height={"80vh"}
            width={"80vw"}
          ></Skeleton> : 
          <FlyTable
            header='Tax rates'
            data={taxList}
            headers={['Code', 'Description', 'Rate']}
            actions={actions}
            handleNextOrPreviousPage={(page) => {
              console.log('Page: ', page);
            }} />}
        </CardContent>
      </CustomTabPanel>
    </Card>
  );
}

export default SettingsCard;
