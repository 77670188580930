import { Stack } from '@mui/material';
import React, { useState } from 'react';
import ShareholderWrapper from './ShareholderWrapper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Add, Remove } from '@mui/icons-material';

const AccordionComponent = ({
  question,
  answer,
  condition,
  panel,
  onChange,
}) => (
  <Accordion expanded={condition === panel} onChange={onChange} sx={{ mb: 1 }}>
    <AccordionSummary
      expandIcon={condition === panel ? <Remove /> : <Add />}
      aria-controls="panel2a-content"
      id="panel2a-header"
    >
      <Typography sx={{ fontWeight: 600, fontSize: 16 }}>{question}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Typography>{answer}</Typography>
    </AccordionDetails>
  </Accordion>
);

const data = [
  {
    question: 'I want to be receiving my dividends through Mpesa?',
    answer:
      'Yes it is possible, kindly dial USSD *483*038# or send to our Registrars an email for more guidance or visit them.',
  },
  {
    question: 'How do I update my dividend payment mode?',
    answer:
      'If your shares are on SCD kindly liaise with your stockbroker and if still in certificate form, contact our Registrars.',
  },
  {
    question: 'I never received my share certificate since I bought shares?',
    answer: 'Please note you can replace the certificate.',
  },
  {
    question: 'I want to sell my shares and do not have a broker?',
    answer: 'We have licensed stockbrokers who can facilitate that process',
  },
  {
    question: 'What is immobilization?',
    answer:
      'This is a process of converting shares from certificate form to electronic mode.',
  },
  {
    question: 'Do we have a representative in Kenya?',
    answer: 'Yes, we do have.',
  },
  {
    question:
      'How can I transfer my shares to my son/daughter or a close relative?',
    answer:
      'The shares are transferable, kindly liaise with your stockbroker if your shares are on SCD and if still in certificate form, contact our Registrars.',
  },
  {
    question: 'I have a deceased relative and held share with the bank.',
    answer:
      'The shares are transferable through court letters, the administrator needs to lodge the transfer documents through a broker of choice.',
  },
  {
    question: 'I want to buy more shares',
    answer:
      'Kindly liaise with your stockbroker for additional shares, which are always available in the market for trading.',
  },
  {
    question: 'Can I use my dividends to buy more shares?',
    answer:
      'Please note that the bank pays dividends to your preferred mode of payment and then you can liaise with your stockbroker for support on the matter.',
  },
  {
    question:
      'I have never received my dividends from the time I purchased the shares?',
    answer: 'Kindly contact our Registrars for further guidance on the matter.',
  },
  {
    question:
      'Am out of the country, how can I continue receiving my dividends, can you pay my dividends to my relatives via mobile money or their bank account?',
    answer:
      'Please note we only pay to mobile money number registered in your name as a shareholder.',
  },
  { question: 'Can I pledge my shares as security?', answer: '' },
  {
    question: 'Why does the process to replace the dividends take so long?',
    answer: 'What are the timelines',
  },
  {
    question:
      'What security document do I have when my shares are on a SCD account?',
    answer:
      'Please note that you can get a portfolio statement from your stockbroker or USE (Uganda Securities Exchange)',
  },
];

function ShareholderFAQs() {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <ShareholderWrapper title="Claim unpaid dividends" sidebar={false}>
      <Stack
        direction="column"
        sx={{
          justifyContent: 'space-between',
          background: 'linear-gradient(to bottom,#052A6C 30%, #F5F5F5 30%)',
          margin: 0,
          padding: {
            xl: '4em 18em',
            lg: '4em 8em',
            md: '2em 1em',
            sm: '2em 1em',
            xs: '1em',
          },
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: {
              md: 32,
              sm: 20,
            },
            color: 'white',
          }}
        >
          Frequently Asked Questions
        </Typography>
        <Stack direction="column" sx={{ bgcolor: 'white', borderRadius: 4 }}>
          <Stack direction="row">
            <img
              // src={require('../../assets/modified-images/FAQs.png')}
              src={require('../../assets/modified-images/faqsFlag.jpg')}
              alt="unpaid dividends"
              style={{ width: '100%', height: '600px' }}
            />
          </Stack>
          <Stack
            direction="column"
            sx={{
              pl: {
                md: 10,
                sm: 10,
                xs: 4,
              },
              pr: {
                md: 10,
                sm: 10,
                xs: 4,
              },
              pt: {
                md: 4,
                sm: 2,
                xs: 2,
              },
              pb: {
                md: 20,
                sm: 10,
                xs: 10,
              },
            }}
          >
            {data &&
              data?.map((record, index) => (
                <AccordionComponent
                  key={index + 1}
                  question={record.question}
                  answer={record.answer}
                  condition={expanded}
                  panel={`panel${index + 1}`}
                  onChange={handleChange(`panel${index + 1}`)}
                />
              ))}
          </Stack>
        </Stack>
      </Stack>
    </ShareholderWrapper>
  );
}

export default ShareholderFAQs;
