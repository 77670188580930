// import { TabPanel } from "@mui/lab";
import {
    alpha,
    Avatar,
    Button,
    Card,
    CardContent,
    CardMedia,
    FormControl,
    Grid,
    InputBase,
    InputLabel,
    styled,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import shareholderBanner from "../assets/images/shareholderBanner.jpg";
import {API} from "../config/API";
import { openAlert, setAlertMessage, setAlertTitle } from "../store/alertSlice";
import {
    replaceOutdatedAccountOfficer,
    updateEmail,
    updateFirstName,
    updateLastName,
    updatePhone,
    updateUsername,
} from "../store/userSlice";

const CustomShareholderInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const EditAccountOfficerCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accountOfficer = useSelector((state) => state.user.dynamicUser);

  const handleSubmit = async () => {
    let response;
    let updatingOfficer = {
      first_name: accountOfficer.first_name,
      last_name: accountOfficer.last_name,
      username: accountOfficer.username,
      primary_email: accountOfficer.primary_email,
      primary_phone_number: accountOfficer.primary_phone_number,
      primary_address: accountOfficer.primary_address,
      shareholder_code: accountOfficer.shareholder.shareholder_code,
    };
    try {
      response = await API.put(
        `account-officers/${accountOfficer.officer_code}`,
        updatingOfficer
      );
      if (response.status === 202) {
        dispatch(replaceOutdatedAccountOfficer(response.data.data));
        dispatch(setAlertMessage(response.data.operation_description));
        dispatch(setAlertTitle("Success"));
        dispatch(openAlert());
        navigate("/account-officers", { replace: true });
      }
    } catch (error) {
      dispatch(setAlertMessage(response.data.operation_description));
      dispatch(setAlertTitle("Error"));
      dispatch(openAlert());
      navigate("/account-officers", { replace: true });
    }
  };

  return (
    <>
      <Card>
        <CardMedia
          component="img"
          height="180"
          image={shareholderBanner}
          alt="Shareholder Banner"
        ></CardMedia>
        <CardContent>
          <Avatar></Avatar>
          <Grid container>
            <Grid item xs={12} md={6}>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <InputLabel shrink htmlFor="bootstrap-input">
                  First Name
                </InputLabel>
                <CustomShareholderInput
                  id="f_nameTxt"
                  onChange={(event) => {
                    dispatch(updateFirstName(event.target.value));
                  }}
                  value={accountOfficer.first_name}
                  sx={{ width: "80%" }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <InputLabel shrink htmlFor="bootstrap-input">
                  Last Name
                </InputLabel>
                <CustomShareholderInput
                  id="l_nameTxt"
                  onChange={(event) => {
                    dispatch(updateLastName(event.target.value));
                  }}
                  value={accountOfficer.last_name}
                  sx={{ width: "80%" }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <InputLabel shrink htmlFor="bootstrap-input">
                  Email
                </InputLabel>
                <CustomShareholderInput
                  id="emailTxt"
                  onChange={(event) => {
                    dispatch(updateEmail(event.target.value));
                  }}
                  value={accountOfficer.primary_email}
                  sx={{ width: "80%" }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <InputLabel shrink htmlFor="bootstrap-input">
                  Phone Number
                </InputLabel>
                <CustomShareholderInput
                  id="phoneTxt"
                  onChange={(event) => {
                    dispatch(updatePhone(event.target.value));
                  }}
                  value={accountOfficer.primary_phone_number}
                  sx={{ width: "80%" }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <InputLabel shrink htmlFor="bootstrap-input">
                  Username
                </InputLabel>
                <CustomShareholderInput
                  id="usernameTxt"
                  onChange={(event) => {
                    dispatch(updateUsername(event.target.value));
                  }}
                  value={accountOfficer.username}
                  sx={{ width: "80%" }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}></Grid>
            <Grid container>
              <Grid item xs={12} md={9} lg={10}></Grid>
              <Grid item xs={6} md={3} lg={2}>
                <Button
                  variant="contained"
                  sx={{ marginLeft: "50%" }}
                  onClick={handleSubmit}
                  disabled={!sessionStorage.getItem('can_update')}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
export default EditAccountOfficerCard;
