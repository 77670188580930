import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  links: {
    textDecoration: 'none',
  },
  listItemButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& .listText': {
      display: 'flex',
      flewDirection: 'column',
      alignItems: 'center',
    },
    '& .list': {
      color: '#5F6868',
      margin: '0',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  listItemActiveButton: {
    backgroundColor: '#5F686820',
    '& .list': {
      color: '#0033A1',
    },
  },
  listItemDisabledButton: {
    backgroundColor: '#5F686820',
    '& .list': {
      color: '##F1F1F1',
    },
  },
  listItemText: {
    fontSize: 14,
    fontWeight: 500,
  },
});

export default useStyles;
