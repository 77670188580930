import { Box, CardHeader, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../components/tables/DataTable";
import { getDividends } from "../../service/userService";
import NavigationContext from "../../store/NavigationContext";
import { setDividends } from "../../store/dividendsSlice";
import AlertModalTest from "../../components/alertModalTest";
import MiniDrawer from "../../components/newSideDrawer/miniSideDrawer";

const AdministratorDividendsSummary = () => {
  const navigationContext = useContext(NavigationContext);
  const { sidebar } = navigationContext;
  const dividend = useSelector((state) => state.dividend);
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alert);

  const requiredResults = 240;

  useEffect(() => {
    getDividends({ size: requiredResults, pageIndex: 0 }).then((response) => {
      dispatch(setDividends(response.data.data));
    });
  }, []);

  const cardTypographyStyles = {
    marginTop: "10px",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    color: "#8E8E8E",
  };

  const cardTypographyStyles2 = {
    marginTop: "10px",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    color: "#000000",
    // borderBottom: "1px solid #000000",
  };

  const dividendsTableStyles = {
    height: "400px",
    // width: "100%",
    marginTop: "20px",
    marginBottom: "20px",
    backgroundColor: "white",
  };

  const columns = [
    { field: "dividend_year", headerName: "Dividend Year", width: 150 },
    { field: "shareholder_code", headerName: "Shareholder Code", width: 150 },
    { field: "shareholder_name", headerName: "Shareholder", width: 150 },
    { field: "shares_held", headerName: "Shares", width: 150 },
    { field: "payment_mode", headerName: "Payment Mode", width: 150 },
    {
      field: "payment_account_name",
      headerName: "Account Name",
      width: 150,
    },
    {
      field: "payment_account_number",
      headerName: "Account Number",
      width: 150,
    },
    {
      field: "tax_rate",
      headerName: "Tax Rate(%)",
      width: 150,
    },
    {
      field: "actual_gross_amount",
      headerName: "Gross Pay",
      width: 150,
    },
    {
      field: "actual_tax_amount",
      headerName: "Tax",
      width: 150,
    },
    {
      field: "actual_net_amount",
      headerName: "Net Pay",
      width: 150,
    },
  ];

  const schedule = dividend.schedule;
  const rows = dividend.dividends;
  return (
    <MiniDrawer>
      {alert.open === true ? (
        <AlertModalTest
          sx={{ margin: "20px", width: "50%", align: "right" }}
        ></AlertModalTest>
      ) : (
        <></>
      )}
      <Card
        sx={{
          marginBottom:"30px"
        }}
      >
        <CardHeader
          title="Stanbic Uganda Holdings Limited"
          sx={{
            textAlign: "center",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "24px",
            color: "#000000",
          }}
        />
        <CardHeader
          title={schedule?.dividend_year}
          sx={{
            textAlign: "center",
            marginTop: "-40px",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "16px",
            color: "#000000",
          }}
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <Typography sx={cardTypographyStyles}>
                Number of shareholders
              </Typography>
            </Grid>
            <Grid item md={3}></Grid>
            <Grid item md={3}></Grid>
            <Grid item md={3}>
              <Typography sx={cardTypographyStyles}>
                {schedule.total_shares_held}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={3}>
              <Typography sx={cardTypographyStyles}>Payout ratio</Typography>
            </Grid>
            <Grid item md={3}></Grid>
            <Grid item md={3}></Grid>
            <Grid item md={3}>
              <Typography sx={cardTypographyStyles}>
                {schedule.dividend_payout_ratio}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={3}>
              <Typography sx={cardTypographyStyles}>
                Total Number of Shares
              </Typography>
            </Grid>
            <Grid item md={3}></Grid>
            <Grid item md={3}></Grid>
            <Grid item md={3}>
              <Typography sx={cardTypographyStyles}>
                {schedule.total_shares_held}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={3}>
              <Typography sx={cardTypographyStyles}>
                Gross Pay Amount
              </Typography>
            </Grid>
            <Grid item md={3}></Grid>
            <Grid item md={3}></Grid>
            <Grid item md={3}>
              <Typography sx={cardTypographyStyles}>
                {schedule.gross_payout_amount}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={3}>
              <Typography sx={cardTypographyStyles}>Total Tax</Typography>
            </Grid>
            <Grid item md={3}></Grid>
            <Grid item md={3}></Grid>
            <Grid item md={3}>
              <Typography sx={cardTypographyStyles}>
                {schedule.total_tax}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={3}>
              <Typography sx={cardTypographyStyles}>
                Total Gross Payout Amount
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography sx={cardTypographyStyles2}>
                {schedule.total_gross}
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography sx={cardTypographyStyles}>
                Total Net Payout Amount
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography sx={cardTypographyStyles2}>
                {schedule.total_net}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <DataTable
        rows={rows}
        columns={columns}
        loading={!rows}
        getRowId={(row) => row.payment_ref_number}
        sx={dividendsTableStyles}
      />
    </MiniDrawer>
  );
};

export default AdministratorDividendsSummary;
