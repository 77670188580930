import { alpha, Autocomplete, ButtonBase, Checkbox, Dialog, InputBase, Select, styled } from "@mui/material";

export const ShareholderInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    '& .MuiInputBase-input': {
            border: '1px solid #ced4da',
            borderRadius: 4,
            width: '80%',
            padding: '5px 7px',
            fontSize: 11,
            backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
            fontFamily: [
                    '-apple-system',
                    'BlinkMacSystemFont',
                    '"Segoe UI"',
                    'Roboto',
                    '"Helvetica Neue"',
                    'Arial',
                    'sans-serif',
                    '"Apple Color Emoji"',
                    '"Segoe UI Emoji"',
                    '"Segoe UI Symbol"',
                  ].join(','),
            '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
            },
        }
}));
export const ShareholderSelect = styled(Select)(({ theme }) => ({
  
  width: '67%',
    'label + &': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
    '&  .MuiOutlinedInput-notchedOutline': {
        border: 'none'
    },
    '&  .MuiInputBase-input': {
        border: '1px solid #ced4da',
        borderRadius: 4,
        padding: '2px 3px',
        fontSize: 11,
        backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    },
}));
export const ShareholderNumberTxt = styled(InputBase)(({ theme }) => ({
  
    'label + &': {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    '&  .MuiOutlinedInput-notchedOutline': {
        border: 'none'
    },
    '&  .MuiInputBase-input': {
        width: '80%',
        border: '1px solid #ced4da',
        borderRadius: 4,
        padding: '2px 3px',
        fontSize: 11,
        backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
        fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
  '&:focus': {
  boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
  borderColor: theme.palette.primary.main,
  },
    },
}));
export const ShareholderAutoComplete = styled(Autocomplete)(({theme}) => ({
  'label + &': {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  '&  .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '& .MuiOutlinedInput-root': {
    border: '1px solid #ced4da',
    borderRadius: 4,
    padding: '2px 3px',
    width: '92%',
    fontSize: 11
  },
  '& .MuiInputBase-formControl' : {
    backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(','),
    '&:focus': {
    boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    borderColor: theme.palette.primary.main,
    },
  }
}));
export const ShareholderBtn = styled(ButtonBase)(({theme}) => ({
  '& .MuiButton-contained': {
    backgroundColor: '#052A6C',
    fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(','),
  }
}));
export const BootstrapCheckbox = styled(Checkbox)(({ theme }) => ({
    '& .MuiFormControlLabel-label': {
      fontSize: '2px',
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));
  export const ShareholderDialog = styled(Dialog)(({ theme }) => ({
      '& .MuiDialog-paper': {
        width: '100%'
      },
    }));
