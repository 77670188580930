// import * as React from 'react';
import {
  AccountCircle,
  FileOpen,
  Group,
  Home,
  Logout,
  NotificationsNone,
  Paid,
  PieChart,
  SafetyCheck,
  Settings,
  ViewList
} from "@mui/icons-material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import { Grid, Menu, MenuItem } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import jwtDecode from "jwt-decode";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import stanbicLogo from "../../assets/images/logo.png";
import { logoutShareholder } from "../../service/userService";
import { useAuth } from "../../store/authContext";
import SideDrawerListItem from "./sideDrawerListItem";
import SideDrawerListItemSelect from "./sideDrawerListItemSelect";
import { useDispatch } from "react-redux";
import { setAccountingActive } from "../../store/dividendsSlice";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer({ children }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [anchor, setAnchor] = useState(null);
  const [myMenu, setMyMenu] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const { setUser } = useAuth();

  const menuOpen = Boolean(anchor);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setAnchor(null);
  };
  const handleClick = (event) => {
    setAnchor(event.currentTarget);
  };
  const logout = () => {
    logoutShareholder().then((response) => {
      setAnchor(null);
      sessionStorage.clear();
      setUser(null);
      navigate("/");
    });
  };
  const showProfile = () => {
    navigate(`/admin_profile/${jwtDecode(sessionStorage.getItem("token")).id}`);
  };

  

  // Function to open the menu
  const openMenu = (event) => {
    setMenuOpen(true);
    setMenuAnchorEl(event.currentTarget);
  };

  // Function to close the menu
  const closeMenu = () => {
    setMenuOpen(false);
    setMenuAnchorEl(null);
  };

  // Define the menu items
  const menuItems = [
    {
      label: "Payment Schedules",
      route: "/schedules",
      onClick: () => {
        dispatch(setAccountingActive(true));       
        // Handle item 1 click
        navigate("/schedules");
        closeMenu();
      },
    },
    {
      label: "Approved Payments",
      route: "/approved-payments",
      onClick: () => {
        // Handle item 2 click
        dispatch(setAccountingActive(true));
        navigate("/approved-payments");
        closeMenu();
      },
    },
  ];

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ backgroundColor: "#ffffff" }}>
        <Toolbar>
          <Grid container>
            <Grid item md={4} lg={6}>
              <IconButton
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  color: "#052A6C",
                  marginRight: 5,
                  ...(open && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item md={4} lg={3}></Grid>
            <Grid item md={4} lg={3}>
              <Grid container>
                <Grid item md={4} lg={3}>
                  <IconButton sx={{ color: "#052A6C" }}>
                    <NotificationsNone></NotificationsNone>
                  </IconButton>
                </Grid>
                <Grid item md={4} lg={6}>
                  <Typography
                    sx={{
                      color: "#052A6C",
                      textAlign: "center",
                      paddingTop: 1,
                    }}
                  >
                    <strong>Welcome,</strong>{" "}
                    {sessionStorage.getItem("username")}
                  </Typography>
                </Grid>
                <Grid item md={4} lg={3}>
                  <IconButton sx={{ color: "#052A6C" }} onClick={handleClick}>
                    <AccountCircle></AccountCircle>
                  </IconButton>
                  <Menu
                    anchorEl={anchor}
                    open={menuOpen}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    transformOrigin={{ vertical: "top", horizontal: "left" }}
                    sx={{
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    <MenuItem onClick={showProfile}>
                      <AccountCircle fontSize="small" /> Profile
                    </MenuItem>
                    <MenuItem onClick={logout}>
                      <Logout fontSize="small" /> Logout
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          "& .MuiDrawer-paper": {
            backgroundColor: "#052A6C",
          },
        }}
      >
        <DrawerHeader>
          <Grid container>
            <Grid item sm={2} md={2}></Grid>
            <Grid item sm={8} md={8} 
              sx={{ 
                display: "flex",
                flexDirection: "column",
                alignItems: "center",}}
            >
              <img
                style={{
                  height: "60px",
                  horizontalAlign: "center",
                }}
                src={stanbicLogo}
              />
            </Grid>
            <Grid item sm={2} md={2} sx={{
              display: "flex",
              alignItems: "right",
              justifyContent: "center",
              }}>
              <IconButton onClick={handleDrawerClose} sx={{color: '#ffffff' }}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </Grid>
          </Grid>
        </DrawerHeader>
        <Divider />
        <List>
          <SideDrawerListItem listText="Home" to="/administrator-dashboard">
            <Home />
          </SideDrawerListItem>
          <SideDrawerListItem listText="Administrators" to="/administrators">
            <AccountCircle />
          </SideDrawerListItem>
          <SideDrawerListItem listText="Shareholders" to="/shareholders">
            <PieChart />
          </SideDrawerListItem>
          <SideDrawerListItem  listText="Account Managers"  to="/account-officers" >
            <Group />
          </SideDrawerListItem>
          <SideDrawerListItem listText="Tickets" to="/tickets">
            <ViewList />
          </SideDrawerListItem>
          <Divider />
          <SideDrawerListItemSelect listText="Accounting" to="#" onClick={openMenu} menu={myMenu}>
            <Paid />
          </SideDrawerListItemSelect>

          <Menu anchorEl={menuAnchorEl} open={isMenuOpen} onClose={closeMenu}>
            {menuItems.map((item, index) => (
              <MenuItem key={index} 
                onClick={ item.onClick }
              >
                  {item.label}
              </MenuItem>
            ))}
          </Menu>
          <Divider />

          <SideDrawerListItem listText="File approve" to="/file-approve">
            <FileOpen />
          </SideDrawerListItem>
          <SideDrawerListItem listText="Settings" to="/settings">
            <Settings />
          </SideDrawerListItem>
          <SideDrawerListItem listText="Logs" to="/logs">
            <SafetyCheck />
          </SideDrawerListItem>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
}
