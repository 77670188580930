import { Grid, Skeleton, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BulkUploadApproveActions from "../../components/BulkUploadApproveActions/BulkUploadApproveActions";
import BulkUploadApproveStatus from "../../components/BulkUploadApproveActions/BulkUploadApproveStatus";
import DataTable from "../../components/tables/DataTable";
import NavigationContext from "../../store/NavigationContext";

import AlertModal from "../../components/alertModal";
import MiniDrawer from "../../components/newSideDrawer/miniSideDrawer";
import { checkAuthorities } from "../../service/securityService";
import { getFileUploads } from "../../service/fileService";
import FlyTable from "../../components/tables/FlyTable";

const AdministratorShareholderBulkUploadApprove = () => {
  const navigationContext = useContext(NavigationContext);
  const { sidebar } = navigationContext;
  const alert = useSelector((state) => state.alert);
  // const navigate = useNavigate();
  // const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [backPagination, setBackPagination] = useState({});
  const [fileUploads, setFileUploads] = useState([]);
  const headers = ['ID', 'Name', 'Entries', 'Loading', 'Validated', 'Approved', 'Status', 'Actions']
  const actions = ['Approve','View', 'Delete']

  // const columns = [
  //   { field: "file_id", headerName: "File ID", width: 150 },
  //   { field: "file_name", headerName: "File Name", width: 200 },
  //   { field: "total_entry_count", headerName: "File Entries", width: 100 },
  //   { field: "entries_validated", headerName: "Validated Entries", width: 150 },
  //   { field: "entries_loaded", headerName: "Loaded Entries", width: 120 },
  //   {
  //     field: "file_upload_status",
  //     headerName: "Status",
  //     width: 200,
  //     renderCell: (params) => <BulkUploadApproveStatus {...{ params }} />,
  //   },
  //   {
  //     field: "actions",
  //     headerName: "Actions",
  //     type: "actions",
  //     width: 200,
  //     renderCell: (params) => <BulkUploadApproveActions {...{ params }} />,
  //   },
  // ];

  const rows = fileUploads;
  console.log('rows: ', rows);

  const bulkShareholderTableStyles = {
    marginTop: "20px",
    backgroundColor: "white",
  };

  const requiredResults = 20;

  useEffect(() => {
    checkAuthorities({
      can_create: "FILE_UPLOAD_MODULE:CAN_CREATE",
      can_read: "FILE_UPLOAD_MODULE:CAN_READ",
      can_update: "FILE_UPLOAD_MODULE:CAN_UPDATE",
      can_delete: "FILE_UPLOAD_MODULE:CAN_DELETE",
      can_export: "FILE_UPLOAD_MODULE:CAN_EXPORT",
      can_approve: "FILE_UPLOAD_MODULE:CAN_APPROVE",
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    console.log('Loading: ', loading);
    getFileUploads({ size: requiredResults, pageIndex: pageIndex }).then((response) => {
      console.log('File uploads response:', response.data);
      if (response.data.operation_code === 0) {
        setBackPagination({
          total_pages: response.data.total_pages,
          page_index: response.data.page_index,
          total_elements: response.data.total_elements,
          records_displayed: response.data.records_displayed
        })
        setFileUploads(...fileUploads,response.data.data.map((element) => ({
          id: element.file_id,
          file_name: element.file_name,
          total_entry_count: element.total_entry_count.toString(),
          total_entries_validated: element.total_entries_validated.toString(),
          entries_validated: element.entries_validated.toString(),
          entries_loaded: element.entries_loaded.toString(),
          status: element.file_upload_status
        })));        
      } else setFileUploads([])
    });
    setLoading(false);
  }, [pageIndex]);

  return (
    <MiniDrawer>
      {/* {alert.open === true ? (
        <AlertModal
          sx={{ margin: "20px", width: "50%", align: "right" }}
        ></AlertModal>
      ) : (
        <></>
      )}
      <Grid container spacing={2}>
        <Grid item xs={8} md={8} lg={8}>
          <Typography
            sx={{
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "20px",
              color: " #0A2240",
            }}
          >
            File Approve
          </Typography>
        </Grid>
      </Grid>
      <DataTable
        rows={rows}
        columns={columns}
        loading={!rows.length}
        getRowId={(row) => row.file_id}
        sx={bulkShareholderTableStyles}
      /> */}
      {/* {loading ? <Skeleton sx={{ minWidth: 500, minHeight: 500, margin: 0, padding: 0 }}></Skeleton> :  */}
      {loading ? 
          <Skeleton
            variant="rectangle"
            height={"80vh"}
            width={"80vw"}
          ></Skeleton> : 
          <FlyTable
            header='Files'
            data={fileUploads}
            headers={headers}
            actions={actions}
            handleNextOrPreviousPage={(page) => {
              console.log('Page: ', page);
              // if (backPagination.total_elements > backPagination.records_displayed) {
              //   setPageIndex(pageIndex + 1)                
              // }
            }}/>
      }
    </MiniDrawer>
  );
};

export default AdministratorShareholderBulkUploadApprove;
