import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { TabPanel } from '@mui/lab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  Tab,
  Tooltip,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import shareholder1 from '../../assets/images/shareholder1.png';
import { ShareholderInput } from '../../components/forms/FormComponents';
import ClaimTicket from '../../components/ticket/ClaimTicket';
import CreateTicket from '../../components/ticket/CreateTicket';
import DividendTicket from '../../components/ticket/DividendTicket';
import UpdateTicket from '../../components/ticket/UpdateTicket';
import { API } from '../../config/API';
import {
  approveTicket,
  getATicketByTicketNumber,
} from '../../service/ticketService';
import NavigationContext from '../../store/NavigationContext';
import {
  openAlert,
  setAlertMessage,
  setAlertTitle,
} from '../../store/alertSlice';
import MiniDrawer from '../../components/newSideDrawer/miniSideDrawer';

const AdministratorTicketsDetailsPageApprove = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ticket_number } = useParams();
  const navigationContext = useContext(NavigationContext);
  const { sidebar } = navigationContext;
  const [selectedTab, setSelectedTab] = useState('1');
  const [comment, setComment] = useState('');
  const [open2, setOpen2] = useState(false);
  const [show2, setShow2] = useState(false);
  const [currentTicket, setCurrentTicket] = useState({});

  // Ticket details
  const user = useSelector((state) => state.user);
  const ticketState = useSelector((state) => state.ticket);
  let content = ticketState.ticket.content;
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const handleShow2 = () => setShow2(true);
  const handleClose2 = () => setShow2(false);
  const [show3, setShow3] = useState(false);
  const handleShow3 = () => setShow3(true);
  const handleClose3 = () => setShow3(false);

  const ticketDetailsStyles = {
    marginBottom: '10px',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    color: '#000000',
  };

  const ticketDetailsHeaderStyles = {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    color: '#000000',
  };

  const tabLabel = {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    color: '#052A6C',
  };

  useEffect(() => {
    fetchTicketByTicketNumber();
  }, []);
  
  const fetchTicketByTicketNumber = () => {
    getATicketByTicketNumber({ ticket_number }).then(response => {
      let ticketDetails = {}
      if (ticket_number.includes('MODS')) {
        ticketDetails = Object.assign(
          {},
          response.data,
          response.data?.data?.content,
          response.data?.data?.content?.payment_mode,
          response.data?.data?.content?.country_tax_rate
        );
      } else if (ticket_number.includes('CRTS')) {
        ticketDetails = Object.assign({}, response.data?.data?.content);
      } else if (ticket_number.includes('CRTM')) {
        ticketDetails = Object.assign({}, response.data?.data?.content);
      } else if (ticket_number.includes('CLAIM')) {
        ticketDetails = Object.assign({}, data, data?.content);
      } else if (ticket_number.includes('CRTD')) {
        ticketDetails = Object.assign({}, data, data?.content);
      } else {
        ticketDetails = {};
      }
      setCurrentTicket(ticketDetails);
    })
  }

  const adminApproveTicket = (ticketId) => {
    approveTicket({ ticketId: ticketId, comment: { comment } }).then(
      (response) => {
        if (response.data.operation_code === 1) {
          dispatch(setAlertTitle('ERROR!'));
          dispatch(setAlertMessage(response.data.message));
          dispatch(openAlert());
          navigate('/tickets');
        } else {
          dispatch(setAlertTitle('Success'));
          dispatch(setAlertMessage(response.data.operation_description));
          dispatch(openAlert());
          navigate('/tickets');
        }
      }
    );
  };
  const adminRejectTicket = async (ticketId) => {
    let response;
    try {
      response = await API.post(`/tickets/${ticketId}?action=REJECT`, {
        comment,
      });

      if (response.status === 202) {
        dispatch(setAlertTitle('Success'));
        dispatch(setAlertMessage(response.data.operation_description));
        dispatch(openAlert());
        navigate('/tickets', {
          replace: true,
        });
      }
    } catch (error) {
      dispatch(setAlertTitle('Success'));
      dispatch(setAlertMessage(error.response.data.operation_description));
      dispatch(openAlert());
      navigate('/tickets', {
        replace: true,
      });
    }
    handleClose2();
  };
  return (
    <MiniDrawer>
      <Card>
          <CardMedia
            sx={{ height: 140 }}
            image={shareholder1}
            title="green iguana"
          />
          <CardContent>
            <TabContext value={selectedTab}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Personal" value="1" sx={tabLabel} />
              </TabList>
              <TabPanel value="1">
                <Grid
                  container
                  spacing={2}
                  sx={{
                    marginBottom: '40px',
                    marginTop: '20px',
                    borderBottom: '1px solid #D2D1D1',
                  }}
                >
                  <Grid item lg={6}>
                    <Typography sx={ticketDetailsHeaderStyles}>
                      Ticket Details
                    </Typography>
                  </Grid>
                  <Grid item lg={6}></Grid>
                </Grid>
                {currentTicket && ticket_number.includes('MODS') && (
                  <UpdateTicket {...currentTicket} />
                )}
                {currentTicket && ticket_number.includes('CRTS') && (
                  <CreateTicket {...currentTicket} />
                )}
                {currentTicket && ticket_number.includes('CLAIM') && (
                  <ClaimTicket {...currentTicket} />
                )}
                {currentTicket && ticket_number.includes('CRTD') && (
                  <DividendTicket {...currentTicket} />
                )}
                {/** What does this code below do? */}
                <Grid container spacing={2} sx={{ marginBottom: '40px' }}>
                  <Grid item lg={6}>
                    <Typography sx={ticketDetailsStyles}>
                      Ticket Number
                    </Typography>
                    <Typography sx={ticketDetailsStyles}>
                      Shareholder Name
                    </Typography>
                    <Typography sx={ticketDetailsStyles}>Email</Typography>
                    <Typography sx={ticketDetailsStyles}>Phone</Typography>
                    <Typography sx={ticketDetailsStyles}>Address</Typography>
                    <Typography sx={ticketDetailsStyles}>Category</Typography>
                    <Typography sx={ticketDetailsStyles}>Tin</Typography>
                    <Typography sx={ticketDetailsStyles}>
                      Shareholder COM Number
                    </Typography>
                    <Typography sx={ticketDetailsStyles}>
                      Shareholder SCD Number
                    </Typography>
                    <Typography sx={ticketDetailsStyles}>
                      Broker Code
                    </Typography>
                  </Grid>
                  <Grid item lg={6}>
                    <Typography sx={ticketDetailsStyles}>
                      {ticket_number ? ticket_number : 'N/A'}
                    </Typography>
                    <Typography sx={ticketDetailsStyles}>
                      {currentTicket?.shareholder_name
                        ? currentTicket?.shareholder_name
                        : 'N/A'}
                    </Typography>
                    <Typography sx={ticketDetailsStyles}>
                      {currentTicket?.primary_email
                        ? currentTicket?.primary_email
                        : 'N/A'}
                    </Typography>
                    <Typography sx={ticketDetailsStyles}>
                      {currentTicket?.primary_phone_number
                        ? currentTicket?.primary_phone_number
                        : 'N/A'}
                    </Typography>
                    <Typography sx={ticketDetailsStyles}>
                      {currentTicket?.primary_address
                        ? currentTicket?.primary_address
                        : 'N/A'}
                    </Typography>
                    <Typography sx={ticketDetailsStyles}>
                      {currentTicket?.category
                        ? currentTicket?.category
                        : 'N/A'}
                    </Typography>
                    <Typography sx={ticketDetailsStyles}>
                      {currentTicket?.tin ? currentTicket?.tin : 'N/A'}
                    </Typography>
                    <Typography sx={ticketDetailsStyles}>
                      {currentTicket?.shareholder_com_number
                        ? currentTicket?.shareholder_com_number
                        : 'N/A'}
                    </Typography>
                    <Typography sx={ticketDetailsStyles}>
                      {currentTicket?.shareholder_scd_number
                        ? currentTicket?.shareholder_scd_number
                        : 'N/A'}
                    </Typography>
                    <Typography sx={ticketDetailsStyles}>
                      {currentTicket?.broker_code
                        ? currentTicket?.broker_code
                        : 'N/A'}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item lg={6}>
                    <Button
                      variant="contained"
                      sx={{
                        width: '90px',
                        height: '40px',
                        backgroundColor: '#B20808',
                        borderRadius: '8px',
                        color: '#FFFFFF',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        fontSize: '16px',
                        ':hover': {
                          backgroundColor: '#052A6C',
                          boxShadow: 'none',
                        },
                        textTransform: 'none',
                      }}
                      onClick={handleShow2}
                      disabled={
                        ticketState.ticket?.workflow_status === 'APPROVED' ||
                        ticketState.ticket?.workflow_status === 'REJECTED'
                      }
                      // disabled={
                      //   user.ticket.workflow_status === 'APPROVED' ||
                      //   user.ticket.workflow_status === 'REJECTED'
                      // }
                    >
                      Reject
                    </Button>
                  </Grid>
                  <Grid item lg={6}>
                    <Button
                      variant="contained"
                      sx={{
                        width: '90px',
                        height: '40px',
                        backgroundColor: '#052A6C',
                        borderRadius: '8px',
                        color: '#FFFFFF',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        fontSize: '16px',
                        ':hover': {
                          backgroundColor: '#052A6C',
                          boxShadow: 'none',
                        },
                        textTransform: 'none',
                      }}
                      disabled={
                        ticketState.ticket?.workflow_status === 'APPROVED' ||
                        ticketState.ticket?.workflow_status === 'REJECTED'
                      }
                      onClick={handleShow3}
                    >
                      Approve
                    </Button>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="2"> </TabPanel>
              <TabPanel value="3"> </TabPanel>
              <TabPanel value="4"> </TabPanel>
            </TabContext>
          </CardContent>
        </Card>

        <Dialog
          open={show2}
          onClose={handleClose2}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            '& .css-hlj6pa-MuiDialogActions-root': {
              paddingRight: '24px',
              paddingLeft: '24px',
            },
            '& .css-hlj6pa-MuiDialogActions-root>:not(:first-of-type)': {
              marginLeft: '60%',
            },
          }}
        >
          <DialogContent>
            <FormControl>
              <Grid container spacing={2} sx={{ marginBottom: '10px' }}>
                <Grid item md={1}>
                  <Tooltip title="View dividend details">
                    <ErrorOutlineIcon fontSize="medium" color="error" />
                  </Tooltip>
                </Grid>
                <Grid item md={11}>
                  <Typography
                    sx={{
                      fontStyle: 'normal',
                      fontWeight: '700',
                      fontSize: '16px',
                      color: '#00000',
                    }}
                  >
                    Are you sure you would like to reject the modification?
                    Please give a reason for the rejection
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item md={1}></Grid>
                <Grid
                  item
                  md={10}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <ShareholderInput
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    sx={{ width: '100%' }}
                  ></ShareholderInput>
                </Grid>
                <Grid item md={1}></Grid>
              </Grid>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Grid container spacing={2} sx={{ marginBottom: '20px' }}>
              <Grid item md={3}>
                <Button
                  variant="contained"
                  onClick={handleClose2}
                  sx={{
                    backgroundColor: '#B20808',
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: '#B20808',
                      color: '#FFFFFF',
                    },
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item md={3}></Grid>
              <Grid item md={3}></Grid>
              <Grid
                item
                md={3}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'end',
                }}
              >
                <Button
                  variant="contained"
                  autoFocus
                  onClick={() => adminRejectTicket(ticketState.ticket?.ticket_number)}
                  sx={{
                    backgroundColor: '#052A6C',
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: '#052A6C',
                      color: '#FFFFFF',
                    },
                  }}
                >
                  Reject
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>

        <Dialog
          open={show3}
          onClose={handleClose3}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            '& .css-hlj6pa-MuiDialogActions-root': {
              paddingRight: '24px',
              paddingLeft: '24px',
            },
            '& .css-hlj6pa-MuiDialogActions-root>:not(:first-of-type)': {
              marginLeft: '60%',
            },
          }}
        >
          <DialogContent>
            <FormControl>
              <Grid container spacing={2} sx={{ marginBottom: '10px' }}>
                <Grid item md={1}>
                  <Tooltip title="View dividend details">
                    <ErrorOutlineIcon fontSize="medium" color="error" />
                  </Tooltip>
                </Grid>
                <Grid item md={11}>
                  <Typography
                    sx={{
                      fontStyle: 'normal',
                      fontWeight: '700',
                      fontSize: '16px',
                      color: '#00000',
                    }}
                  >
                    Please give a comment on approval.
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item md={1}></Grid>
                <Grid
                  item
                  md={10}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <ShareholderInput
                    onChange={(e) => setComment(e.target.value)}
                    sx={{ width: '100%' }}
                  ></ShareholderInput>
                </Grid>
                <Grid item md={1}></Grid>
              </Grid>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Grid container spacing={2} sx={{ marginBottom: '20px' }}>
              <Grid item md={3}>
                <Button
                  variant="contained"
                  onClick={handleClose2}
                  sx={{
                    backgroundColor: '#B20808',
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: '#B20808',
                      color: '#FFFFFF',
                    },
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item md={3}></Grid>
              <Grid item md={3}></Grid>
              <Grid
                item
                md={3}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'end',
                }}
              >
                <Button
                  variant="contained"
                  autoFocus
                  onClick={() => adminApproveTicket(ticketState.ticket?.ticket_number)}
                  sx={{
                    backgroundColor: '#052A6C',
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: '#052A6C',
                      color: '#FFFFFF',
                    },
                  }}
                >
                  Approve
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
    </MiniDrawer>
  )
};
export default AdministratorTicketsDetailsPageApprove;
