import { alpha, Autocomplete, Box, Button, Divider, FormControl, FormGroup, Grid, Input, InputBase, InputLabel, Select, styled, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import useValidation from "../../hooks/useValidate";
import { addAccountOfficer, getAllShareholders, getShareholders, PostAccountOfficer } from "../../service/userService";
import { openAlert, setAlertMessage, setAlertTitle } from "../../store/alertSlice";
import { addShareholderCode, setShareholders, appendAccountOfficer } from "../../store/userSlice";
import { ShareholderAutoComplete, ShareholderInput } from "./FormComponents";
import { checkAuthorities } from "../../service/securityService";

const AccountOfficerRegForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.user);
    const [shareholderCode, setShareholderCode] = useState(user.dynamicUser.shareholder_code);
    const shareholder = user.shareholder;
    const [newAccountOfficer, setNewAccountOfficer] = useState({
        shareholder_code: shareholder.shareholder_code,
        first_name: "",
        last_name: "",
        primary_phone_number: "",
        primary_address: "",
        primary_email: "",
        username: "",
    });
    const {handleValidationCheck, values, errors} = useValidation();
    const accountOfficer = user.dynamicUser;
    const shareholders = user.shareholders;
    const myShareholderCode = useSelector(state => state.user.shareholderCode);

    useEffect (() => {
        checkAuthorities({
        can_create: "ACCOUNT_OFFICER_MODULE:CAN_CREATE",
        can_read: "ACCOUNT_OFFICER_MODULE:CAN_READ",
        can_update: "ACCOUNT_OFFICER_MODULE:CAN_UPDATE",
        can_delete: "ACCOUNT_OFFICER_MODULE:CAN_DELETE",
        can_export: "ACCOUNT_OFFICER_MODULE:CAN_EXPORT",
        can_approve: "ACCOUNT_OFFICER_MODULE:CAN_APPROVE",
        });
        getAllShareholders({size: 240, pageIndex:0}).then((response) =>{
            dispatch(setShareholders(response.data.data))
        })
    }, []);

    const handleSubmit = () => {
        addAccountOfficer(newAccountOfficer).then((response) =>{
            if (response.data.operation_code === 0) {
                dispatch(setAlertTitle("Success"));
                dispatch(setAlertMessage(response.data.operation_description));
                dispatch(appendAccountOfficer(response.data))
            } else {
                dispatch(setAlertTitle("Error"));
                dispatch(setAlertMessage(response.operation_description));
            }            
            dispatch(openAlert());
        })
        navigate('/account-officers');
    }
    if (!_.isEmpty(shareholder)) {
        return (
            <>
            <Divider />
            <Box sx={{marginTop: 4, marginBottom: 4}} >
                <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                    <FormControl variant="standard" sx={{ width: "100%" }}>
                        <InputLabel
                        shrink
                        htmlFor="bootstrap-input"
                        sx={{
                            color: "#000000",
                        }}
                        >
                        Shareholder code <span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <ShareholderInput
                        disabled
                        name="shareholderName"
                        id="firstNameTxt"
                        value={shareholder.shareholder_code}
                        sx={{ width: "100%" }}
                        />
                    </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                    <FormControl variant="standard" sx={{ width: "100%" }}>
                        <InputLabel
                        shrink
                        htmlFor="bootstrap-input"
                        sx={{
                            color: "#000000",
                        }}
                        >
                        Shareholder Name <span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <ShareholderInput
                        disabled
                        name="shareholderName"
                        id="firstNameTxt"
                        value={shareholder.shareholder_name}
                        sx={{ width: "100%" }}
                        />
                    </FormControl>
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <FormGroup>
                            <InputLabel>First Name</InputLabel>
                            <ShareholderInput id='f_name_txt'
                                name="firstName"
                                onChange={(event) => {
                                    setNewAccountOfficer({
                                      ...newAccountOfficer,
                                      first_name: event.target.value,
                                    });
                                    handleValidationCheck(event);
                                }}
                                value={newAccountOfficer.first_name}
                            />
                            {errors.firstName && <Typography color='red' fontSize='small' sx={{marginBottom: 2}}>{errors.firstName}</Typography>}
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormGroup>
                            <InputLabel>Last Name</InputLabel>
                            <ShareholderInput id='l_name_txt'
                                name="lastName"
                                onChange={(event) => {
                                    setNewAccountOfficer({
                                      ...newAccountOfficer,
                                      last_name: event.target.value,
                                    });
                                    handleValidationCheck(event);
                                }}
                                value={newAccountOfficer.last_name}
                            />
                            {errors.lastName && <Typography color='red' fontSize='small' sx={{marginBottom: 2}}>{errors.lastName}</Typography>}
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormGroup>
                            <InputLabel>Email</InputLabel>
                            <ShareholderInput id='email_txt'
                                name="email"
                                onChange={(event) => {
                                    setNewAccountOfficer({
                                      ...newAccountOfficer,
                                      primary_email: event.target.value,
                                    });
                                    handleValidationCheck(event);
                                }}
                                value={newAccountOfficer.primary_email}
                            />
                            {errors.email && <Typography color='red' fontSize='small' sx={{marginBottom: 2}}>{errors.email}</Typography>}
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormGroup>
                            <InputLabel>Phone Number</InputLabel>
                            <ShareholderInput id='phone_txt'
                                name="primaryPhoneNumber"
                                onChange={(event) => {
                                    setNewAccountOfficer({
                                      ...newAccountOfficer,
                                      primary_phone_number: event.target.value,
                                    });
                                    handleValidationCheck(event);
                                }}
                                value={newAccountOfficer.primary_phone_number}
                            />
                            {errors.primaryPhoneNumber && <Typography color='red' fontSize='small' sx={{marginBottom: 2}}>{errors.primaryPhoneNumber}</Typography>}
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormGroup>
                            <InputLabel>Address</InputLabel>
                            <ShareholderInput id='address_txt'
                                name="primaryAddress"
                                onChange={(event) => {
                                    setNewAccountOfficer({
                                      ...newAccountOfficer,
                                      primary_address: event.target.value,
                                    });
                                    handleValidationCheck(event);
                                }}
                                value={newAccountOfficer.primary_address}
                            />
                            {errors.primaryAddress && <Typography color='red' fontSize='small' sx={{marginBottom: 2}}>{errors.primaryAddress}</Typography>}
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormGroup>
                            <InputLabel>Username</InputLabel>
                            <ShareholderInput id='username_txt'
                                onChange={
                                    (event) => {
                                        setNewAccountOfficer({
                                          ...newAccountOfficer,
                                          username: event.target.value,
                                        });
                                    }}
                                value={newAccountOfficer.username}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
            </Box>
            <Divider />
            <Grid container>
                <Grid item xs={12} md={9} lg={10}></Grid>
                <Grid item xs={6} md={3} lg={2}>
                    <Button
                        variant="contained"
                        sx={{marginLeft: '50%'}}
                        onClick={handleSubmit}
                        >Submit</Button>
                </Grid>
            </Grid>
            </>            
        )        
    }
    return (
        <>
        <Divider />
        <Box sx={{marginTop: 4, marginBottom: 4}} >
            <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                    <FormGroup>
                        <InputLabel>Shareholder</InputLabel>
                        <ShareholderAutoComplete
                            disablePortal
                            value={shareholderCode}
                            onChange={(event, shareholder) => {
                                setShareholderCode(shareholder.shareholder_code);
                                setNewAccountOfficer({
                                  ...newAccountOfficer,
                                  shareholder_code: event.target.value,
                                });
                                dispatch(addShareholderCode(shareholder.shareholder_code));
                            }}
                            options={shareholders}
                            getOptionLabel={(option) => option.shareholder_name}
                            renderInput = {(params) => <TextField {...params} />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormGroup>
                        <InputLabel>First Name</InputLabel>
                        <ShareholderInput id='f_name_txt'
                            name="firstName"
                            onChange={(event) => {
                                setNewAccountOfficer({
                                  ...newAccountOfficer,
                                  first_name: event.target.value,
                                });
                                handleValidationCheck(event);
                            }}
                            value={newAccountOfficer.first_name}
                        />
                        {errors.firstName && <Typography color='red' fontSize='small' sx={{marginBottom: 2}}>{errors.firstName}</Typography>}
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormGroup>
                        <InputLabel>Last Name</InputLabel>
                        <ShareholderInput id='l_name_txt'
                            name="lastName"
                            onChange={(event) => {
                                setNewAccountOfficer({
                                  ...newAccountOfficer,
                                  last_name: event.target.value,
                                });
                                handleValidationCheck(event);
                            }}
                            value={newAccountOfficer.last_name}
                        />
                        {errors.lastName && <Typography color='red' fontSize='small' sx={{marginBottom: 2}}>{errors.lastName}</Typography>}
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormGroup>
                        <InputLabel>Email</InputLabel>
                        <ShareholderInput id='email_txt'
                            name="email"
                            onChange={(event) => {
                                setNewAccountOfficer({
                                  ...newAccountOfficer,
                                  primary_email: event.target.value,
                                });
                                handleValidationCheck(event);
                            }}
                            value={newAccountOfficer.primary_email}
                        />
                        {errors.email && <Typography color='red' fontSize='small' sx={{marginBottom: 2}}>{errors.email}</Typography>}
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormGroup>
                        <InputLabel>Phone Number</InputLabel>
                        <ShareholderInput id='phone_txt'
                            name="primaryPhoneNumber"
                            onChange={(event) => {
                                setNewAccountOfficer({
                                  ...newAccountOfficer,
                                  primary_phone_number: event.target.value,
                                });
                                handleValidationCheck(event);
                            }}
                            value={newAccountOfficer.primary_phone_number}
                        />
                        {errors.primaryPhoneNumber && <Typography color='red' fontSize='small' sx={{marginBottom: 2}}>{errors.primaryPhoneNumber}</Typography>}
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormGroup>
                        <InputLabel>Address</InputLabel>
                        <ShareholderInput id='address_txt'
                            name="primaryAddress"
                            onChange={(event) => {
                                setNewAccountOfficer({
                                  ...newAccountOfficer,
                                  primary_address: event.target.value,
                                });
                                handleValidationCheck(event);
                            }}
                            value={newAccountOfficer.primary_address}
                        />
                        {errors.primaryAddress && <Typography color='red' fontSize='small' sx={{marginBottom: 2}}>{errors.primaryAddress}</Typography>}
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormGroup>
                        <InputLabel>Username</InputLabel>
                        <ShareholderInput id='username_txt'
                            onChange={
                                (event) => {
                                    setNewAccountOfficer({
                                      ...newAccountOfficer,
                                      username: event.target.value,
                                    });
                                    dispatch(addAUsername(event.target.value)                                
                            )}}
                            value={newAccountOfficer.username}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                </Grid>
            </Grid>
        </Box>
        <Divider />
        <Grid container>
            <Grid item xs={12} md={9} lg={10}></Grid>
            <Grid item xs={6} md={3} lg={2}>
                <Button
                    variant="contained"
                    sx={{marginLeft: '50%'}}
                    onClick={handleSubmit}
                    >Submit</Button>
            </Grid>
        </Grid>
        </>
    )
}
export default AccountOfficerRegForm;