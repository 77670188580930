import { Box, Button, Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AlertModal from "../../components/alertModal";
import { confirmShareholderSecretKey, logoutShareholder } from "../../service/userService";
import { MuiOtpInput } from "mui-one-time-password-input";
import { openAlert, setAlertMessage, setAlertTitle } from "../../store/alertSlice";
const AdministratorQRSetup = () => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alert);
  const [otp, setOtp] = useState("");
  
  const userQRCode = useSelector(state => state.user.userQrCode)

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const logout = () => {
    logoutShareholder().then((response) => {
      sessionStorage.clear();
      navigate("/");
    });
  };

  //confirm the otp
  const handleComplete = (otp) => {
    let data = {
      code: otp,
      auth_factor_method: "AUTHENTICATOR_APP",
    };
    confirmShareholderSecretKey(data).then((response) => {
      if (response.operation_code === 1) {        
        dispatch(setAlertTitle("Error"));
        dispatch(setAlertMessage(response.message));   
        dispatch(openAlert());       
      }else {
        dispatch(
          setAlertMessage(
            "Successfully enabled 2FA. You can login with your new credentials"
          )
        );
        dispatch(setAlertTitle("Success"));
        dispatch(openAlert());
        navigate("/administrator-dashboard", { replace: true });
      }
      
    });
  };
  const otpInputStyle = {
    "& .MuiInputBase-input": {
      border: "1px solid #000",
      borderRadius: 1,
    },
  };

  return (
    <Grid 
      container 
      justifyContent="center" 
      sx={{
        minHeight: '100vh',
        backgroundImage: 'linear-gradient(#0033A1, #000029)',
        }}>
      <Grid
        item
        alignSelf='center'
        sx={{
            width:{
                lg: '30%',
                md: '40%',
                sm: '50%',
                xs: '60%'
            },
        }}
      >
        {alert.open === true ? (
            <AlertModal
              sx={{
                margin: "20px",
                width: "50%",
                align: "right",
              }}
            ></AlertModal>
          ) : (
            <></>
          )}
        <Card>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              width: {
                md: "100%",
                sm: "100%",
                xs: "100%",
              },
          }}>              
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "16px",
                color: "#444A4A",
              }}
            >
              Two-factor authentication adds an additional layer of security to
              your account by requiring more than just a password to sign in.
            </Typography>
            <img src={userQRCode} />           
            <MuiOtpInput
                length={6}
                autoFocus
                value={otp}
                onChange={handleChange}
                onComplete={handleComplete}
                sx={otpInputStyle}
            />
          </CardContent>
        </Card>
      </Grid>
      </Grid>
  );
};

export default AdministratorQRSetup;
