
import { useContext } from 'react'
import NavigationContext from '../../store/NavigationContext'
import ShareholderRegStepper from '../../components/shareholderRegistrationStepper'
import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import MiniDrawer from '../../components/newSideDrawer/miniSideDrawer'


function CreateShareholderPage(props) {
  const navigationContext = useContext(NavigationContext)
  const { sidebar } = navigationContext
  return (
    <MiniDrawer>
      <Card>
               <CardContent>
                 <Typography variant='h6' >Add new Shareholder</Typography>
                 <ShareholderRegStepper />
               </CardContent>
             </Card>
    </MiniDrawer>
  )
}

export default CreateShareholderPage