import { Box, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../components/tables/DataTable";
import { checkAuthorities } from "../../service/securityService";
import { getAllAuthenticationLogs } from "../../service/userService";
import NavigationContext from "../../store/NavigationContext";
import { setAuthenticationLogs, setTotalDataElements, setTotalPages } from "../../store/userSlice";
import MiniDrawer from "../../components/newSideDrawer/miniSideDrawer";

const requiredResults = 240;
const AdministratorAuthenticationLogs = () => {
  const navigationContext = useContext(NavigationContext);
  const { sidebar } = navigationContext;
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    checkAuthorities({
      can_create: "ADMINISTRATOR_MODULE:CAN_CREATE",
      can_read: "ADMINISTRATOR_MODULE:CAN_READ",
      can_update: "ADMINISTRATOR_MODULE:CAN_UPDATE",
      can_delete: "ADMINISTRATOR_MODULE:CAN_DELETE",
      can_export: "ADMINISTRATOR_MODULE:CAN_EXPORT",
      can_approve: "ADMINISTRATOR_MODULE:CAN_APPROVE",
    });

    getAllAuthenticationLogs({ size: requiredResults, pageIndex: 0 }).then(
      (response) => {
        dispatch(setAuthenticationLogs(response.data.data));
        dispatch(setTotalPages(response.data.total_pages));
        dispatch(setTotalDataElements(response.data.total_elements));
      }
    );
  }, []);

  const columns = [
    { field: "created_on", headerName: "Date", width: 150 },
    { field: "username", headerName: "Username", width: 150 },
    { field: "primary_email", headerName: "Email", width: 150 },
    { field: "auth_status", headerName: "Auth Status", width: 150 },
    { field: "auth_event_message", headerName: "Message", width: 150 },
    { field: "query_string", headerName: "Query String", width: 150 },
    { field: "remote_host", headerName: "Remote Host", width: 150 },
    { field: "remote_addr", headerName: "Remote Address", width: 150 },
    { field: "xforwarded_for", headerName: "Xforwarded_for", width: 150 },
    { field: "request_uri", headerName: "Request URl", width: 150 },
    { field: "server_name", headerName: "Server Name", width: 150 },
  ];

  const rows = user.authenticationLogs;
  const total_logs = user.data_total_elements;

  const authenticationLogsTableStyles = {
    height: "400px",
    marginTop: "20px",
    marginBottom: "20px",
    backgroundColor: "white",
  };

  return (
    <MiniDrawer>
      <Typography
          sx={{
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "20px",
            color: " #0A2240",
          }}
        >
          All Authentication Logs: {total_logs}
        </Typography>
        <DataTable
          rows={rows}
          columns={columns}
          loading={!user.authenticationLogs}
          getRowId={(row) => row.id}
          sx={authenticationLogsTableStyles}
        />      
    </MiniDrawer>
  )
};

export default AdministratorAuthenticationLogs;
