import { omit } from "lodash";
import { useState } from "react";

const useValidation = () => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});

  const handleValidationCheck = (event) => {
    event.persist();
    let name = event.target.name;
    let value = event.target.value;
    validate(event, name, value);
  };

  const validate = (event, name, value) => {
    switch (name) {
      case "shareholderName":
        if (value.length <= 2) {
          setErrors({
            ...errors,
            shareholderName:
              "Shareholder name should be atleast 3 characters long.",
          });
        } else {
          let newObject = omit(errors, "shareholderName");
          setErrors(newObject);
        }

        break;
        if (value.length <= 3) {
          setErrors({
            ...errors,
            bankName: "Invalid Bank name",
          });
        } else {
          let newObject = omit(errors, "bankName");
          setErrors(newObject);
        }

        break;
      case "password":
        let spChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (value.length < 12) {
          setErrors({
            ...errors,
            password:
              "Password should be atleast 12 characters long.(Example: SilenceIsGold123#)",
          });
        } else if (!spChars.test(value)) {
          setErrors({
            ...errors,
            password:
              "Password should contain atleast one special character(Example: SilenceIsGold123#)",
          });
        } else if (!/\d/.test(value)) {
          setErrors({
            ...errors,
            password:
              "Password should contain atleast one number(Example: SilenceIsGold123#)",
          });
        } else if (!/[A-Z]/.test(value)) {
          setErrors({
            ...errors,
            password:
              "Password should contain atleast one upper case character(Example: SilenceIsGold123#)",
          });
        } else if (!/[a-z]/.test(value)) {
          setErrors({
            ...errors,
            password:
              "Password should contain atleast one lower case character(Example: SilenceIsGold123#)",
          });
        } else {
          let newObject = omit(errors, "password");
          setErrors(newObject);
        }

        break;
      case "firstName":
        if (value.length <= 2) {
          setErrors({
            ...errors,
            firstName: "First name should be atleast 3 characters long.",
          });
        } else {
          let newObject = omit(errors, "firstName");
          setErrors(newObject);
        }

        break;
      case "lastName":
        if (value.length <= 2) {
          setErrors({
            ...errors,
            lastName: "Last name should be atleast 3 characters long.",
          });
        } else {
          let newObject = omit(errors, "lastName");
          setErrors(newObject);
        }

        break;
      case "companyId":
        if (value.length <= 1) {
          setErrors({
            ...errors,
            companyId:
              "Company Id cannot less than 3 characters. Please input a valid company Id",
          });
        } else {
          let newObject = omit(errors, "companyId");
          setErrors(newObject);
        }

        break;
      case "email":
        if (
          !new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(
            value
          )
        ) {
          setErrors({
            ...errors,
            email: "Invalid email. Please use a valid email (xxxxxx@xxx.xxx).",
          });
        } else {
          let newObject = omit(errors, "email");
          setErrors(newObject);
        }

        break;
      case "secondaryEmail":
        if (
          !new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(
            value
          ) &&
          value !== ""
        ) {
          setErrors({
            ...errors,
            secondaryEmail:
              "Invalid email. Please use a valid email (xxxxxx@xxx.xxx).",
          });
        } else {
          let newObject = omit(errors, "secondaryEmail");
          setErrors(newObject);
        }

        break;
      case "primaryPhoneNumber":
        if (
          !new RegExp(/\+\d{1,4}?\s{1}?\d{1,3}?\s{1}?\d{6,9}?/g).test(value)
        ) {
          setErrors({
            ...errors,
            primaryPhoneNumber:
              "Invalid phone number. Please use a valid phone number (+256 710 123456).",
          });
        } else {
          let newObject = omit(errors, "primaryPhoneNumber");
          setErrors(newObject);
        }

        break;
      case "secondaryPhoneNumber":
        if (
          !new RegExp(/\+\d{1,4}?\s{1}?\d{1,3}?\s{1}?\d{6,9}?/g).test(value) &&
          value !== ""
        ) {
          setErrors({
            ...errors,
            secondaryPhoneNumber:
              "Invalid phone number. Please use a valid phone number (+256 710 123456).",
          });
        } else {
          let newObject = omit(errors, "secondaryPhoneNumber");
          setErrors(newObject);
        }

        break;
      case "primaryAddress":
        if (value <= 4) {
          setErrors({
            ...errors,
            primaryAddress:
              "Invalid address. Please input a valid physical address",
          });
        } else {
          let newObject = omit(errors, "primaryAddress");
          setErrors(newObject);
        }

        break;
      case "shareholderCode":
        if (
          !new RegExp(/^SUHL_\d{4}$/).test(value) &&
          value !== ""
        ){
          setErrors({
            ...errors,
            shareholderCode:
              "Invalid shareholder code. Please use a valid shareholder code (SUHL_1234).",
          });
        } else {
          let newObject = omit(errors, "shareholderCode");
          setErrors(newObject);
        }

        break;
      case "shareholderTin":
        if (value.length <= 8) {
          setErrors({
            ...errors,
            shareholderTin:
              "Invalid shareholder Tin. Please use a valid shareholder Tin (1234567890).",
          });
        } else {
          let newObject = omit(errors, "shareholderTin");
          setErrors(newObject);
        }

        break;
      case "bankName":
        if (value.length <= 3) {
          setErrors({
            ...errors,
            bankName: "Invalid Bank name",
          });
        } else {
          let newObject = omit(errors, "bankName");
          setErrors(newObject);
        }

        break;
      case "accountName":
        if (value.length <= 3) {
          setErrors({
            ...errors,
            accountName: "Invalid account name",
          });
        } else {
          let newObject = omit(errors, "accountName");
          setErrors(newObject);
        }

        break;
      case "accountNumber":
        if (value.length <= 6) {
          setErrors({
            ...errors,
            accountNumber: "Invalid account number",
          });
        } else {
          let newObject = omit(errors, "accountNumber");
          setErrors(newObject);
        }

        break;
      case "accountPhoneNumber":
        if (
          !new RegExp(/\+\d{1,4}?\s{1}?\d{1,3}?\s{1}?\d{6,9}?/g).test(value)
        ) {
          setErrors({
            ...errors,
            accountPhoneNumber: "Invalid account phone number",
          });
        } else {
          let newObject = omit(errors, "accountPhoneNumber");
          setErrors(newObject);
        }

        break;

      //Validation for Shareholder SCD Number
      case "shareholderSCDNumber":
        if (!new RegExp(/^\d{13}$/).test(value)) {
          setErrors({
            ...errors,
            shareholderSCDNumber:
              "Shareholder SCD Number must be 13 digits long optionally starting with zeros",
          });
        } else {
          let newObject = omit(errors, "shareholderSCDNumber");
          setErrors(newObject);
        }

        break;

      case "accountPhoneName":
        if (value.length <= 2) {
          setErrors({
            ...errors,
            accountPhoneName: "Invalid Phone registration name",
          });
        } else {
          let newObject = omit(errors, "accountPhoneName");
          setErrors(newObject);
        }

        break;

      default:
        break;
    }
  };
  return {
    values,
    errors,
    handleValidationCheck,
  };
};
export default useValidation;
