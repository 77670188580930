import { Box, Stack } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import { StyledDiv } from '../styled/styledDivs';

function ActivityCard({
  shareholder_initials = 'You',
  user_comment = 'N/A',
  required_revision_instant = Date.now(),
  required_revision_number,
  revision_type = 'No current activity',
}) {
  return (
    <Box
      sx={{
        padding: '10px 16px',
        borderBottom: '1px solid #C4C4C4',
        margin: '10px 0',
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ width: '100%', columnGap: 2 }}
      >
        <Grid item flex={1}>
          <StyledDiv sx={{ color: 'white', bgcolor: '#0033A1' }}>
            {shareholder_initials}
          </StyledDiv>
        </Grid>
        <Grid item flex={9}>
          <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
            <Grid item>
              <p>{user_comment}</p>
            </Grid>
            <Grid item>
              <p>
                {required_revision_instant &&
                  required_revision_instant.split('T')[0]}
              </p>
            </Grid>
          </Stack>
          <Stack direction="row">
            <Grid item>
              {/* <Typography variant="p">{`${revision_type} (${required_revision_number})`}</Typography> */}
              <Typography variant="p">{`${revision_type}`}</Typography>
            </Grid>
          </Stack>
        </Grid>
      </Stack>
    </Box>
  );
}

export default ActivityCard;
