import {
  Box,
  Button,
  Container,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import React from 'react';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const FooterBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.customColor.basic,
  minHeight: 200,
  display: 'flex',
  alignItems: 'flex-end',
}));

const FooterLinkBtn = styled(Button)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 200,
  textTransform: 'capitalize',
}));

function Footer() {
  return (
    <FooterBox>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Stack
          direction="row"
          sx={{
            color: 'white',
            justifyContent: 'space-between',
            flexDirection: {
              xs: 'column',
              sm: 'column',
              md: 'row',
            },
          }}
        >
          <FooterLinkBtn
            color="primary"
            variant="soft"
            startIcon={<CallIcon />}
          >
            +256-757 072 773 /760 451 945 /414 237 504
          </FooterLinkBtn>
          <FooterLinkBtn
            color="primary"
            variant="soft"
            startIcon={<MailIcon />}
          >
            shareholder@candrgroup.co.ug
          </FooterLinkBtn>
          <FooterLinkBtn
            color="primary"
            variant="soft"
            startIcon={<LocationOnIcon />}
          >
            Crested Towers 13th Floor, Short Tower
          </FooterLinkBtn>
        </Stack>
        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
            borderTop: '0.2px solid white',
            paddingTop: 2,
            flexDirection: {
              xs: 'column',
              sm: 'column',
              md: 'row',
            },
          }}
        >
          <Typography
            variant="h6"
            sx={{ color: 'white', fontSize: 16, fontWeight: 300 }}
          >
            Copyright 2022 Stanbic Holdings Uganda Limited
          </Typography>
          <Stack direction="row" sx={{ color: 'white', columnGap: 2 }}>
            <InstagramIcon />
            <TwitterIcon />
            <YouTubeIcon />
            <FacebookIcon />
          </Stack>
        </Stack>
      </Container>
    </FooterBox>
  );
}

export default Footer;
