import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DataTable from "../../components/tables/DataTable";
// import { createSummary } from "../../service/dividendService";
import AlertModalTest from "../../components/alertModalTest";
import MiniDrawer from "../../components/newSideDrawer/miniSideDrawer";
import { createSummary } from "../../service/dividendService";
import { checkAuthorities } from "../../service/securityService";
import { getSchedules } from "../../service/userService";
import NavigationContext from "../../store/NavigationContext";
import {
  openAlert,
  setAlertMessage,
  setAlertTitle,
} from "../../store/alertSlice";
import { setCurrentSchedule, setSchedules } from "../../store/dividendsSlice";
import { setTotalDataElements } from "../../store/userSlice";

const AdministratorSchedules = () => {
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigationContext = useContext(NavigationContext);
  const { sidebar } = navigationContext;
  const dividend = useSelector((state) => state.dividend);
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alert);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const requiredResults = 240;

  useEffect(() => {
    checkAuthorities({
      can_create: "DIVIDENDS_MODULE:CAN_CREATE",
      can_read: "DIVIDENDS_MODULE:CAN_READ",
      can_update: "DIVIDENDS_MODULE:CAN_UPDATE",
      can_delete: "DIVIDENDS_MODULE:CAN_DELETE",
      can_export: "DIVIDENDS_MODULE:CAN_EXPORT",
      can_approve: "DIVIDENDS_MODULE:CAN_APPROVE",
    });

    getSchedules({ size: requiredResults, pageIndex: 0 }).then((response) => {
      dispatch(setSchedules(response.data.data));
      dispatch(setTotalDataElements(response.data.total_elements));
      setLoading(false);
    });
  }, []);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [open2, setOpen2] = useState(false);
  const [show2, setShow2] = useState(false);
  const handleShow2 = () => setShow2(true);
  const handleClose2 = () => setShow2(false);

  const columns = [
    { field: "div_no", headerName: "Schedule Id", width: 150 },
    { field: "created_on", headerName: "Date", width: 150 },
    { field: "total_shareholders", headerName: "Shareholders", width: 150 },
    { field: "total_shares_held", headerName: "Shares", width: 150 },
    { field: "dividend_payout_ratio", headerName: "Pay Out Ratio", width: 150 },
    { field: "total_gross", headerName: "Total Gross(UGX)", width: 150 },
    { field: "total_tax", headerName: "Total Tax(UGX)", width: 150 },
    { field: "total_net", headerName: "Total Net(UGX)", width: 150 },
  ];

  const rows = dividend.schedules;
  const total_schedules = user.data_total_elements;

  const schedulesTableStyles = {
    height: "400px",
    // width: "100%",
    marginTop: "20px",
    marginBottom: "20px",
    backgroundColor: "white",
  };

  const dividendYears = [
    { label: "2020", value: "2020" },
    { label: "2021", value: "2021" },
    { label: "2022", value: "2022" },
    { label: "2028", value: "2028" },
    { label: "2029", value: "2029" },
    { label: "2030", value: "2030" },
    { label: "2031", value: "2031" },
    { label: "2032", value: "2032" },
    { label: "2033", value: "2033" },
    { label: "2034", value: "2034" },
    { label: "2035", value: "2035" },
    { label: "2036", value: "2036" },
  ];

  const [gross_payout_amount, setSelectedGrossPayAmount] = useState(null);
  const [dividend_year, setDividendYear] = useState("");
  const [dividend_type, setSelectedDividendType] = useState("INTERIM");

  const handleDividendYearChange = (event) => {
    setDividendYear(event.target.value);
  };

  const handleDividendTypeChange = (event) => {
    setSelectedDividendType(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleClose();
    handleShow2();
  };

  const dialogOneStyles = {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    color: "#000000",
    textAlign: "left",
  };
  const dialogOneRadioButtons = {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    color: "#000000",
    textAlign: "left",
  };

  const createDividendSummary = (e) => {
    e.preventDefault();
    setLoading(true);
    let data = {
      dividend_year: dividend_year,
      dividend_type: dividend_type,
      gross_payout_amount: gross_payout_amount,
    };

    createSummary(data).then((response) => {
      handleClose2();
      setLoading(false);
      if (response?.data?.operation_code === 1) {
        dispatch(setAlertTitle("ERROR!"));
        dispatch(setAlertMessage(response?.data?.message));
      } else {
        let dividend_summary = response?.data?.data;
        dispatch(setCurrentSchedule(dividend_summary));
        dispatch(setAlertTitle("Success"));
        dispatch(setAlertMessage(response?.data?.operation_description));
        navigate("/administrator-dividends-summary", {
          replace: true,
        });
      }
      dispatch(openAlert());
    });
  };
  return (
    <MiniDrawer>
      {alert.open === true ? (
        <AlertModalTest
          sx={{ margin: "20px", width: "50%", align: "right" }}
        ></AlertModalTest>
      ) : (
        <></>
      )}
      <Grid container>
        <Grid item xs={8} md={4}>
          <Typography
            sx={{
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "20px",
              color: " #0A2240",
            }}
          >
            All Schedules: {total_schedules}
          </Typography>
        </Grid>
        <Grid item xs={4} md={8}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}></Grid>
            <Grid item xs={12} md={4}></Grid>
            <Grid item xs={12} md={4} sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                sx={{
                  marginBottom: "10px",
                  width: "200px",
                  height: "40px",
                  borderRadius: "8px",
                  backgroundColor: "#052A6C",
                  color: "#FFFFFF",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#052A6C",
                    color: "#FFFFFF",
                  },
                }}
                onClick={handleShow}
                disabled={sessionStorage.getItem("can_create") === "false"}
              >
                Create Summary
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DataTable
        rows={rows}
        columns={columns}
        loading={!dividend.dividends}
        getRowId={(row) => row.div_no}
        sx={schedulesTableStyles}
      />

      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .css-hlj6pa-MuiDialogActions-root": {
            paddingRight: "24px",
            paddingLeft: "24px",
          },
          "& .css-hlj6pa-MuiDialogActions-root>:not(:first-of-type)": {
            marginLeft: "60%",
          },
        }}
      >
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <DialogTitle
              sx={{
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "24px",
                color: "#00000",
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              Stanbic Uganda Holdings Limited
            </DialogTitle>{" "}
            <Grid container spacing={2}>
              <Grid
                item
                md={5}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  component="label"
                  htmlFor="gross-pay-amount"
                  sx={dialogOneStyles}
                >
                  Gross Pay Amount
                </Typography>
              </Grid>
              <Grid
                item
                md={7}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FormControl sx={{ width: "100%" }}>
                  <TextField
                    required
                    id="gross-pay-amount"
                    label="Gross pay amount"
                    variant="outlined"
                    type="number"
                    aria-label="Gross pay amount"
                    onChange={(e) => setSelectedGrossPayAmount(e.target.value)}
                  />
                </FormControl>
              </Grid>

              <Grid
                item
                md={5}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  component="label"
                  htmlFor="gross-pay-amount"
                  sx={dialogOneStyles}
                >
                  Period
                </Typography>
              </Grid>
              <Grid
                item
                md={7}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FormControl margin="normal" fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select a Payment Period{" "}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={dividend_year}
                    onChange={handleDividendYearChange}
                    required
                  >
                    {dividendYears.map((dividendYear) => (
                      <MenuItem
                        key={dividendYear.value}
                        value={dividendYear.value}
                      >
                        {dividendYear.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid
                item
                md={2.4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  component="label"
                  htmlFor="gross-pay-amount"
                  sx={dialogOneStyles}
                >
                  Dividend Type
                </Typography>
              </Grid>
              <Grid item md={2.4}></Grid>
              <Grid
                item
                md={2.4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FormControl component="fieldset" margin="normal">
                  <RadioGroup
                    aria-label="radio-group"
                    value={dividend_type}
                    onChange={handleDividendTypeChange}
                  >
                    <FormControlLabel
                      value="INTERIM"
                      control={<Radio />}
                      label="Interim"
                      sx={dialogOneRadioButtons}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid
                item
                md={2.4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FormControl component="fieldset" margin="normal">
                  <RadioGroup
                    aria-label="radio-group2"
                    value={dividend_type}
                    onChange={handleDividendTypeChange}
                  >
                    <FormControlLabel
                      value="FINAL"
                      control={<Radio />}
                      label="Final"
                      sx={dialogOneRadioButtons}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item md={2.4}></Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
              <Grid item md={3}>
                <Button
                  variant="contained"
                  onClick={handleClose}
                  sx={{
                    backgroundColor: "#B20808",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#B20808",
                      color: "#FFFFFF",
                    },
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item md={3}></Grid>
              <Grid item md={3}></Grid>
              <Grid
                item
                md={3}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Button
                  variant="contained"
                  autoFocus
                  type="submit"
                  sx={{
                    backgroundColor: "#052A6C",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#052A6C",
                      color: "#FFFFFF",
                    },
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog
        open={show2}
        onClose={handleClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .css-hlj6pa-MuiDialogActions-root": {
            paddingRight: "24px",
            paddingLeft: "24px",
          },
          "& .css-hlj6pa-MuiDialogActions-root>:not(:first-of-type)": {
            marginLeft: "60%",
          },
        }}
      >
        <DialogContent>
          <FormControl>
            <Grid container spacing={2}>
              <Grid item md={1}>
                <Tooltip title="View dividend details">
                  <ErrorOutlineIcon fontSize="medium" color="error" />
                </Tooltip>
              </Grid>
              <Grid item md={11}>
                <Typography
                  sx={{
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "16px",
                    color: "#88898A",
                  }}
                >
                  Are you sure you would like to create a dividends payment
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={4}></Grid>
              <Grid
                item
                md={4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "16px",
                    color: "#88898A",
                  }}
                >
                  of
                </Typography>
              </Grid>
              <Grid item md={4}></Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={2}></Grid>
              <Grid
                item
                md={8}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "24px",
                    color: "#00000",
                  }}
                >
                  UGX {gross_payout_amount}?
                </Typography>
              </Grid>
              <Grid item md={2}></Grid>
            </Grid>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
            <Grid item md={3}>
              <Button
                variant="contained"
                onClick={handleClose2}
                sx={{
                  backgroundColor: "#B20808",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#B20808",
                    color: "#FFFFFF",
                  },
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item md={3}></Grid>
            <Grid item md={3}></Grid>
            <Grid
              item
              md={3}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Button
                variant="contained"
                autoFocus
                onClick={createDividendSummary}
                sx={{
                  backgroundColor: "#052A6C",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#052A6C",
                    color: "#FFFFFF",
                  },
                }}
              >
                Submit
                {loading && <CircularProgress sx={{ color: "white" }} />}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </MiniDrawer>
  );
};

export default AdministratorSchedules;
