import {
  FormControl,
  Grid,
  Icon,
  Stack,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import ShareholderWrapper from './ShareholderWrapper';
import { UpdateButton } from '../../components/styled/buttons';
import { LocalPhone, Send } from '@mui/icons-material';

const StyledTypography = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 400,
}));

function ShareholderContactSupport() {
  return (
    <ShareholderWrapper title="Shareholder Contact Support" sidebar={false}>
      <Stack
        direction="column"
        sx={{
          justifyContent: 'space-between',
          background: 'linear-gradient(to bottom,#052A6C 30%, #F5F5F5 30%)',
          margin: 0,
          padding: {
            xl: '4em 18em',
            lg: '4em 8em',
            md: '2em 1em',
            sm: '2em 1em',
            xs: '1em',
          },
        }}
      >
        {/* <Typography
          sx={{
            fontWeight: 500,
            fontSize: {
              md: 32,
              sm: 20,
            },
            color: 'white',
          }}
        >
          Reach out to us
        </Typography> */}
        <Stack direction="column" sx={{ bgcolor: 'white', borderRadius: 4 }}>
          <Stack
            direction="row"
            justifyContent="center"
            sx={{
              pt: 6,
              pb: 6,
              borderBottom: '0.5px solid #88898A',
            }}
          >
            <Typography
              sx={{
                fontWeight: {
                  md: 500,
                  sm: 400,
                  xs: 300,
                },
                fontSize: { md: 28, sm: 24, xs: 20 },
                color: '#000000',
              }}
            >
              Please reach out to the registra
              {/* Leave us a message. We would love to hear from you */}
            </Typography>
          </Stack>
          <Grid
            container
            component="form"
            sx={{
              p: {
                md: '3em 6em 6em 6em',
                sm: '3em 3em 3em 3em',
              },
            }}
          >
            <Grid item xs={5}> 
            <LocalPhone/> 
            <Typography
              sx={{
                fontWeight: {
                  md: 500,
                  sm: 400,
                  xs: 300,
                },
                fontSize: { md: 28, sm: 24, xs: 20 },
                color: '#000000',
              }}>Dial</Typography>
            <Typography
              sx={{
                fontWeight: {
                  md: 500,
                  sm: 400,
                  xs: 300,
                },
                fontSize: { md: 28, sm: 24, xs: 20 },
                color: '#000000',
              }}>+256-7577072773</Typography>
            <Typography
              sx={{
                fontWeight: {
                  md: 500,
                  sm: 400,
                  xs: 300,
                },
                fontSize: { md: 28, sm: 24, xs: 20 },
                color: '#000000',
              }}>+256-760415945</Typography>
            <Typography
              sx={{
                fontWeight: {
                  md: 500,
                  sm: 400,
                  xs: 300,
                },
                fontSize: { md: 28, sm: 24, xs: 20 },
                color: '#000000',
              }}>+256-414237504</Typography>
              {/* <FormControl
                variant="standard"
                sx={{ width: '100%', mb: 2, padding: '0 4px 0 4px' }}
              >
                <StyledTypography>First Name</StyledTypography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  label="_"
                  size="small"
                />
              </FormControl> */}
            </Grid>
            <Grid item xs={2}>
            <Typography
              sx={{
                fontWeight: {
                  md: 500,
                  sm: 400,
                  xs: 300,
                },
                fontSize: { md: 28, sm: 24, xs: 20 },
                color: '#000000',
              }}>OR</Typography>
              {/* <FormControl
                variant="standard"
                sx={{ width: '100%', mb: 2, padding: '0 4px 0 4px' }}
              >
                <StyledTypography>Last Name</StyledTypography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  label="_"
                  size="small"
                />
              </FormControl> */}
            </Grid>
            <Grid item xs={5}>
              <Send/>
            <Typography
              sx={{
                fontWeight: {
                  md: 500,
                  sm: 400,
                  xs: 300,
                },
                fontSize: { md: 28, sm: 24, xs: 20 },
                color: '#000000',
              }}>Send an email to:</Typography>
              <Typography
                sx={{
                  fontWeight: {
                    md: 500,
                    sm: 400,
                    xs: 300,
                  },
                  fontSize: { md: 28, sm: 24, xs: 20 },
                  color: '#000000',
                }}> <a href="mailto:shareholder@candrgroup.co.ug?body=First%20line%0Asecond%20line%0A%0ANew%20paragraph">shareholder@candrgroup.co.ug</a></Typography>
              {/* <FormControl
                variant="standard"
                sx={{ width: '100%', mb: 2, padding: '0 4px 0 4px' }}
              >
                <StyledTypography>Email</StyledTypography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  label="_"
                  size="small"
                />
              </FormControl> */}
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              {/* <FormControl
                variant="standard"
                sx={{ width: '100%', mb: 2, padding: '0 4px 0 4px' }}
              >
                <StyledTypography>Write your message here</StyledTypography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  label="_"
                  size="small"
                  multiline
                  rows={8}
                />
              </FormControl> */}
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              {/* <FormControl
                variant="standard"
                sx={{
                  width: {
                    md: '80%',
                    sm: '60%',
                    xs: '100%',
                  },
                  mb: 2,
                  padding: '0 4px 0 4px',
                }}
              >
                <UpdateButton
                  sx={{
                    width: 'auto',
                    height: 40,
                    fontWeight: 500,
                    fontSize: 20,
                    bgcolor: '#0033A1',
                    '&:hover': {
                      bgcolor: '#052A6C',
                      color: '#ffffff',
                    },
                  }}
                >
                  Submit
                </UpdateButton>
              </FormControl> */}
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </ShareholderWrapper>
  );
}

export default ShareholderContactSupport;
