// import { Card } from 'react-bootstrap'
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { Card, CardContent, Skeleton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { getShareholderHistory } from "../service/userService";

function ActivityTracker() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activities, setActivities] = useState();
  const user = useSelector((state) => state.user);
  const locale = useParams();
  useEffect(() => {
    getShareholderHistory({
      shareholderCode: locale.shareholder_code,
      size: 20,
      pageIndex: 0,
    }).then((response) => {
      setActivities(response.data.data);
    });
  }, []);

  const handleCheckHistory = () => {
    navigate(`/admin-shareholder-history/${locale.shareholder_code}`);
  };
  if (!activities)
    return (
      <Skeleton variant="rectangular" width="100%" height="100%"></Skeleton>
    );

  function renderTimelineItem(activity) {
    return (
      <TimelineItem key={activity.required_revision_number}>
        <TimelineSeparator className="activity_tracker_timeline_item_separator">
          <TimelineDot></TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          {activity.revision_type === "INSERT" ? (
            <>
              <Typography>Created</Typography>
              <Typography sx={{ fontSize: 8 }}>
                {activity.required_revision_instant}
              </Typography>
            </>
          ) : (
            <>
              <Typography>{activity.revision_type}</Typography>
              <Typography sx={{ fontSize: 8 }}>
                {activity.required_revision_instant}
              </Typography>
            </>
          )}
        </TimelineContent>
      </TimelineItem>
    );
  }
  return (
    <Card style={{ cursor: "pointer" }}>
      <CardContent onClick={handleCheckHistory}>
        <Typography sx={{ textAlign: "center" }}>
          <strong>Shareholder activity tracker</strong>
        </Typography>
        <Timeline>
          {activities?.length > 1 ? activities?.map(renderTimelineItem) : <></>}
        </Timeline>
      </CardContent>
    </Card>
  );
}
export default ActivityTracker;
