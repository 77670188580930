import { Close } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  Button,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
  styled,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import { logoutShareholder } from '../../service/userService';
import { useAuth } from '../../store/authContext';
import { StyledDiv } from '../styled/styledDivs';

const useStyles = makeStyles({
  active: {
    color: '#ffffff95',
  },
  normal: {
    color: 'white',
  },
});

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.customColor.light,
  height: '100px',
  '& .mainLogo': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    rowGap: 10,
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: '#ffffff',
  fontSize: 16,
  fontWeight: 500,
  '&:hover': {
    textDecoration: 'underline',
  },
  '&:active': {
    textDecoration: 'underline',
  },
}));

const MenuLinkItem = ({ name, to = '/', onClick = () => null }) => (
  <MenuItem>
    <NavLink
      to={to}
      onClick={onClick}
      style={{
        textDecoration: 'none',
        color: '#555C65',
      }}
    >
      {name}
    </NavLink>
  </MenuItem>
);

function Navbar() {
  const [open, setOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState('N/A');
  const { setUser } = useAuth();

  const classes = useStyles();

  const location = useLocation();
  const navigate = useNavigate();

  const logout = () => {
    logoutShareholder().then((response) => {
      localStorage.clear();
      sessionStorage.clear();
      setUser(null);
      navigate('/', { replace: true });
    });
  };

  useEffect(() => {
    const username = sessionStorage.getItem('username')
      ? sessionStorage.getItem('username')[0]
      : 'N/A';
    setCurrentUser(username);
  }, []);

  return (
    <AppBar position="sticky">
      <StyledToolbar
        sx={{
          bgcolor: '#0033A1',
        }}
      >
        <Link to="/" style={{ textDecoration: 'none' }}>
          <Typography variant="h5" component="p" className="mainLogo">
            <img
              src={logo}
              alt="stanbic logo"
              style={{ width: 50, height: 60 }}
            />
            <Typography
              variant="h2"
              sx={{
                color: '#ffffff',
                fontSize: 18,
                fontWeight: 'bold',
                marginLeft: 4,
                display: {
                  xs: 'none',
                  sm: 'block',
                },
              }}
            >
              Shareholder Management Register
            </Typography>
          </Typography>
        </Link>
        <Box>
          <Stack
            direction="row"
            sx={{
              display: {
                xs: 'none',
                sm: 'none',
                md: 'flex',
              },
              alignItems: 'center',
              columnGap: 2,
            }}
          >
            {sessionStorage.getItem('is_authenticated') !== null &&
              sessionStorage.getItem('userType') === 'ACCOUNT_OFFICER' && (
                <StyledTypography>
                  <NavLink
                    to="/shareholder"
                    style={{
                      textDecoration: 'none',
                    }}
                    className={({ isActive }) =>
                      isActive ? classes.active : classes.normal
                    }
                  >
                    Home
                  </NavLink>
                </StyledTypography>
              )}

            <StyledTypography>
              <NavLink
                to="/claim-dividends"
                style={{
                  textDecoration: 'none',
                }}
                className={({ isActive }) =>
                  isActive ? classes.active : classes.normal
                }
              >
                How to Claim Unpaid Dividends
              </NavLink>
            </StyledTypography>
            <StyledTypography>
              <NavLink
                to="/faqs"
                style={{
                  textDecoration: 'none',
                }}
                className={({ isActive }) =>
                  isActive ? classes.active : classes.normal
                }
              >
                FAQs
              </NavLink>
            </StyledTypography>
            <StyledTypography>
              <NavLink
                to="/contact-us"
                style={{
                  textDecoration: 'none',
                }}
                className={({ isActive }) =>
                  isActive ? classes.active : classes.normal
                }
              >
                Contact Support
              </NavLink>
            </StyledTypography>
            {sessionStorage.getItem('is_authenticated') === null && (
              <Button
                sx={{
                  bgcolor: '#0089FF',
                  color: '#ffffff',
                  borderRadius: 0,
                  height: '100px',
                  width: '140px',
                  '&:hover': {
                    bgcolor: '#ffffff',
                    color: '#0089FF',
                  },
                }}
                onClick={() => navigate('/login')}
              >
                Login
              </Button>
            )}
            {sessionStorage.getItem('is_authenticated') === 'true' && (
              <StyledDiv
                title="Logout"
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
                onClick={logout}
              >
                {currentUser}
              </StyledDiv>
            )}
            <Divider orientation="vertical" />
          </Stack>
          <Stack
            direction="row"
            sx={{
              display: {
                xs: 'flex',
                sm: 'flex',
                md: 'none',
              },
              alignItems: 'center',
            }}
          >
            <MenuIcon
              sx={{
                color: '#ffffff',
                fontSize: 40,
              }}
              onClick={(e) => setOpen(true)}
            />
          </Stack>
        </Box>
      </StyledToolbar>
      <Menu
        open={open}
        onClose={(e) => setOpen(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ListItemIcon
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <Close onClick={(e) => setOpen(false)} />
        </ListItemIcon>
        {sessionStorage.getItem('is_authenticated') === 'true' && (
          <>
            <MenuLinkItem name="Profile" to="/shareholder" />
            <MenuLinkItem name="Dividends" to="/dividends" />
            <MenuLinkItem name="Change Requests and Claims" to="/requests" />
            <MenuLinkItem name="Notifications" to="/notifications" />
            <MenuLinkItem name="Change Password" to="/change-password" />
          </>
        )}
        <MenuLinkItem name="Claim Unpaid Dividends" to="/claim-dividends" />
        <MenuLinkItem name="FAQs" to="/faqs" />
        <MenuLinkItem name="Contact Support" to="/contact-us" />
        {sessionStorage.getItem('is_authenticated') === 'true' && (
          <MenuLinkItem name="Logout" onClick={logout} />
        )}
      </Menu>
    </AppBar>
  );
}

export default Navbar;
