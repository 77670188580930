import { styled } from '@mui/material';

export const StyledDiv = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.customColor.light,
  color: theme.palette.primary.light,
  width: 40,
  height: 40,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 16,
  fontWeight: '400',
}));
