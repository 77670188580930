import { useNavigate } from "react-router-dom";
import { Box, Stepper, Step, StepLabel, Button, Typography,
    TextField, Grid, Container, Divider, FormGroup, FormControlLabel,
    Checkbox, alpha, styled, InputBase, FormControl, InputLabel, CircularProgress } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addFirstName, addCompanyID, addEmail, addFunctinalGroups, addLastName, addPhone, addUsername, addFName, addLName, addPrimaryEmail, addPrimaryPhone, addAUsername, addPrimaryAddress, updateAdminList } from "../store/userSlice";
import { getAllFunctionalGroups, getFunctionalGroups } from "../service/securityService";
import { closeAlert, openAlert, setAlertMessage, setAlertTitle } from "../store/alertSlice";

import {API} from "../config/API";
import useValidation from "../hooks/useValidate";
import { setFunctionalGroups } from "../store/securitySlice";
import { incrementBackendTotalElements } from "../store/settingsSlice";
import { addAdministrator, updateAdministratorSecurity } from "../service/userService";
import AlertModal from "./alertModal";
import { addTicket } from "../store/ticketSlice";

const steps = ['Personal Details', 'Confirm'];

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
      border: '1px solid #ced4da',
      fontSize: 16,
      width: '100%',
      padding: '10px 12px',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));
  const BootstrapCheckbox = styled(Checkbox)(({ theme }) => ({
      '& .MuiFormControlLabel-label': {
        fontSize: '2px',
        fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
          boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
          borderColor: theme.palette.primary.main,
        },
      },
    }));
  

const requiredResults = 20;
function RegStepper() {
    const navigate = useNavigate();
    const [newAdministrator, setNewAdministrator] = useState({ 
        company_id: "",
        first_name: "",
        last_name: null,
        primary_phone_number: null,
        secondary_phone_number: false,
        primary_email: "",
        secondary_email: "",
        primary_address: null,
        secondary_address: "",
        username: null,
        user_comment: ""});
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const alert = useSelector((state) => state.alert);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const {...security} = useSelector(state => state.security);
    const availableFunctionalGroups = security.functionalGroups.filter(group => group.functional_group_name !== "ADMIN_BASIC_GRP");
    const {handleValidationCheck, values, errors} = useValidation();

    const isStepOptional = (step) => {
        return step === 2;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
      };
    
    const handleSubmit = async() => {
        setLoading(true)
        addAdministrator(newAdministrator).then((response) => {
        if (response.data.operation_code === 1) {
            dispatch(setAlertTitle("ERROR!"));
            dispatch(setAlertMessage(response.data.operation_description));
        } else{
            dispatch(addTicket(response.data.data));
            dispatch(setAlertTitle("Success"));
            dispatch(setAlertMessage(response.data.data.ticket_number + " created"));
        }
        setLoading(false)
        dispatch(openAlert());
        navigate("/tickets");
        })
    };
    const updateAdmin = async(adminId) => {
        updateAdministratorSecurity({adminId: adminId, functionalGroups: user.userFunctionalGroups}). then((response) => {
        })
    }
    const handleNext = () => {
        let newSkipped = skipped;
        if (Object.keys(errors).length === 0) {
        if(isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            throw new Error("You can't skip this step.")            
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    return (
        <Box>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if(isStepOptional(index)) {
                        labelProps.optional = (
                            <Typography variant="caption">Optional</Typography>
                        );
                    }
                    if(isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === 0 ?(
                <>
                    <Typography>Bio data</Typography>  
                    <Divider />                   
                    <Grid container sx={{ position: 'center', padding: '5% 0' }}>
                        <Grid item xs={12} sm={6}>
                            <FormControl variant="standard" sx={{width: '100%'}}>
                                <InputLabel
                                    shrink
                                    htmlFor="bootstrap-input"
                                    sx={{color: '#000000'}}
                                >
                                First Name
                                </InputLabel>
                                <BootstrapInput
                                    id="firstNameTxt"
                                    name="firstName"
                                    onChange={(e) => {
                                      setNewAdministrator ({...newAdministrator, first_name: e.target.value, });
                                      handleValidationCheck(e);
                                    }}
                                    value={newAdministrator.first_name}
                                    sx={{width: '80%'}} />
                                    {errors.firstName && <Typography color='red' fontSize='small' sx={{marginBottom: 2}}>{errors.firstName}</Typography>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl variant="standard" sx={{width: '100%'}}>
                                <InputLabel shrink htmlFor="bootstrap-input"
                                    sx={{color: '#000000'}}>
                                Last Name
                                </InputLabel>
                                <BootstrapInput
                                    id="lastNameTxt"
                                    name="lastName"
                                    onChange={(e) => {
                                      setNewAdministrator ({...newAdministrator, last_name: e.target.value, });
                                      handleValidationCheck(e);
                                    }}
                                    value={newAdministrator.last_name}
                                    sx={{width: '80%'}}/>
                                    {errors.lastName && <Typography color='red' fontSize='small' sx={{marginBottom: 2}}>{errors.lastName}</Typography>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl variant="standard" sx={{width: '100%'}}>
                                <InputLabel shrink htmlFor="bootstrap-input"
                                    sx={{ color: '#000000' }}>
                                Email
                                </InputLabel>
                                <BootstrapInput
                                    id="emailTxt"
                                    name="email"
                                    onChange={(e) => {
                                      setNewAdministrator ({...newAdministrator, primary_email: e.target.value, });
                                      handleValidationCheck(e);
                                    }}
                                    value={newAdministrator.primary_email}
                                    sx={{width: '80%'}} />
                                    {errors.email && <Typography color='red' fontSize='small' sx={{marginBottom: 2}}>{errors.email}</Typography>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl variant="standard" sx={{width: '100%'}}>
                                <InputLabel shrink htmlFor="bootstrap-input"
                                    sx={{ color: '#000000' }}>
                                Phone Number
                                </InputLabel>
                                <BootstrapInput
                                    id="phoneNumberTxt"
                                    name="primaryPhoneNumber"
                                    onChange={(e) => {
                                      setNewAdministrator ({...newAdministrator, primary_phone_number: e.target.value, });
                                      handleValidationCheck(e);
                                    }}
                                    value={newAdministrator.primary_phone_number}                                    sx={{width: '80%'}}/>
                                    {errors.primaryPhoneNumber && <Typography color='red' fontSize='small' sx={{marginBottom: 2}}>{errors.primaryPhoneNumber}</Typography>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl variant="standard" sx={{width: '100%'}}>
                                <InputLabel shrink htmlFor="bootstrap-input"
                                    sx={{  color: '#000000' }}>
                                Username
                                </InputLabel>
                                <BootstrapInput
                                    id="usernameTxt"
                                    onChange={(e) => {
                                        setNewAdministrator ({...newAdministrator, username: e.target.value, });
                                        handleValidationCheck(e);
                                      }}
                                      value={newAdministrator.username}
                                    sx={{width: '80%'}}/>
                            </FormControl>
                            
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl variant="standard" sx={{width: '100%'}}>
                                <InputLabel shrink htmlFor="bootstrap-input"
                                    sx={{ color: '#000000' }}>
                                Company ID Number
                                </InputLabel>
                                <BootstrapInput
                                    id="companyIDTxt"
                                    name="companyId"
                                    onChange={(e) => {
                                        setNewAdministrator ({...newAdministrator, company_id: e.target.value, });
                                        handleValidationCheck(e);
                                      }}
                                      value={newAdministrator.company_id}
                                    sx={{width: '80%'}} />
                                    {errors.companyId && <Typography color='red' fontSize='small' sx={{marginBottom: 2}}>{errors.companyId}</Typography>}
                            </FormControl>                        
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl variant="standard" sx={{width: '100%'}}>
                                <InputLabel shrink htmlFor="bootstrap-input"
                                    sx={{ color: '#000000' }}>
                                Address
                                </InputLabel>
                                <BootstrapInput
                                    id="companyIDTxt"
                                    onChange={(e) => {
                                        setNewAdministrator ({...newAdministrator, primary_address: e.target.value, });
                                        handleValidationCheck(e);
                                      }}
                                      value={newAdministrator.primary_address}
                                    sx={{width: '80%'}} />
                            </FormControl>                        
                        </Grid>
                    </Grid>
                    <Divider />                   
                </>                
                    ) : (
                    
                <>
                    <Typography>Confirm details</Typography>
                    <Divider />                            
                    {alert.open === true ? (
                        <AlertModal
                        sx={{ margin: "20px", width: "50%", align: "right" }}
                        ></AlertModal>
                    ) : (
                        <></>
                    )}
                    <Grid container sx={{ padding: '5% 0' }}>
                        <Grid item xs={12} sm={6}>
                            <Typography sx={{ mt: 2, mb: 1 }}>First Name:</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography sx={{ mt: 2, mb: 1 }}>{newAdministrator.first_name}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography sx={{ mt: 2, mb: 1 }}>Last Name:</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography sx={{ mt: 2, mb: 1 }}>{newAdministrator.last_name}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography sx={{ mt: 2, mb: 1 }}>Email:</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography sx={{ mt: 2, mb: 1 }}>{newAdministrator.primary_email}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography sx={{ mt: 2, mb: 1 }}>Phone</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography sx={{ mt: 2, mb: 1 }}>{newAdministrator.primary_phone_number}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography sx={{ mt: 2, mb: 1 }}>username:</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography sx={{ mt: 2, mb: 1 }}>{newAdministrator.username}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography sx={{ mt: 2, mb: 1 }}>Company ID</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography sx={{ mt: 2, mb: 1 }}>{newAdministrator.company_id}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography sx={{ mt: 2, mb: 1 }}>Primary Address</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography sx={{ mt: 2, mb: 1 }}>{newAdministrator.primary_address}</Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                </>     
                    )}
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button  disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1, color: '#052A6C' }}>Back</Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {isStepOptional(activeStep) && (
                            <Button color="inherit" onClick={handleSkip} sx={{ mr:1 }}>Skip</Button>
                        )}
                        {activeStep === steps.length -1 ? 
                        (
                            <Button
                                variant="contained" 
                                onClick={handleSubmit} 
                                sx={{backgroundColor: '#052A6C',}}
                            >Submit
                            {loading &&  <CircularProgress size={25} />}
                            </Button>
                        ):(
                            <Button variant="contained" onClick={handleNext} sx={{backgroundColor: '#052A6C',}}>Save and Continue</Button>
                        )}
                    </Box>
        </Box>
    );
}
export default RegStepper;