import {
  alpha,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputBase,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  styled,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openAlert, setAlertMessage, setAlertTitle } from "../store/alertSlice";
import { addTicket } from "../store/ticketSlice";
import { resetDynamicUser } from "../store/userSlice";

import { Report } from "@mui/icons-material";
// import API from "../config/API";
import useValidation from "../hooks/useValidate";
import { checkAuthorities } from "../service/securityService";
import { getAllBrokers, getAllCountries, getAllPayModes } from "../service/settingsService";
import { createShareholder } from "../service/userService";
import { setBrokers } from "../store/settingsSlice";
import { ShareholderInput, ShareholderSelect } from "./forms/FormComponents";

const steps = ["Personal Details", "Account Details", "Shares"];
const genders = ["Male", "Female", "Other"];
const walletProviders = ["Flexipay", "MTN Mobile Money", "Airtel Money"];

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "auto",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
const BootstrapSelect = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "auto",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
const BootstrapCheckbox = styled(Checkbox)(({ theme }) => ({
  "& .MuiFormControlLabel-label": {
    fontSize: "2px",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

let taxRates = [
  {
    tax_rate_code: "TAX_RATE_6503",
    description: "FOREIGNER-SHAREHOLDERS",
    tax_rate: 13,
  },
];

let backPageIndex = 0;
let requestedSize = 240;
function ShareholderRegStepper() {
  const navigate = useNavigate();

  const [newShareholder, setNewShareholder] = useState({
    broker_code: "",
    created_by: "",
    identification_number: null,
    industry: null,
    is_company: false,
    payment_account_name: "",
    payment_account_number: "",
    payment_branch_address: null,
    payment_branch_name: "",
    payment_mode_code: null,
    primary_address: "",
    primary_email: "",
    primary_phone_number: "",
    secondary_address: "",
    secondary_email: "",
    secondary_phone_number: "",
    certificate_number: "",
    shareholder_name: "",
    shareholder_other_name: null,
    shareholder_scd_number: "",
    shares_held: 0,
    tax_rate_code: null,
    tin: 0,
  });
  const [loading, setLoading] = useState(true);
  const [ctryLoading, setCtryLoading] = useState(true);
  const [payModeLoading, setPayModeLoading] = useState(true);
  const [brokersLoading, setBrokersLoading] = useState(true);
  const [paymentMode, setPaymentMode] = useState();
  const [filteredPayModes, setFilteredPayModes] = useState();
  const [activeStep, setActiveStep] = useState(0);
  const [getTaxRate, setGetTaxRate] = useState(false);
  const [comExists, setComExists] = useState(false);
  const [scdExists, setScdExists] = useState(false);
  const [comScdErrorDialog, setComScdErrorDialog] = useState(false);
  const [country, setCountry] = useState("Uganda");
  const [selectedPayModes, setSelectedPayModes] = useState();
  const [skipped, setSkipped] = useState(new Set());
  const dispatch = useDispatch();
  const brokers = useSelector((state) => state.settings.brokers)
  const user = useSelector((state) => state.user);
  const shSettings = useSelector((state) => state.settings);
  const { ...security } = useSelector((state) => state.security);
  const { handleValidationCheck, values, errors } = useValidation();
  const sessionCountries = sessionStorage.getItem("countries");
  const sessionPayModes = sessionStorage.getItem("payModes");
  const countries = JSON.parse(sessionCountries);
  const payModes = JSON.parse(sessionPayModes);

  useEffect(() => {
    checkAuthorities({
      can_create: "SHAREHOLDER_MODULE:CAN_CREATE",
      can_read: "SHAREHOLDER_MODULE:CAN_READ",
      can_update: "SHAREHOLDER_MODULE:CAN_UPDATE",
      can_delete: "SHAREHOLDER_MODULE:CAN_DELETE",
      can_export: "SHAREHOLDER_MODULE:CAN_EXPORT",
      can_approve: "SHAREHOLDER_MODULE:CAN_APPROVE",
    });
    if (sessionCountries === null) {
      getAllCountries({ size: requestedSize, pageIndex: backPageIndex }).then(
        (response) => {
          sessionCountries.setItem("countries", response.data);
          setCtryLoading(false);
        }
      );
    }
    if (sessionPayModes === null) {
      getAllPayModes({ size: requestedSize, pageIndex: backPageIndex }).then(
        (response) => {
          sessionCountries.setItem("payModes", response.data);
          setPayModeLoading(false);
        }
      );
    }
  }, []);
  useEffect(() => {
    getAllBrokers({size: 5, pageIndex: 0}).then(response => {
      if (response?.data?.operation_code === 0) {
        dispatch(setBrokers(response?.data?.data))        
      }
      setBrokersLoading(false);
    })
  }, []);
  useEffect(() => {
    if (((ctryLoading !== true && payModeLoading !== true) || (sessionCountries !== null && sessionPayModes !== null)) && brokersLoading !== true) {
      setLoading(false);
    }
  }, [ctryLoading, payModeLoading, brokersLoading]);

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const selectPaymentMode = (paymentMode) => {
    let preFilteredPayModes = payModes?.filter(
      (myPayMode) => myPayMode.payment_type === paymentMode
    );
    setFilteredPayModes(preFilteredPayModes);
    setPaymentMode(paymentMode);
  };
  const handlePayModeNameSelect = (event) => {
  };
  const handleSubmit = () => {
    setLoading(true);
    createShareholder(newShareholder).then((response) => {
      if (response?.data?.operation_code === 1) {
        dispatch(setAlertTitle("ERROR!"));
        dispatch(setAlertMessage(response.data[0].message));
      } else {
        dispatch(resetDynamicUser());
        dispatch(addTicket(response.data.data));
        dispatch(setAlertTitle("Success"));
        dispatch( setAlertMessage(response.data.data.ticket_number + " created"));
      }
      setLoading(false);
      dispatch(openAlert());
      navigate("/tickets");
    });
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (comExists === false && scdExists === false) {
      setComScdErrorDialog(true);
    } else if (Object.keys(errors).length === 0) {
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip this step.");
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleCountrySelect = (country) => {
    let countryObj = {};
    setCountry(country);
    countryObj = countries?.filter(
      (countryToReturn) => countryToReturn.country_name === country
    );
    if (countryObj[0].tax_rates.length >= 1) {
      setGetTaxRate(true);
      taxRates.splice(0, 1, ...countryObj[0].tax_rates);
    } else {
    }
  };
  const handleCloseDialog = () => {
    setComScdErrorDialog(false);
  };
  const handleSetCom = () => {
    setComExists(true);
  };
  const handleSetScd = () => {
    setScdExists(true);
  };

  return (
    <Box>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === 0 ? (
        <>
          <Typography>Bio data</Typography>
          <Divider />
          <Grid container sx={{ position: "center", padding: "5% 0" }}>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  sx={{
                    color: "#000000",
                  }}
                >
                  Shareholder Name <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <ShareholderInput
                  required
                  name="shareholderName"
                  id="firstNameTxt"
                  onChange={(e) => {
                    setNewShareholder({
                      ...newShareholder,
                      shareholder_name: e.target.value,
                    });
                    handleValidationCheck(e);
                  }}
                  value={newShareholder.shareholder_name}
                  sx={{ width: "100%" }}
                />
                {errors.shareholderName && (
                  <Typography
                    color="red"
                    fontSize="small"
                    sx={{ marginBottom: 2 }}
                  >
                    {errors.shareholderName}
                  </Typography>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel
                  id="demo-row-radio-buttons-group-label"
                  sx={{
                    color: "#000000",
                  }}
                >
                  What type of shareholder is this?{" "}
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(e) =>
                    setNewShareholder({
                      ...newShareholder,
                      is_company: e.target.value,
                    })
                  }
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Company"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Individual"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  sx={{
                    color: "#000000",
                  }}
                >
                  Email <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <ShareholderInput
                  required
                  name="email"
                  id="emailTxt"
                  onChange={(e) => {
                    setNewShareholder({
                      ...newShareholder,
                      primary_email: e.target.value,
                    });
                    handleValidationCheck(e);
                  }}
                  value={newShareholder.primary_email}
                  sx={{ width: "80%" }}
                />
                {errors.email && (
                  <Typography
                    color="red"
                    fontSize="small"
                    sx={{ marginBottom: 2 }}
                  >
                    {errors.email}
                  </Typography>
                )}
              </FormControl>
              <Typography>{errors.email}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  sx={{
                    color: "#000000",
                  }}
                >
                  Secondary Email
                </InputLabel>
                <ShareholderInput
                  id="emailTxt"
                  name="secondaryEmail"
                  onChange={(e) => {
                    setNewShareholder({
                      ...newShareholder,
                      secondary_email: e.target.value,
                    });
                    handleValidationCheck(e);
                  }}
                  value={newShareholder.secondary_email}
                  sx={{ width: "80%" }}
                />
                {errors.secondaryEmail && (
                  <Typography
                    color="red"
                    fontSize="small"
                    sx={{ marginBottom: 2 }}
                  >
                    {errors.secondaryEmail}
                  </Typography>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  sx={{
                    color: "#000000",
                  }}
                >
                  Phone Number <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <ShareholderInput
                  required
                  name="primaryPhoneNumber"
                  id="phoneNumberTxt"
                  onChange={(e) => {
                    setNewShareholder({
                      ...newShareholder,
                      primary_phone_number: e.target.value,
                    });
                    handleValidationCheck(e);
                  }}
                  value={newShareholder.primary_phone_number}
                  sx={{ width: "80%" }}
                />
                {errors.primaryPhoneNumber && (
                  <Typography
                    color="red"
                    fontSize="small"
                    sx={{ marginBottom: 2 }}
                  >
                    {errors.primaryPhoneNumber}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  sx={{
                    color: "#000000",
                  }}
                >
                  Secondary Phone Number
                </InputLabel>
                <ShareholderInput
                  name="secondaryPhoneNumber"
                  id="phoneNumberTxt"
                  onChange={(e) => {
                    setNewShareholder({
                      ...newShareholder,
                      secondary_phone_number: e.target.value,
                    });
                    handleValidationCheck(e);
                  }}
                  value={newShareholder.secondary_phone_number}
                  sx={{ width: "80%" }}
                />
                {errors.secondaryPhoneNumber && (
                  <Typography
                    color="red"
                    fontSize="small"
                    sx={{ marginBottom: 2 }}
                  >
                    {errors.secondaryPhoneNumber}
                  </Typography>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  sx={{
                    color: "#000000",
                  }}
                >
                  Address <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <ShareholderInput
                  required
                  id="phoneNumberTxt"
                  name="primaryAddress"
                  onChange={(e) => {
                    setNewShareholder({
                      ...newShareholder,
                      primary_address: e.target.value,
                    });
                    handleValidationCheck(e);
                  }}
                  value={newShareholder.primary_address}
                  sx={{ width: "80%" }}
                />
                {errors.primaryAddress && (
                  <Typography
                    color="red"
                    fontSize="small"
                    sx={{ marginBottom: 2 }}
                  >
                    {errors.primaryAddress}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  sx={{
                    color: "#000000",
                  }}
                >
                  Secondary Address
                </InputLabel>
                <ShareholderInput
                  id="secondaryAddressTxt"
                  onChange={(e) => {
                    setNewShareholder({
                      ...newShareholder,
                      secondary_address: e.target.value,
                    });
                  }}
                  value={newShareholder.secondary_address}
                  sx={{ width: "80%" }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl sx={{ width: "100%", marginTop: 2 }}>
                <InputLabel
                  shrink
                  htmlFor="country-select"
                  sx={{ marginLeft: -1, color: "#000000" }}
                >
                  Country of Residence <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <ShareholderSelect
                  required
                  name="countryTaxRate"
                  id="country-select"
                  onChange={(event) => {
                    handleCountrySelect(event.target.value);
                  }}
                >
                  {countries?.map((country) => (
                    <MenuItem
                      key={country.iso_code}
                      value={country.country_name}
                    >
                      {country.country_name}
                    </MenuItem>
                  ))}
                </ShareholderSelect>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              {getTaxRate && (
                <FormControl sx={{ width: "100%", marginTop: 2 }}>
                  <InputLabel
                    shrink
                    htmlFor="tax-rate-select"
                    sx={{ marginLeft: -1, color: "#000000" }}
                  >
                    Tax Rate <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <ShareholderSelect
                    required
                    name="taxRate"
                    id="tax-rate-select"
                    onChange={(e) => {
                      setNewShareholder({
                        ...newShareholder,
                        tax_rate_code: e.target.value,
                      });
                    }}
                  >
                    {taxRates.length > 1 ? (
                      taxRates.map((rate) => (
                        <MenuItem
                          key={rate.tax_rate_code}
                          value={rate.tax_rate_code}
                        >
                          {rate.description}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value={taxRates[0].tax_rate_code}>
                        {taxRates[0].description}
                      </MenuItem>
                    )}
                  </ShareholderSelect>
                </FormControl>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  sx={{
                    color: "#000000",
                  }}
                >
                  Shareholder Certificate Number <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <ShareholderInput
                  required
                  id="usernameTxt"
                  name="shareholderCode"
                  onChange={(e) => {
                    setNewShareholder({
                      ...newShareholder,
                      certificate_number: e.target.value,
                    });
                    handleValidationCheck(e);
                    handleSetCom();
                  }}
                  value={newShareholder.certificate_number}
                  sx={{ width: "80%" }}
                />
                {errors.shareholderCode && (
                  <Typography
                    color="red"
                    fontSize="small"
                    sx={{ marginBottom: 2 }}
                  >
                    {errors.shareholderCode}
                  </Typography>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  sx={{
                    color: "#000000",
                  }}
                >
                  Shareholder SCD Number <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <ShareholderInput
                  required
                  id="companyIDTxt"
                  name="shareholderSCDNumber"
                  onChange={(e) => {
                    setNewShareholder({
                      ...newShareholder,
                      shareholder_scd_number: e.target.value,
                    });
                    handleValidationCheck(e);
                    handleSetScd();
                  }}
                  value={newShareholder.shareholder_scd_number}
                  sx={{ width: "80%" }}
                />
                {errors.shareholderSCDNumber && (
                  <Typography
                    color="red"
                    fontSize="small"
                    sx={{ marginBottom: 2 }}
                  >
                    {errors.shareholderSCDNumber}
                  </Typography>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  sx={{
                    color: "#000000",
                  }}
                >
                  Tax Identification Number(TIN){" "}
                  <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <ShareholderInput
                  required
                  id="usernameTxt"
                  name="shareholderTin"
                  type="number"
                  onChange={(e) => {
                    setNewShareholder({
                      ...newShareholder,
                      tin: e.target.value,
                    });
                    handleValidationCheck(e);
                  }}
                  value={newShareholder.tin}
                  sx={{ width: "80%" }}
                />
                {errors.shareholderTin && (
                  <Typography
                    color="red"
                    fontSize="small"
                    sx={{ marginBottom: 2 }}
                  >
                    {errors.shareholderTin}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              
            <FormControl sx={{ width: "100%", marginTop: 2 }}>
                <InputLabel
                  shrink
                  htmlFor="broker-select"
                  sx={{ marginLeft: -1, color: "#000000" }}
                >
                  Broker <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <ShareholderSelect
                  required
                  name="broker"
                  id="broker-select"
                  onChange={(e) => {
                    setNewShareholder({
                      ...newShareholder,
                      broker_code: e.target.value,
                    });
                  }}
                >
                  {brokers?.map((broker) => (
                    <MenuItem
                      key={broker.broker_code}
                      value={broker.broker_code}
                    >
                      {broker.broker_name}
                    </MenuItem>
                  ))}
                </ShareholderSelect>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  sx={{
                    color: "#000000",
                  }}
                >
                  Comment (Please comment on why this shareholder is being
                  created individually)
                </InputLabel>
                <ShareholderInput
                  required
                  multiline={true}
                  id="commmentTxt"
                  onChange={(e) => {
                    setNewShareholder({
                      ...newShareholder,
                      user_comment: e.target.value,
                    });
                  }}
                  value={newShareholder.user_comment}
                  sx={{ width: "102%" }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Divider />
        </>
      ) : (
        <>
          {activeStep === 1 ? (
            <>
              <Typography>Payment Details</Typography>
              <Divider />
              <Grid container>
                <Grid item xs={12} md={12}>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Please select the prefered payment channel{" "}
                      <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={(event) => {
                        selectPaymentMode(event.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="BANK"
                        control={<Radio />}
                        label="Bank"
                      />
                      <FormControlLabel
                        value="WALLET"
                        control={<Radio />}
                        label="Wallet"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              {filteredPayModes && (
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <FormControl sx={{ width: "100%", marginTop: 2 }}>
                      <InputLabel
                        shrink
                        htmlFor="pay-mode-name-select"
                        sx={{ marginLeft: -1, color: "#000000" }}
                      >
                        Bank/Wallet name:{" "}
                        <span style={{ color: "red" }}>*</span>
                      </InputLabel>
                      <ShareholderSelect
                        required
                        name="payModeName"
                        id="pay-mode-name-select"
                        onChange={(e) => {
                          handlePayModeNameSelect(e);
                          setNewShareholder({
                            ...newShareholder,
                            payment_mode_code: e.target.value,
                          });
                        }}
                      >
                        {filteredPayModes?.map((paymode) => (
                          <MenuItem
                            key={paymode.payment_mode_code}
                            value={paymode.payment_mode_code}
                          >
                            {paymode.payment_mode_name}
                          </MenuItem>
                        ))}
                      </ShareholderSelect>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl variant="standard" sx={{ width: "100%" }}>
                      <InputLabel
                        shrink
                        htmlFor="branch-name-txt"
                        sx={{
                          color: "#000000",
                        }}
                      >
                        Branch Name:
                      </InputLabel>
                      <ShareholderInput
                        required
                        id="branch-name-txt"
                        name="branchName"
                        onChange={(e) => {
                          setNewShareholder({
                            ...newShareholder,
                            payment_branch_name: e.target.value,
                          });
                        }}
                        value={newShareholder.payment_branch_name}
                        sx={{ width: "80%" }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              )}
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="standard" sx={{ width: "100%" }}>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      sx={{
                        color: "#000000",
                      }}
                    >
                      Account Name:
                    </InputLabel>
                    <ShareholderInput
                      required
                      id="account-name-txt"
                      name="accountNameTxt"
                      onChange={(e) => {
                        setNewShareholder({
                          ...newShareholder,
                          payment_account_name: e.target.value,
                        });
                      }}
                      value={newShareholder.payment_account_name}
                      sx={{ width: "80%" }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="standard" sx={{ width: "100%" }}>
                    <InputLabel
                      shrink
                      htmlFor="account-number-txt"
                      sx={{
                        color: "#000000",
                      }}
                    >
                      Account Number:
                    </InputLabel>
                    <ShareholderInput
                      required
                      id="account-number-txt"
                      name="accountNumberTxt"
                      onChange={(e) => {
                        setNewShareholder({
                          ...newShareholder,
                          payment_account_number: e.target.value,
                        });
                      }}
                      value={newShareholder.payment_account_number}
                      sx={{ width: "80%" }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Divider />{" "}
            </>
          ) : (
            <>
              <Typography>Shares</Typography>
              <Divider />
              <Grid container>
                <Grid item xs={12} sm={6} md={6}>
                  <FormControl variant="standard">
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      sx={{
                        color: "#000000",
                      }}
                    >
                      Number of Shares
                    </InputLabel>
                    <ShareholderInput
                      id="phoneNumberTxt"
                      onChange={(e) => {
                        setNewShareholder({
                          ...newShareholder,
                          shares_held: e.target.value,
                        });
                      }}
                      value={newShareholder.shares_held}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Divider />
            </>
          )}
        </>
      )}
      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1, color: "#052A6C" }}
        >
          Back
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        {isStepOptional(activeStep) && (
          <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
            Skip
          </Button>
        )}
        {activeStep === steps.length - 1 ? (
          <Button
            disabled={sessionStorage.getItem("can_create") === "false" ||  Object.keys(errors).length > 0}
            variant="contained"
            onClick={handleSubmit}
            sx={{ backgroundColor: "#052A6C" }}
          >
            {loading ? (
              <CircularProgress></CircularProgress>
            ):(
              "Submit"
            )}            
          </Button>
        ) : (
          <Button
            disabled={sessionStorage.getItem("can_create") === "false" ||  Object.keys(errors).length > 0}
            variant="contained"
            onClick={handleNext}
            sx={{ backgroundColor: "#052A6C" }}
          >
            Save and Continue
          </Button>
        )}
      </Box>
      <Dialog
        open={comScdErrorDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Report color="error" sx={{ marginRight: 2 }} />
            Every shareholder should have a COM or SCD number, please register
            either before moving to the next step.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#052A6C" }}
            onClick={handleCloseDialog}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
export default ShareholderRegStepper;
