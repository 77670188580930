
import { useContext } from 'react'
import ListShareholders from '../../components/tables/ListShareholderTable';
import NavigationContext from '../../store/NavigationContext'
import MiniDrawer from '../../components/newSideDrawer/miniSideDrawer';


function ListShareholdersPage() {
  const navigationContext = useContext(NavigationContext)
  const { sidebar } = navigationContext
  return (
    <MiniDrawer >
      <ListShareholders />
    </MiniDrawer>
  )
}

export default ListShareholdersPage;