import { Download, Upload } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  Input,
  Typography
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import DividendsActions from "../../components/DividendsActions/DividendsActions";
import DividendsStatus from "../../components/DividendsActions/DividendsStatus";
import AlertModal from "../../components/alertModal";
import DataTable from "../../components/tables/DataTable";
import { API } from "../../config/API";
import { checkAuthorities } from "../../service/securityService";
import { getDividends } from "../../service/userService";
import NavigationContext from "../../store/NavigationContext";
import {
  setDividends,
  setExcelData,
  setExcelFile,
  setExcelFileError,
  setFileName,
  setFileType,
} from "../../store/dividendsSlice";
import { setTotalDataElements } from "../../store/userSlice";
import MiniDrawer from "../../components/newSideDrawer/miniSideDrawer";

const AdministratorDividends = () => {
  const navigationContext = useContext(NavigationContext);
  const { sidebar } = navigationContext;
  const dividend = useSelector((state) => state.dividend);
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alert);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const requiredResults = 240;

  useEffect(() => {
    checkAuthorities({
      can_create: "DIVIDENDS_MODULE:CAN_CREATE",
      can_read: "DIVIDENDS_MODULE:CAN_READ",
      can_update: "DIVIDENDS_MODULE:CAN_UPDATE",
      can_delete: "DIVIDENDS_MODULE:CAN_DELETE",
      can_export: "DIVIDENDS_MODULE:CAN_EXPORT",
      can_approve: "DIVIDENDS_MODULE:CAN_APPROVE",
    });

    getDividends({size: requiredResults, pageIndex: 0}).then((response) => {
      dispatch(setDividends(response.data.data));
      dispatch(setTotalDataElements(response.data.total_elements));
    });
  });

  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const columns = [
    { field: "dividend_year", headerName: "Financial Year", width: 150 },
    { field: "payment_ref_number", headerName: "Transaction ID", width: 150 },
    { field: "shareholder_name", headerName: "Shareholder", width: 150 },
    { field: "payment_mode", headerName: "Paid Via", width: 150 },
    {
      field: "payment_account_number",
      headerName: "Account Paid to",
      width: 150,
    },
    { field: "actual_net_amount", headerName: "Amount Paid", width: 150 },
    {
      field: "workflow_status",
      headerName: "Transaction Status",
      width: 150,
      renderCell: (params) => <DividendsStatus {...{ params }} />,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 150,
      renderCell: (params) => <DividendsActions {...{ params }} />,
    },
  ];

  const rows = dividend.dividends;
  const total_dividends = user.data_total_elements;

  

  const dividendsTableStyles = {
    height: "400px",
    // width: "100%",
    marginTop: "20px",
    marginBottom: "20px",
    backgroundColor: "white",
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

  const handleFile = (e) => {
    const [selectedFile] = e.target.files;
    if (selectedFile) {
      if (selectedFile && fileType.includes(selectedFile.type)) {
        const filePath = e.target.value;
        const pathParts = filePath.split("\\fakepath\\").slice(1);
        const fileName = pathParts.join("\\");
        const reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          dispatch(setExcelFile(e.target.result));
          dispatch(setFileName(fileName));
          dispatch(setFileType(selectedFile.type));
        };
        reader.onerror = (e) => {
          dispatch(setExcelFileError(null));
          dispatch(setExcelFileError(e.target.error.message));
          dispatch(setExcelFile(null));
        };
      } else {
        dispatch(setExcelFileError("Please select only excel file types"));
        dispatch(setExcelFile(null));
      }
    } else {
    }
  };

  // view bulk dividends function
  const viewBulkDividends = (e) => {
    e.preventDefault();
    navigate("/administrator-dividend-bulkupload-view", {
      replace: true,
    });
    if (dividend.excelFile !== null) {
      const workbook = XLSX.read(dividend.excelFile, { type: "buffer" });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const data = XLSX.utils.sheet_to_json(worksheet);
      dispatch(setExcelData(data));
    } else {
      dispatch(setExcelData(null));
    }
  };

  // download template
  const downloadTemplate = async () => {
    try {
      const response = await API.get(`/dividends/download-template`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        responseType: "blob",
      });
      if (response.status === 200) {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: response.data.type })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Dividends.xlsx");
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    } catch (error) {
    }
  };
  return (
    <MiniDrawer>
      {alert.open === true ? (
        <AlertModal
          sx={{ margin: "20px", width: "50%", align: "right" }}
        ></AlertModal>
        ) : (
          <></>
      )}
      <Grid container>
             <Grid item xs={8} md={4}>
             <Typography
            sx={{
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "20px",
              color: " #0A2240",
            }}
          >
            All Dividends: {total_dividends}
          </Typography>
            </Grid>
            <Grid item xs={4} md={8}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={2.5}>
                </Grid>
                <Grid item xs={12} md={3.5}>
                  <Button
                    variant="contained"
                    endIcon={<Download />}
                    sx={{
                      backgroundColor: "#FFFFFF",
                      color: "#052A6C",
                      margin: "2% 1% 2% 0",
                      width: "100%",
                      fontSize: 14,
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "#FFFFFF",
                      },
                    }}
                    onClick={downloadTemplate}
                    disabled={sessionStorage.getItem("can_export") === "false"}
                  >
                    Download Template
                  </Button>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Button
                    variant="contained"
                    endIcon={<Upload />}
                    sx={{
                      backgroundColor: "#FFFFFF",
                      color: "#052A6C",
                      margin: "2% 1% 2% 0",
                      width: "100%",
                      fontSize: 14,
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "#FFFFFF",
                      },
                    }}
                    onClick={handleShow}
                    disabled={sessionStorage.getItem("can_create") === "false"}
                  >
                    Bulk Upload
                  </Button>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#052A6C",
                      margin: "2% 1% 2% 0",
                      width: "100%",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "#052A6C",
                      },
                      fontSize: 14,
                    }}
                    onClick={() =>
                      navigate("/create-dividend", {
                        replace: true,
                      })
                    }
                    disabled={sessionStorage.getItem("can_create") === "false"}
                  >
                    Add Dividend
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <DataTable
            rows={rows}
            columns={columns}
            loading={!dividend.dividends}
            getRowId={(row) => row.payment_ref_number}
            sx={dividendsTableStyles}
          />

          <Dialog
            open={show}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
              "& .css-hlj6pa-MuiDialogActions-root": {
                paddingRight: "24px",
                paddingLeft: "24px",
              },
              "& .css-hlj6pa-MuiDialogActions-root>:not(:first-of-type)": {
                marginLeft: "60%",
              },
            }}
          >
            <DialogContent>
              <FormControl>
                <Input
                  type="file"
                  onChange={handleFile}
                  required
                  inputProps={{ accept: fileType }}
                />
                {dividend.excelFileError && (
                  <Box style={{ color: "red" }}>{dividend.excelFileError}</Box>
                )}
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={handleClose}
                sx={{
                  backgroundColor: "#B20808",
                  "&:hover": {
                    backgroundColor: "#B20808",
                    color: "#FFFFFF",
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={viewBulkDividends}
                autoFocus
                sx={{ backgroundColor: "#052A6C" }}
                disabled={
                  dividend.fileName == null || dividend.fileType !== fileType
                }
              >
                Upload
              </Button>
            </DialogActions>
          </Dialog>
    </MiniDrawer>
  )
};

export default AdministratorDividends;
