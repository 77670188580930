import axios from 'axios';

export const testApi = axios.create({});

//Investigating how necessary these two are
export const API = axios.create({});
export const OfficerApi = axios.create({});

//Endpoints do not include the "api/v1"
export const dashboardApi = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL_ENV}`,
  headers: {
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
  },
});

//unprotected version of the testApi
export const unprotectedApi = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL_ENV}api/v1`,
  headers: { 'Access-Control-Allow-Origin': '*'},
});

// unprotected version of the dashboardApi
export const InfoApi = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL_ENV}`,
  headers: {'Access-Control-Allow-Origin': '*',},
});



testApi.interceptors.response.use(
  (response, config) => {
    if (response.status === 200 || response.status === 201 || response.status === 202 ) {
      return {data: response.data, headers: response.headers};
    } else if (response.status === 204) {
      return {data:{
        data: [],
        message: 'No Record(s) found.',
        operation_code:0,
        operation_description: "Resource read successfully",
        page_index: 0,
        records_displayed: 0,
        total_elements: 0,
        total_pages: 0,}
      };
    }
  },
  (error) => {
    if (error.code === "ERR_NETWORK") {
      return {data:{
        data: [],
        message: 'Network Error',
        operation_code: 1,
        operation_description: "Network Error. Please check your network connection",
        page_index: 0,
        records_displayed: 0,
        total_elements: 0,
        total_pages: 0,},
      };      
    }else {
      return {data:{
        data: [],
        message: error?.response?.data?.message,
        operation_code: error.response.data.operation_code,
        operation_description: error.response.data.operation_description,
        page_index: 0,
        records_displayed: 0,
        timestamp: error.response.data.timestamp,
        total_elements: 0,
        total_pages: 0,},
        headers: error.response.headers
      };
    }
    
    // return error.response.data;
  }
);

testApi.interceptors.request.use(
  async (config) => {
    (config.timeout = 560000),
      (config.baseURL = `${process.env.REACT_APP_BASE_URL_ENV}api/v1`),
      (config.headers = {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      });
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

OfficerApi.interceptors.request.use(
  async (config) => {
    (config.timeout = 560000),
      (config.baseURL = `${process.env.REACT_APP_BASE_URL_ENV}api/v1`),
      (config.headers = {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      });
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);


API.interceptors.request.use(
  async (config) => {
    (config.timeout = 560000),
      (config.baseURL = `${process.env.REACT_APP_BASE_URL_ENV}api/v1`),
      (config.headers = {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      });
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

API.interceptors.response.use((response) => {
  return response;
});

dashboardApi.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return response.data;
    }
  },
  (error) => {
    return error;
  }
);

unprotectedApi.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 202) {
      return { data: response.data, headers: response.headers };
    }
  },
  (error) => {
    if ('response' in error && 'message' in error.response.data) {
      return error.response.data;
    } else {
        return {
          error: 'Network Error',
          message:
            'Unfortunately we are having trouble establishing a connection. Please try again later',
        };
    }
  }
);

InfoApi.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return response.data;
    }
  },
  (error) => {
    if (!error.response.data === undefined) {
      return error.response.data;
    }
    return {
      error: 'Network Error',
      message:
        'Unfortunately we are having trouble establishing a connection. Please try again later',
    };
  }
);
