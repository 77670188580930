import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Icon from "@mdi/react";
import {
  mdiLogout,
  mdiAccountCircle,
  mdiCreditCard,
  mdiBell,
  mdiKey,
  mdiUpdate,
} from "@mdi/js";
import { Container } from "react-bootstrap";
import "./ShareholderSidebar.css";
import { Link } from "react-router-dom";

const ShareholderSidebar = () => {
  return (
    <Container id="sidebar_container">
      <Row className="sidebar_links_row">
        <Col sm={12} md={12} lg={12}>
          <ul className="list-unstyled side_bar_ul">
            <li className="navbar-item sidebar_links ">
              <Icon
                path={mdiAccountCircle}
                title="User Profile"
                size="20px"
                color="#5f6868"
              />
              <Link to="/shareholder" className="sidebar_link_txt">
                Profile
              </Link>
            </li>
            <li className="navbar-item sidebar_links">
              <Icon
                path={mdiUpdate}
                title="User Profile"
                size="20px"
                color="#5f6868"
              />
              <Link to="/requests" className="sidebar_link_txt">
                Change Requests
              </Link>
            </li>
            <li className="navbar-item sidebar_links">
              <Icon
                path={mdiCreditCard}
                title="User Profile"
                size="20px"
                color="#5f6868"
              />
              <Link to="/payouts" className="sidebar_link_txt">
                Payouts
              </Link>
            </li>
            <li className="navbar-item sidebar_links">
              <Icon
                path={mdiBell}
                title="User Profile"
                size="20px"
                color="#5f6868"
              />
              <Link to="/notifications" className="sidebar_link_txt">
                Notifications
              </Link>
            </li>
            <li className="navbar-item sidebar_links">
              <Icon
                path={mdiKey}
                title="User Profile"
                size="20px"
                color="#5f6868"
              />
              <Link to="/change-password" className="sidebar_link_txt">
                Change Password
              </Link>
            </li>
            <li className="navbar-item sidebar_links">
              <Icon
                path={mdiLogout}
                title="User Profile"
                size="20px"
                color="#5f6868"
              />
              <Link to="/" className="sidebar_link_txt">
                Logout
              </Link>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default ShareholderSidebar;
