import {
  Check,
  Clear,
  Delete,
  Edit,
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
  RemoveRedEye,
  Report
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  alpha,
  styled,
  useTheme
} from "@mui/material";
import { green, orange, red } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkAuthorities } from "../../service/securityService";
import {
  deleteAccountOfficer,
  getAllAccountOfficers,
} from "../../service/userService";
import {
  setAccountOfficerBackendPagination,
  setAccountOfficers,
} from "../../store/accountOfficerSlice";
import {
  openAlert,
  setAlertMessage,
  setAlertTitle,
} from "../../store/alertSlice";
import {
  removeAccountOfficer,
  setDynamicUser,
  setPage,
} from "../../store/userSlice";
import AlertModal from "../alertModal";
const TablePaginationActions = (props) => {
  const dispatch = useDispatch();
  const backendPageIndex = useSelector(
    (state) => state.settings.backendPageIndex
  );
  const backendReturnedElements = useSelector(
    (state) => state.settings.backendReturnedElements
  );
  const backendTotalPages = useSelector(
    (state) => state.settings.backendTotalPages
  );
  const backendTotalElements = useSelector(
    (state) => state.settings.backendTotalElements
  );
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    if (page >= Math.ceil(count / rowsPerPage) - 1) {
      getAllAccountOfficers({ size: 20, pageIndex: backendPageIndex + 1 }).then(
        (response) => {
        }
      );
    }
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(backendTotalElements / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
      </IconButton>
    </Box>
  );
};
const requiredResults = 20;

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const ListAccountOfficers = () => {
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [backendPagination, setBackendPagination] = useState({
    page_Index: null,
    total_pages: null,
    records_displayed: null,
    total_elements: null,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const alert = useSelector((state) => state.alert);
  const backendTotalElements = useSelector(
    (state) => state.settings.backendTotalElements
  );

  useEffect(() => {
    checkAuthorities({
      can_create: "ACCOUNT_OFFICER_MODULE:CAN_CREATE",
      can_read: "ACCOUNT_OFFICER_MODULE:CAN_READ",
      can_update: "ACCOUNT_OFFICER_MODULE:CAN_UPDATE",
      can_delete: "ACCOUNT_OFFICER_MODULE:CAN_DELETE",
      can_export: "ACCOUNT_OFFICER_MODULE:CAN_EXPORT",
      can_approve: "ACCOUNT_OFFICER_MODULE:CAN_APPROVE",
    });
    getAllAccountOfficers({ size: requiredResults, pageIndex: 0 }).then(
      (response) => {
        dispatch(setAccountOfficers(response.data.data));
        setBackendPagination({
          page_Index: response.data.page_index,
          total_pages: response.data.total_pages,
          records_displayed: response.data.records_displayed,
          total_elements: response.data.total_elements,
        });
        dispatch(
          setAccountOfficerBackendPagination({
            page_Index: response.data.page_index,
            total_pages: response.data.total_pages,
            records_displayed: response.data.records_displayed,
            total_elements: response.data.total_elements,
          })
        );
        // dispatch(setBackendPagination(response));
      }
    );
  }, []);

  const page = user.page_index;
  const accountOfficers = useSelector(
    (state) => state.accountOfficer.accountOfficers
  );
  // const accountOfficers = user.accountOfficers;
  const total_elements = user.total_elements;
  const fields = [
    "Officer_Code",
    "Full_Name",
    "Email",
    "Mobile_Number",
    "Shareholder",
    "Activated",
  ];
  const [searchTerm, setSearchTerm] = useState("");

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - accountOfficers.length)
      : 0;

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleView = (accountOfficer) => {
    dispatch(setDynamicUser(accountOfficer));
    navigate(`/account-officer-details/${accountOfficer.officer_code}`, {
      replace: false,
    });
  };
  const handleEdit = (accountOfficer) => {    dispatch(setDynamicUser(accountOfficer));
    navigate("/edit-account-officer");
  };
  const handleDelete = (accountOfficer) => {
    dispatch(setDynamicUser(accountOfficer));
    setOpen(true);
  };
  const confirmDeleteAccountOfficer = async () => {
    let response;
    deleteAccountOfficer(user.dynamicUser.officer_code).then((response) => {
      if (response.data.operation_code === 0) {
        dispatch(setAlertTitle("Success"));
        dispatch(setAlertMessage(response.data.operation_description));
        dispatch(openAlert());
        dispatch(removeAccountOfficer(user.dynamicUser.officer_code));
        setOpen(false);
        navigate("/account-officers", {
          replace: true,
        });
      } else {
        dispatch(setAlertTitle("ERROR!"));
        dispatch(setAlertMessage(response.data.operation_description));
        dispatch(openAlert());
        setOpen(false);
        navigate("/account-officers", {
          replace: true,
        });
      }
    });
  };

  return (
    <>
      <Grid container>
        <Grid item xs={8} md={8} lg={6}>
          <Typography variant="h1">{`${accountOfficers.length} of ${backendPagination.total_elements} Account officers`}</Typography>
        </Grid>
        <Grid item xs={4} md={4} lg={6}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormControl
                variant="standard"
                sx={{ width: "100%", marginBottom: "10px" }}
              >
                <BootstrapInput
                  id="searchTxt"
                  size="small"
                  type="text"
                  placeholder="Search"
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                  sx={{ backgroundColor: "#FFFFFF" }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2} md={6}>
              <Button
                variant="contained"
                disabled={sessionStorage.getItem("can_create") === "false"}
                sx={{
                  backgroundColor: "#052A6C",
                  // margin: "2% 1% 2% 0",
                  height:"78%",
                  width: "100%",
                }}
                onClick={() =>
                  navigate("/create-account-officer", {
                    replace: false,
                  })
                }
              >
                Add account-officer
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {alert.open === true ? (
        <AlertModal
          sx={{ margin: "20px", width: "50%", align: "right" }}
        ></AlertModal>
      ) : (
        <></>
      )}
      <TableContainer component={Paper}>
        <Table aria-label="custom pagination table" size="small">
          <TableHead>
            <TableRow>
              {fields.map((field) => (
                <TableCell key={field}>
                  <strong>{field}</strong>
                </TableCell>
              ))}
              <TableCell colSpan={3}>
                <strong>Actions</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {accountOfficers
              ?.filter((val) => {
                if (searchTerm === "") {
                  return val;
                } else if (
                  val.officer_code?.includes(searchTerm) ||
                  val.officer_code?.includes(searchTerm) ||
                  val.last_name?.includes(searchTerm) ||
                  val.primary_email?.includes(searchTerm) ||
                  val.primary_phone_number?.includes(searchTerm) ||
                  val.shareholder.shareholder_name?.includes(searchTerm)
                ) {
                  return val;
                }
              })
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((accountOfficer) => (
                <TableRow
                  key={accountOfficer.officer_code}
                  sx={{
                    "& .css-1ex1afd-MuiTableCell-root": {
                      fontSize: 12,
                    },
                    "& .css-ahj2mt-MuiTypography-root": {
                      fontSize: 12,
                    },
                  }}
                >
                  <TableCell style={{ width: 80 }}>
                    {accountOfficer.officer_code}
                  </TableCell>
                  <TableCell style={{ width: 140 }}>
                    {accountOfficer.first_name + " " + accountOfficer.last_name}
                  </TableCell>
                  <TableCell style={{ width: 80 }}>
                    {accountOfficer.primary_email}
                  </TableCell>
                  <TableCell style={{ width: 80 }}>
                    {accountOfficer.primary_phone_number}
                  </TableCell>
                  <TableCell
                    style={{ width: 180 }}
                  >{`${accountOfficer.shareholder.shareholder_name
                    .toLowerCase()
                    .substring(0, 15)}...`}</TableCell>
                  <TableCell style={{ width: 20 }} align="center">
                    {accountOfficer.enabled ? (
                      <Check fontSize="small" />
                    ) : (
                      <Clear fontSize="small" />
                    )}
                  </TableCell>
                  <TableCell style={{ width: 20 }} sx={{ padding: 0 }}>
                    {
                      <Tooltip title="View Account Officer">
                        <span>
                        <IconButton
                          disabled={
                            sessionStorage.getItem("can_read") === "false"
                          }
                          onClick={() => {
                            handleView(accountOfficer);
                          }}
                          style={{
                            color:
                              sessionStorage.getItem("can_read") === "true"
                                ? green[500]
                                : "gray",
                          }}
                        >
                          <RemoveRedEye fontSize="small" />
                        </IconButton>
                        </span>
                      </Tooltip>
                    }
                  </TableCell>
                  <TableCell style={{ width: 20 }} sx={{ padding: 0 }}>
                    {
                      <Tooltip title="Edit Account Officer">
                        <span>
                        <IconButton
                          disabled={
                            sessionStorage.getItem("can_update") === "false"
                          }
                          onClick={() => {
                            handleEdit(accountOfficer);
                          }}
                          style={{
                            color:
                              sessionStorage.getItem("can_update") === "true"
                                ? orange[500]
                                : "gray",
                          }}
                        >
                          <Edit fontSize="small" />
                        </IconButton>
                        </span>
                      </Tooltip>
                    }
                  </TableCell>
                  <TableCell style={{ width: 20 }} sx={{ padding: 0 }}>
                    {
                      <Tooltip title="Delete Account Officer">
                        <span>
                        <IconButton
                          disabled={
                            sessionStorage.getItem("can_delete") === "false"
                          }
                          onClick={() => {
                            handleDelete(accountOfficer);
                          }}
                          style={{
                            color:
                              sessionStorage.getItem("can_delete") === "true"
                                ? red[500]
                                : "gray",
                          }}
                        >
                          <Delete fontSize="small" />
                        </IconButton>
                        </span>
                      </Tooltip>
                    }
                  </TableCell>
                </TableRow>
              ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={3}
                count={accountOfficers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                sx={{
                  "&  .MuiTablePagination-spacer": {
                    display: "none",
                  },
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .css-hlj6pa-MuiDialogActions-root": {
            paddingRight: "24px",
            paddingLeft: "24px",
          },
          "& .css-hlj6pa-MuiDialogActions-root>:not(:first-of-type)": {
            marginLeft: "60%",
          },
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Report color="error" /> Are you sure you'd like to remove this
            Account officer?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleCloseDialog}
            sx={{ backgroundColor: "#B20808" }}
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={confirmDeleteAccountOfficer}
            autoFocus
            sx={{ backgroundColor: "#052A6C" }}
            disabled={!sessionStorage.getItem("can_delete")}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ListAccountOfficers;
