import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AlertModal from '../components/alertModal';
import { UpdateButton } from '../components/styled/buttons';
import { FormInputLabel } from '../components/styled/inputs';
import { ActivateBootstrapInput as BootstrapInput } from '../components/styled/labels';
import useValidation from '../hooks/useValidate';
import { activateNewUser } from '../service/userService';
import { openAlert, setAlertMessage, setAlertTitle } from '../store/alertSlice';
import ShareholderWrapper from './shareholderPages/ShareholderWrapper';

const ActivateUser = () => {
  const [isSubmittting, setIsSubmittting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordUpdateData, setPasswordUpdateData] = useState({
                                                                password: '',
                                                                confirm_password: '',
                                                                old_password: '',
                                                                account_non_expired: true,
                                                                account_non_locked: true,
                                                                credentials_non_expired: true,
                                                                enabled: true,
                                                              });

  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const alert = useSelector((state) => state.alert);

  const {handleValidationCheck, values, errors} = useValidation();
  //   const [passwordUpdateData, setPasswordUpdateData] = useState({});
 

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Update my state from form change
  const setInputFieldData = (e) => {
    const { name, value } = e.target;
    setPasswordUpdateData({
      ...passwordUpdateData,
      [name]: value,
    });
  };

  //   // Get the URL of the current page
  const url = window.location.href;

  const urlObject = new URL(url);

  // Get the query parameters from the URL
  const queryParams = new URLSearchParams(urlObject.search);

  // Access individual query parameters
  const idQueryParam = queryParams.get('id');

  const userType = queryParams.get('user_type');

  const userActivationToken = queryParams.get('token');

  let currentUser;

  if (userType === 'ADMIN') {
    currentUser = 'administrators';
  } else {
    currentUser = 'account-officers';
  }
  const activateUser = (event) => {
    event.preventDefault();
    setIsSubmittting(true);
    activateNewUser({
      id: idQueryParam,
      action: 'ACTIVATE',
      data: passwordUpdateData,
      user: currentUser,
      token: userActivationToken
    }).then((response) => {
      if (response?.data?.operation_code === 1) {
        dispatch(setAlertTitle('Error'));
        dispatch(setAlertMessage(response?.data?.message));
        dispatch(openAlert());
      } else {
        navigate('/');
      }
    });
    setIsSubmittting(false);
  };

  return (
    <ShareholderWrapper title="Shareholder Activation Guide" sidebar={false}>
    {alert.open === true ? (
      <AlertModal
        sx={{
          margin: '20px',
          width: '50%',
          align: 'right',
        }}
      ></AlertModal>
    ) : (
      <></>
    )}
      <Grid
        container
        md={12}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '60vh',
        }}
      >
        <Grid
          item
          spacing={2}
          sx={{
            marginTop: '20px',
          }}
          md={4}
        >
          <Grid item md={12}>
            <Card>
              <CardHeader
                title={
                  <Typography
                    sx={{
                      fontStyle: 'normal',
                      fontWeight: '600',
                      fontSize: '20px',
                      color: ' #0A2240',
                      textAlign: 'center',
                    }}
                  >
                    Activate account
                  </Typography>
                }
              />
              <CardContent>
                <FormGroup 
                  sx={{
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '16x',
                    color: ' #0A2240',
                    marginBottom: '20px',
                    textAlign: 'center',
                  }}>
                </FormGroup>
                <Typography
                  sx={{
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '16x',
                    color: ' #0A2240',
                    borderBottom: '1px solid #0A2240',
                    marginBottom: '20px',
                    textAlign: 'center',
                  }}
                >
                  Manage Your Password
                </Typography>
                <form
                  onSubmit={(e) => {activateUser(e);}}
                  onChange={setInputFieldData}
                >
                  <Grid container spacing={2}>
                    <Grid item md={12}>
                      <FormControl variant="standard" fullWidth={true}>
                        <FormInputLabel shrink htmlFor="bootstrap-input">
                          Old Password
                        </FormInputLabel>
                        <BootstrapInput
                          id="bootstrap-input"
                          name="old_password"
                          required
                          type={showPassword ? 'text' : 'password'}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleTogglePasswordVisibility}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={12}>
                      <FormControl variant="standard" fullWidth={true}>
                        <FormInputLabel shrink htmlFor="bootstrap-input">
                          New Password
                        </FormInputLabel>
                        <BootstrapInput
                          id="bootstrap-input"
                          name="password"
                          required
                          type={showPassword ? 'text' : 'password'}
                          onChange={(event) => {
                              handleValidationCheck(event);
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleTogglePasswordVisibility}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        {errors.password && <Typography color='red' fontSize='small' sx={{marginBottom: 2}}>{errors.password}</Typography>}
                      </FormControl>
                    </Grid>
                    <Grid item md={12}>
                      <FormControl variant="standard" fullWidth={true}>
                        <FormInputLabel shrink htmlFor="bootstrap-input">
                          Confirm New Password
                        </FormInputLabel>
                        <BootstrapInput
                          id="bootstrap-input"
                          name="confirm_password"
                          required
                          type={showPassword ? 'text' : 'password'}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleTogglePasswordVisibility}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={12}>
                      <UpdateButton
                        type="submit"
                        disabled={errors.password || isSubmittting}
                        sx={{
                          bgcolor: isSubmittting ? 'grey' : '',
                          width: '100%',
                          height: '3.4em',
                        }}
                      >
                        {isSubmittting && (
                          <CircularProgress color="secondary" />
                        )}
                        {!isSubmittting && 'Change Password'}
                      </UpdateButton>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </ShareholderWrapper>
  );
};

export default ActivateUser;
