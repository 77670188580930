import { Close, ErrorOutline, PieChart, Visibility } from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import AlertModal from "../../components/alertModal";
import { UpdateButton } from "../../components/styled/buttons";
import dividendService from "../../service/officer/dividendsService";
import {
  openAlert,
  setAlertMessage,
  setAlertTitle,
} from "../../store/alertSlice";
import ShareholderWrapper from "./ShareholderWrapper";

const StyledTableCell = styled(TableCell)(() => ({
  color: "#0A2240",
  fontWeight: 500,
  fontSize: 14,
}));

const DataStyledTableCell = styled(TableCell)(() => ({
  color: "#88898A",
  fontWeight: 500,
  fontSize: 16,
}));

const StyledAvatar = styled(Avatar)(() => ({
  width: "80%",
  borderRadius: "8%",
  height: 30,
  fontSize: 16,
  fontWeight: 500,
}));

const ModalHeaderTypography = styled(Typography)(() => ({
  color: "#000000",
  fontWeight: 500,
  fontSize: 12,
}));

const ModalTypography = styled(Typography)(() => ({
  color: "#5F6868",
  fontWeight: 500,
  fontSize: 16,
}));

const StyledStack = {
  borderBottom: "1px solid #D2D1D1",
  pl: 4,
  pr: 10,
  pt: 2,
  pb: 2,
};

const StyledStackHeader = {
  pl: 4,
  pr: 10,
  pt: 2,
  pb: 2,
};

const ModalTextTypography = styled(Typography)(() => ({
  color: "#3F4041",
  fontWeight: 400,
  fontSize: 16,
}));

const StyledBox = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    md: "40%",
    sm: "100%",
    xs: "100%",
  },
  borderRadius: 8,
  boxShadow:
    "inset 0 -3em 3em #00000010, 0 0 0 2px #00000020, 0.3em 0.3em 1em #00000050",
  p: 4,
}));

function ShareholderDividends() {
  const [openModal, setOpenModal] = useState(false);
  const [showAlert, setShowAlert] = useState(true);
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleDetailOpen = () => setOpenDetailModal(true);
  const handleClose = () => setOpenModal(false);
  const handleDetailClose = () => setOpenDetailModal(false);
  const [dividends, setDividends] = useState([]);
  const [singleDividend, setSingleDividend] = useState({});
  const [missedDividend, setMissedDividend] = useState({ years_claimed: [] });
  const [isSubmittting, setIsSubmittting] = useState(false);
  const [initialYear] = useState(2006);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alert);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setMissedDividend({
      ...missedDividend,
      [name]: value,
    });
  };

  // Generate years for claim dividends
  const generateYearList = (defaultYear) => {
    const date = new Date();
    const currentYear = date.getFullYear();
    let yearsList = [];

    for (let year = defaultYear; year <= currentYear; year++) {
      yearsList.push(year);
    }
    return yearsList;
  };

  const years = useMemo(() => generateYearList(initialYear), [initialYear]);

  const formatPrice = (price) => new Intl.NumberFormat("UGX").format(price);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmittting(true);
    dividendService
      .claimForMissingDividends({ data: missedDividend })
      .then((response) => {
        const message = response?.data
          ? `Claim with number ${response?.data?.data?.ticket_number} has been created`
          : "Claim successfully created";
        dispatch(setAlertTitle("Success"));
        dispatch(setAlertMessage(message));
        dispatch(openAlert());
        handleClose();
      })
      .catch(() => {
        dispatch(setAlertTitle("ERROR!"));
        dispatch(setAlertMessage("An error occured while raising a claim"));
        dispatch(openAlert());
      });
    setIsSubmittting(false);
  };

  useEffect(() => {
    fetchAllDividendsByShareholder();
    return () => setDividends([]);
  }, []);

  const fetchAllDividendsByShareholder = async () => {
    const response = await dividendService.findDividendsByShareholderCode({
      shareholderCode:
        searchParams.get("shareholder_code") ??
        sessionStorage.getItem("shareholder_code"),
    });
    setDividends(response.data.data);
  };

  const loadDividendDetails = (index) => {
    setSingleDividend(dividends[index]);
    handleDetailOpen();
  };

  return (
    <ShareholderWrapper title="Shareholder dividends">
      <Box
        sx={{
          bgcolor: "#ffffff",
          mt: 4,
          borderRadius: 2,
          padding: {
            md: "3em 4em 6em 4em",
            sm: "3em 0.5em 4em 0.5em",
          },
          width: "100%",
        }}
      >
        {alert.open === true ? (
          <AlertModal
            sx={{ margin: "20px", width: "50%", align: "right" }}
          ></AlertModal>
        ) : (
          <></>
        )}
        <form onSubmit={handleSubmit} onChange={handleChange}>
          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <StyledBox
              sx={{
                width: {
                  sm: "auto",
                  xs: "90%",
                },
              }}
            >
              <Stack
                direction="column"
                sx={{ borderRadius: 2, position: "realtive", bgcolor: "white" }}
              >
                <Close
                  fontSize="medium"
                  sx={{
                    position: "absolute",
                    right: 40,
                    top: 30,
                    "&:hover": {
                      color: "red",
                      cursor: "pointer",
                    },
                  }}
                  onClick={handleClose}
                />
                <Grid
                  container
                  xs={12}
                  component="form"
                  sx={{
                    mt: 4,
                    padding: {
                      md: "10px 0 80px 50px",
                      sm: 0,
                      xs: 0,
                    },
                    width: {
                      md: "70%",
                      sm: "100%",
                      xs: "100%",
                    },
                  }}
                >
                  <Typography
                    variant="h6"
                    component="h2"
                    sx={{
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                  >
                    Claim Unpaid Dividends
                  </Typography>
                  <Grid item xs={12} sm={12} sx={{ mt: 3 }}>
                    <FormControl
                      variant="outlined"
                      sx={{ width: "100%", mb: 2, padding: "0 4px 0 4px" }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: 14,
                          pb: 1,
                        }}
                      >
                        Select the year for which Dividend has not been received
                      </Typography>
                      <Select
                        multiple
                        name="years_claimed"
                        required
                        onChange={handleChange}
                        value={missedDividend.years_claimed}
                        size="small"
                        input={
                          <OutlinedInput label="2021" name="years_claimed" />
                        }
                        renderValue={(selected) => (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            {selected.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                      >
                        {years &&
                          years?.map((year) => (
                            <MenuItem value={year} key={year}>
                              {year}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} sx={{ mt: 3 }}>
                    <FormControl
                      variant="outlined"
                      sx={{ width: "100%", mb: 2, padding: "0 4px 0 4px" }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: 14,
                          pb: 1,
                        }}
                      >
                        Comment (Description of Claim)
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        type="text"
                        variant="outlined"
                        label="Reason for the claim"
                        size="small"
                        name="comment"
                        required
                        pattern="\w{10,}"
                        multiline={true}
                        minRows={5}
                        maxRows={10}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} sx={{ mt: 3 }}>
                    <FormControl
                      variant="outlined"
                      sx={{ width: "100%", mb: 2, padding: "0 4px 0 4px" }}
                    >
                      <UpdateButton
                        sx={{
                          width: 160,
                          height: 40,
                          width: "auto",
                          bgcolor: isSubmittting ? "grey" : "",
                        }}
                        disabled={isSubmittting}
                        type="submit"
                        onSubmit={handleSubmit}
                      >
                        {isSubmittting && (
                          <CircularProgress color="secondary" />
                        )}
                        {!isSubmittting && "Submit Claim"}
                      </UpdateButton>
                    </FormControl>
                  </Grid>
                </Grid>
              </Stack>
            </StyledBox>
          </Modal>
        </form>
        <Modal
          open={openDetailModal}
          onClose={handleDetailClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <StyledBox
            sx={{
              mr: {
                xs: "-20px",
              },
              mt: {
                xs: "20px",
              },
              width: {
                lg: "60%",
                md: "40%",
                sm: "60%",
                xs: "90%",
              },
            }}
          >
            <Stack
              direction="column"
              sx={{ borderRadius: 2, position: "realtive", bgcolor: "white" }}
            >
              <Close
                fontSize="medium"
                sx={{
                  position: "absolute",
                  right: 40,
                  top: 30,
                  "&:hover": {
                    color: "red",
                    cursor: "pointer",
                  },
                }}
                onClick={handleDetailClose}
              />
              <Grid container>
                <Stack
                  direction="column"
                  sx={{
                    width: "100%",
                    mt: 4,
                    mb: 2,
                    ml: 4,
                    mr: 4,
                  }}
                >
                  <Typography
                    sx={{ fontWeight: 600, fontSize: 16, color: "#000000" }}
                  >
                    {singleDividend?.dividend_year}
                  </Typography>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    sx={{ gap: 2 }}
                  >
                    <Typography
                      sx={{ color: "#0033A1", fontWeight: 700, fontSize: 32 }}
                    >
                      UGX {formatPrice(singleDividend?.actual_net_amount)}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    bgcolor: "#F2F3F4B2",
                    width: "100%",
                    borderBottom: "1px solid #D2D1D1",
                    pt: 2,
                    pl: 4,
                    pr: 4,
                    pb: 2,
                    ml: 4,
                    mr: 4,
                  }}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      columnGap: 1,
                    }}
                  >
                    <PieChart sx={{ color: "#01422A" }} />
                    <Typography
                      sx={{ color: "#FFB21E", fontWeight: 600, fontSize: 20 }}
                    >
                      {formatPrice(singleDividend?.shares_held) || 0}
                    </Typography>
                    <Typography
                      sx={{ color: "#000000", fontWeight: 300, fontSize: 12 }}
                    >
                      Shares
                    </Typography>
                  </Typography>
                  <Stack direction="column">
                  </Stack>
                </Stack>
                <Grid
                  container
                  xs={12}
                  sx={{
                    bgcolor: "#F2F3F4B2",
                    width: "100%",
                    mb: 2,
                    ml: 4,
                    mr: 4,
                    rowGap: 2,
                    p: "10px 30px",
                  }}
                >
                  <Grid item xs={6}>
                    <Stack direction="column" justifyContent="space-between">
                      <ModalHeaderTypography>
                        Transaction ID
                      </ModalHeaderTypography>
                      <ModalTextTypography>
                        {singleDividend?.external_ref_number}
                      </ModalTextTypography>
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack direction="column" justifyContent="space-between">
                      <ModalHeaderTypography>
                        Payment Ref. No
                      </ModalHeaderTypography>
                      <ModalTextTypography>
                        {singleDividend?.payment_ref_number}
                      </ModalTextTypography>
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack
                      direction="column"
                      item
                      justifyContent="space-between"
                    >
                      <ModalHeaderTypography>
                        Account Number
                      </ModalHeaderTypography>
                      <ModalTextTypography>
                        {singleDividend?.payment_account_number}
                      </ModalTextTypography>
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack
                      direction="column"
                      item
                      justifyContent="space-between"
                    >
                      <ModalHeaderTypography>
                        Account Name
                      </ModalHeaderTypography>
                      <ModalTextTypography>
                        {singleDividend?.payment_account_name}
                      </ModalTextTypography>
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack
                      direction="column"
                      item
                      justifyContent="space-between"
                    >
                      <ModalHeaderTypography>Comment</ModalHeaderTypography>
                      <ModalTextTypography>
                        {singleDividend?.comment}
                      </ModalTextTypography>
                    </Stack>
                  </Grid>
                </Grid>
                <Stack
                  direction="column"
                  sx={{
                    bgcolor: "#F2F3F4B2",
                    width: "100%",
                    mb: 10,
                    ml: 4,
                    mr: 4,
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{ ...StyledStackHeader }}
                  >
                    <Typography>
                      <Typography
                        sx={{ color: "#0E2036", fontWeight: 500, fontSize: 12 }}
                      >
                        Tax Rate
                      </Typography>
                      <Typography
                        sx={{ color: "#0033A1", fontWeight: 500, fontSize: 28 }}
                      >
                        {singleDividend?.tax_rate}%
                      </Typography>
                    </Typography>
                    <Typography>
                      <Typography
                        sx={{ color: "#0E2036", fontWeight: 500, fontSize: 12 }}
                      >
                        Gross payout ratio
                      </Typography>
                      <Typography
                        sx={{ color: "#33A920", fontWeight: 500, fontSize: 28 }}
                      >
                        {
                          singleDividend?.dividend_summary
                            ?.dividend_payout_ratio
                        }
                      </Typography>
                    </Typography>
                  </Stack>
                  <Stack direction="column">
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      sx={{ ...StyledStack }}
                    >
                      <ModalTypography>Gross Amount</ModalTypography>
                      <ModalTypography>
                        UGX {formatPrice(singleDividend?.actual_gross_amount)}
                      </ModalTypography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      sx={{ ...StyledStack }}
                    >
                      <ModalTypography>Tax</ModalTypography>
                      <ModalTypography>
                        UGX {formatPrice(singleDividend?.actual_tax_amount)}
                      </ModalTypography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      sx={{ ...StyledStack }}
                    >
                      <ModalTypography>Net Amount</ModalTypography>
                      <ModalTypography>
                        UGX {formatPrice(singleDividend?.actual_net_amount)}
                      </ModalTypography>
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>
            </Stack>
          </StyledBox>
        </Modal>
        <Stack
          direction="column"
          sx={{
            width: "100%",
          }}
        >
          <Stack direction="column">
            {showAlert && (
              <Alert
                icon={
                  <ErrorOutline
                    fontSize="inherit"
                    sx={{
                      color: "#DA930A",
                    }}
                  />
                }
                severity="error"
                onClose={() => setShowAlert(false)}
                sx={{ bgcolor: "#FFE99C", color: "#DA930A" }}
              >
                This information comes from the logs of SHUL and cannot be
                edited
              </Alert>
            )}
            <Grid
              container
              sx={{
                justifyContent: "space-between",
                minHeight: 120,
                alignContent: "center",
                mt: 1,
                mb: 1,
              }}
            >
              <Grid item xs={12} sm={12} md={6}>
                <Typography
                  sx={{
                    color: "#000",
                    fontWeight: 500,
                    fontSize: {
                      md: 20,
                      sm: 16,
                    },
                    pt: 1,
                    pb: 1,
                  }}
                >
                  Entitlements
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  flexDirection: {
                    sm: "column",
                    xs: "column",
                    md: "row",
                  },
                  gap: 1,
                }}
              >
              </Grid>
            </Grid>
          </Stack>
          <Stack sx={{ overflow: "auto" }}>
            <TableContainer
              sx={{
                display: "table",
                tableLayout: "fixed",
                width: "100%",
                borderRadius: 2,
              }}
            >
              {/* Main Table */}
              <Table
                aria-label="caption table"
                sx={{
                  width: "100%",
                  border: "1px solid #B8B7B760",
                  borderRadius: 2,
                }}
              >
                <TableHead>
                  <TableRow sx={{ bgcolor: "#B8B7B730" }}>
                    <StyledTableCell align="left">
                      Financial Year
                    </StyledTableCell>
                    <StyledTableCell align="left">Shares Held</StyledTableCell>
                    <StyledTableCell align="left">Entitlement</StyledTableCell>
                    <StyledTableCell align="left">Payment Mode</StyledTableCell>
                    <StyledTableCell align="left">Payment Account</StyledTableCell>
                    <StyledTableCell align="left">Dividend Type</StyledTableCell>
                    <StyledTableCell align="left" sx={{ color: "transparent" }}>Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dividends?.map((dividend, index) => (
                    <TableRow key={dividend.payment_ref_number}>
                      <DataStyledTableCell
                        size="medium"
                        align="left"
                        sx={{ fontWeight: "bold" }}
                      >
                        {dividend.dividend_year}
                      </DataStyledTableCell>
                      <DataStyledTableCell size="medium" align="left">
                        {formatPrice(dividend?.shares_held)}
                      </DataStyledTableCell>
                      <DataStyledTableCell size="medium" align="left">
                        {formatPrice(dividend?.actual_net_amount)}
                      </DataStyledTableCell>
                      <DataStyledTableCell size="medium" align="left">
                        {dividend.payment_mode_name}
                      </DataStyledTableCell>
                      <DataStyledTableCell size="medium" align="left">
                        {dividend.payment_account_number}
                      </DataStyledTableCell>
                      <DataStyledTableCell size="medium" align="left">
                        {dividend.dividend_type === "FINAL" && (
                          <StyledAvatar
                            sx={{ bgcolor: "#ACE4CF", color: "#01422A" }}
                          >
                            {dividend.dividend_type}
                          </StyledAvatar>
                        )}
                        {dividend.dividend_type === "INTERIM" && (
                          <StyledAvatar
                            sx={{ bgcolor: "#FFE99C", color: "#DA930A" }}
                          >
                            {dividend.dividend_type}
                          </StyledAvatar>
                        )}
                      </DataStyledTableCell>
                      <TableCell size="medium" align="left">
                        <Visibility
                          onClick={() => loadDividendDetails(index)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
          <Stack direction="column">
            <Grid
              container
              sx={{
                justifyContent: "space-between",
                minHeight: 120,
                alignContent: "center",
                mt: 1,
                mb: 1,
              }}
            >
              <Grid item xs={12} sm={12} md={6}>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                  <Button
                    sx={{
                      bgcolor: "#052A6C",
                      color: "#ffffff",
                      "&:hover": {
                        bgcolor: "#0033A1",
                      },
                      textTransform: "capitalize",
                      width: "100%",
                      padding: {
                        md: "4px 10px",
                        sm: "4px 4px",
                      },
                      fontWeight: 500,
                      minHeight: 50,
                      fontSize: {
                        md: 16,
                        sm: 12,
                      },
                    }}
                    onClick={handleOpen}
                  >
                    Make an inquiry
                  </Button>
                </Grid>
            </Grid>
          </Stack>
        </Stack>
      </Box>
    </ShareholderWrapper>
  );
}

export default ShareholderDividends;
