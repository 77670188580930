import { Clear, Delete, Done, Edit, FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage, RemoveRedEye, Report } from "@mui/icons-material";
import { Box, Button, CircularProgress, Dialog, DialogActions,
        DialogContent, DialogContentText, FormControl, Grid, IconButton, InputBase, MenuItem, Paper,
        Skeleton, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination,
        TableRow, Tooltip, Typography, alpha, styled, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
  
  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
    const adminState = useSelector((state) => state.administrator);
    const backendPagination = adminState.backendPagination;
    const backendPageIndex = adminState.page_Index;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      if (page >= Math.ceil(count / rowsPerPage) - 1) {
        getAllAdministrators({ size: 20, pageIndex: backendPageIndex + 1 }).then(
          (response) => {
          }
        );
      }
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={
            page >= Math.ceil(backendPagination.total_elements / rowsPerPage) - 1
          }
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
        </IconButton>
      </Box>
    );
  };
  
  const requiredResults = 20;
  
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
      border: "1px solid #ced4da",
      fontSize: 16,
      width: "100%",
      padding: "10px 12px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));
  
  function FileViolations() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const violations = useSelector((state) => state.files.violations);
    const locale = useParams();
    let entryViolations = [];
    console.log('locale: ', locale);
    console.log('violations: ', violations);
    for (let index = 0; index < violations.length; index++) {
      const element = violations[index];
      if (locale.file_id === element.id) {
        console.log(element);
        entryViolations.push(element)       
      }
      
    }
    // entryViolations.push(violations.map((violation) => violation.id === locale.file_id))
    console.log('Entry violations: ', entryViolations);
  
    // const page = user.page_index;
    const headers = [
      "Property",
      "Value",
      "Message",
    ];


  
    return (
      <>  
        <TableContainer component={Paper}>
          {loading ? (
            <Skeleton
              variant="rectangle"
              height={"80vh"}
              width={"80vw"}
            ></Skeleton>
          ) : (
            <Table aria-label="custom pagination table" size="small">
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableCell
                      key={header}
                      align="left"
                      sx={{ fontWeight: "bold" }}
                    >
                      {header}
                    </TableCell>
                  ))}
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold" }}
                    colSpan={3}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
  
              <>
                  <TableBody>
                    {violations
                      .map((record) => (
                        <TableRow key={record.property}>
                          <TableCell component="th">{record.property}</TableCell>
                          <TableCell align="left">{record.invalid_value}</TableCell>
                          <TableCell align="left">{record.message}</TableCell>
                        </TableRow>
                      ))}
  
                  </TableBody>
              </>
            </Table>
          )}
        </TableContainer>
      </>
    );
  }
  export default FileViolations;
  