import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment/moment";
import { API, testApi, unprotectedApi } from "../config/API";

export const getAllShareholders = async (props) => {
  return testApi.get(
    `shareholders?size=${props.size}&page_index=${props.pageIndex}&direction=asc&field_name=shareholder_code`
  );
};
export const ShareholdersAdvancedSearch = async (props) => {
  return testApi.post(
    `shareholders/filter?size=${props.size}&page_index=${props.pageIndex}&direction=asc&field_name=shares_held`,
    props.data
  );
};
export const createShareholder = async (props) => {
  return testApi.post(`/shareholders/`, props);
};
export const shareholderBulkUpload = async (props) => {
  return testApi.post(`shareholders/bulk`, props);
};
export const updateShareholder = async (props) => {
  return testApi.put(`/shareholders/${props.id}`, props.shareholder);
};

export const getAllAdministrators = async (props) => {
  return testApi.get(
    `administrators?size=${props.size}&page_index=${props.pageIndex}&direction=desc&field_name=administrator_id`
  );
};
export const filterAdministrators = async (props) => {
  return testApi.get(
    `administrators?/filter?size=${props.size}&page_index=${props.pageIndex}&direction=asc&field_name=administrator_id&filter_type=ALL`
  );
};
export const getAllAuthenticationLogs = async (props) => {
  return testApi.get(
    `security/log?size=${props.size}&page_index=${props.pageIndex}&direction=desc&field_name=created_on`
  );
};
export const getAllAccountOfficers = async (props) => {
  return testApi.get(
    `account-officers?size=${props.size}&page_index=${props.pageIndex}&direction=asc&field_name=officer_code`
  );
};
export const getShareholderAccountOfficers = async (shareholderCode) => {
  return testApi.get(`shareholders/${shareholderCode}/account-officers`);
};
export const deleteAccountOfficer = async (props) => {
  return testApi.delete(`/account-officers/${props}`);
};
export const getShareholderDividends = async (props) => {
  return testApi.get(
    `dividends/shareholder/${props.shareholderCode}?size=${props.size}&page_index=${props.pageIndex}&direction=asc&field_name=payment_ref_number`
  );
};
export const getShareholderHistory = async (props) => {
  return testApi.get(
    `shareholders/revisions/${props.shareholderCode}?size=${props.size}&page_index=${props.pageIndex}&direction=asc&field_name=shareholder_code`
  );
};
export const getSchedules = async (props) => {
  return testApi.get(
    `dividend-summary?size=${props.size}&page_index=${props.pageIndex}&direction=asc&field_name=div_no`
  );
};
export const getDividends = async (props) => {
  return testApi.get(
    `dividends?size=${props.size}&page_index=${props.pageIndex}&direction=asc&field_name=payment_ref_number`
  );
};

export const generateShareholderQrCode = async (props) => {
  return testApi.get(`security/mfa/generate-secret-key`);
};

export const confirmShareholderSecretKey = async (props) => {
  return testApi.post(`security/mfa/confirm-secret-key`, props);
};

export const addAdministrator = async (props) => {
  return testApi.post(`administrators/`, props);
};
export const resetAdministrator = async (props) => {
  return testApi.put(`administrators/update-security/${props.administrator_id}?action=RESET&token=1`, {'password': props.password, 'confirmPassword': props.confirmPassword});
};
export const forgotMyPassword = async (props) => {
  return unprotectedApi.post(`security/forgot`, {id:props});
};
export const logoutShareholder = async () => {
  return testApi.post(`security/logout/`);
};
export const updateAdministratorSecurity = async (props) => {
  return testApi.put(
    `security/add-fg-admin/${props.adminId}`,
    props.functionalGroups
  );
};
export const deleteAdministratorService = async (props) => {
  return testApi.delete(`/administrators/${props}`);
};
export const addAccountOfficer = async (props) => {
  return testApi.post(`account-officers/`, props);
};
export const activateNewUser = async (props) => {
  return await testApi.put(
    `/${props.user}/update-security/${props.id}?action=${props.action}&token=${props.token}`,
    props.data
  );
};

export const loginUser = async (props) => {
  return await unprotectedApi.post(`security/auth/req-token/`, props);
};

export const refreshToken = async (props) => {
  return await unprotectedApi.post(`security/auth/refresh-token/`, props);
};

export const getAdministratorById = async (props) => {
  let result;
  try {
    const resp = await API.get(`administrators/${props}`);
    return resp.data.data;
  } catch (error) {
    return error.message;
  }
};
export const getShareholderById = async (props) => {
  let result;
  try {
    const resp = await API.get(`shareholders/${props}`);
    return resp.data.data;
  } catch (error) {
    return error.message;
  }
};
export const getAccountOfficerById = async (props) => {
  let result;
  try {
    const resp = await API.get(`account-officers/${props}`);
    return resp.data.data;
  } catch (error) {
    return error.message;
  }
};
export const getAccountOfficers = createAsyncThunk(
  "/account-officers",
  async (props) => {
    let result;
    try {
      const resp = await API.get(
        `account-officers?size=${props.results}&page_index=${props.pageIndex}&direction=asc&field_name=officer_code`
      );
      result = {
        page_index: resp.data.page_index,
        total_pages: resp.data.total_pages,
        total_officers: resp.data.total_elements,
        accountOfficers: [...resp.data.data],
      };
      return result;
    } catch (error) {
      return error.message;
    }
  }
);

export const getHistory = createAsyncThunk(
  "/shareholder-history",
  async (shareholderCode) => {
    try {
      const resp = await API.get(
        `shareholders/revisions/${shareholderCode}?size=5&page_index=0&direction=desc&field_name=required_revision_instant`
      );
      return [...resp.data.data];
    } catch (error) {
      return error.message;
    }
  }
);

export const getDividendByShareholderCode = async (shareholderCode) => {
  let response;
  try {
    response = await API.get(
      `dividends/shareholder/${shareholderCode}?size=10&page_index=0&direction=asc&field_name=payment_ref_number`
    );
    return response.data.data;
  } catch (error) {
    return error.message;
  }
};

const formatTime = (tickets) => {
  for (let index = 0; index < tickets.length; index++) {
    const element = {
      ticket_number: tickets[index].ticket_number,
      workflow_status: tickets[index].workflow_status,
      workflow_type: tickets[index].workflow_type,
      last_modified_on: tickets[index].last_modified_on,
      last_modified_by: tickets[index].last_modified_by,
      created_on: moment(tickets[index].created_on).format("Do-MMM-YY"),
      created_by: tickets[index].created_by,
      content: tickets[index].content,
      class_type: tickets[index].class_type,
    };
    tickets.splice(index, 1, element);
  }
  return tickets;
};

export const getTaxRates = createAsyncThunk("/tax-rates", async () => {
  try {
    const resp = await API.get(
      `app-settings/tax-rates?size=10&page_index=0&direction=asc&field_name=tax_rate_code`
    );
    return resp.data.data;
  } catch (error) {
    return error.message;
  }
});
export const getPaymentModes = createAsyncThunk("/payment-modes", async () => {
  try {
    const resp = await API.get(`app-settings/listpaymentmodes`);
    return resp.data.data;
  } catch (error) {
    return error.message;
  }
});
export const getCountries = createAsyncThunk("/countries", async () => {
  try {
    const resp = await API.get(
      `app-settings/countries?size=5&page_index=0&direction=asc&field_name=country_code`
    );

    return resp.data.data;
  } catch (error) {
    return error.message;
  }
});
export const PostAccountOfficer = async (accountOfficer) => {
  let response;
  try {
    response = await API.post(`/account-officers`, accountOfficer);
  } catch (error) {
  }
  return response;
};
