import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import KeyIcon from '@mui/icons-material/Key';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsIcon from '@mui/icons-material/Notifications';
import UpdateIcon from '@mui/icons-material/Update';
import List from '@mui/material/List';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { logoutShareholder } from '../../service/userService';
import { useAuth } from '../../store/authContext';
import SidebarListItem from './SidebarListItem';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';

function Sidebar() {
  const navigate = useNavigate();
  const { setUser } = useAuth();

  const logout = (event) => {
    logoutShareholder().then((response) => {
      event.preventDefault();
      event.stopPropagation();
      localStorage.clear();
      sessionStorage.clear();
      setUser(null);
      // navigate("/", { replace: true });
    });
  };

//   import * as React from 'react';
// import SendIcon from '@mui/icons-material/Send';
// import Stack from '@mui/material/Stack';

// export default function IconLabelButtons() {
//   return (
//     <Stack direction="row" spacing={2}>
//       <Button variant="outlined" startIcon={<DeleteIcon />}>
//         Delete
//       </Button>
//       <Button variant="contained" endIcon={<SendIcon />}>
//         Send
//       </Button>
//     </Stack>
//   );
// }

  const handleDisabledLink = (event) => {
    // t, event.view.location.pathname
    event.preventDefault()
    console.log('This is disabled');
    window.location.assign(`${event.view.location?.host}/shareholder`)
  }
  // to="/dividends"
  // onClick={handleDisabledLink}
  return (
    <List sx={{ width: '90%' }}>
      <SidebarListItem listText="Profile" to="/shareholder">
        <AccountCircleIcon />
      </SidebarListItem>
      {/* <SidebarListItem listText="Dividends (coming soon)" to="/shareholder">
        <CreditCardIcon />
      </SidebarListItem> */}
      {/* <SidebarListItem button={true} listText="Dividends (coming soon)" onClick={(event)=>handleDisabledLink(event)}>
        <CreditCardIcon />
      </SidebarListItem> */}
      <Button style={{textTransform: 'capitalize', paddingLeft: '1.8em'}} startIcon={<CreditCardIcon style={{color: 'grey'}}/>}>
        <span style={{paddingLeft: '2em', color: 'grey'}}>Dividends (coming soon)</span>
      </Button>
      {/* <SidebarListItem listText="Change Requests and Claims" to="/requests">
        <UpdateIcon />
      </SidebarListItem> */}
      <SidebarListItem listText="Change Requests" to="/requests">
        <UpdateIcon />
      </SidebarListItem>
      <SidebarListItem listText="Notifications" to="/notifications">
        <NotificationsIcon />
      </SidebarListItem>
      <SidebarListItem listText="Password and Authentication" to="/change-password">
        <KeyIcon />
      </SidebarListItem>

      <SidebarListItem listText="Logout" button={true} onClick={logout}>
        <LogoutIcon />
      </SidebarListItem>
    </List>
  );
}

export default Sidebar;
