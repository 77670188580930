import { testApi } from "../config/API";

export const getAllTickets = async (props) => {
    return testApi.get(`tickets?size=${props.size}&page_index=${props.pageIndex}&direction=asc&field_name=created_on`);
};
export const filterTickets = async (props) => {
    return testApi.post(`tickets/filter?size=${props.size}&page_index=${props.pageIndex}&direction=asc&field_name=ticket_number&filter_type=ANY`, props.data);
};

export const getATicketByTicketNumber = async ({ ticket_number }) => {
    return testApi.get(`tickets/${ticket_number}`);
};
export const approveTicket = async (props) => {
    return testApi.post(`/tickets/${props.ticketId}?action=ACCEPT`, props.comment);
};
export const approveShareholderBulkTicket = async (props) => {
    return testApi.post(`file-uploads/load/${props}`);
};