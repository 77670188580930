import { Box, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ShareholderWrapper from './ShareholderWrapper';
import { Link } from 'react-router-dom';
import notificationService from '../../service/officer/notificationService';

const StackComponent = ({ notification }) => (
  <Stack
    direction="column"
    sx={{
      borderTop: '0.5px solid #88898A',
      borderBottom: '0.5px solid #88898A',
      padding: '10px 0 30px 0',
    }}
  >
    <Stack direction="row" justifyContent="space-between">
      <Link
        to={`/notifications/dividends-details/${notification.notif_id}`}
        style={{
          textDecoration: 'none',
          color: notification?.read ? '#000000' : '#0033A1',
        }}
      >
        <Typography>{notification.subject}</Typography>
      </Link>
      <Typography>
        {notification && notification?.created_on.split('T')[0]}
      </Typography>
    </Stack>
    <Stack direction="row">{notification.body}</Stack>
  </Stack>
);

function ShareholderNotificationsPage() {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    fetchAllNotifications();
  }, []);

  const fetchAllNotifications = async () => {
    const response = await notificationService.findAllnotifications({
      page_number: 0,
      shareholder_code: sessionStorage.getItem('shareholder_code'),
    });
    setNotifications(response.data.data);
  };

  return (
    <ShareholderWrapper title="Shareholder Notification page">
      <Box
        sx={{
          bgcolor: '#ffffff',
          mt: 4,
          borderRadius: 2,
          padding: '3em 4em 6em 4em',
        }}
      >
        <Typography sx={{ fontSize: 20, fontWeight: 600, mb: 3 }}>
          Notifications
        </Typography>
        <Stack direction="column">
          {notifications &&
            notifications.map((notification) => (
              <StackComponent
                key={notification?.notif_id}
                notification={notification}
              />
            ))}
        </Stack>
      </Box>
    </ShareholderWrapper>
  );
}

export default ShareholderNotificationsPage;
