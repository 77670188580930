import ApartmentIcon from "@mui/icons-material/Apartment";
import GroupsIcon from "@mui/icons-material/Groups";
import PeopleIcon from "@mui/icons-material/People";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import React, { useContext, useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { useDispatch, useSelector } from "react-redux";
import ShareholderCard from "../../components/AdministratorDashboard/ShareholderCard";
import TicketsCard from "../../components/AdministratorDashboard/TicketsCard";
import TicketsActions from "../../components/TicketsActions/TicketsActions";
import TicketsStatus from "../../components/TicketsActions/TicketsStatus";
import AlertModalTest from "../../components/alertModalTest";
import MiniDrawer from "../../components/newSideDrawer/miniSideDrawer";
import ListTicketsTable from "../../components/tables/ListTicketsTable";
import { getAllTickets } from "../../service/notificationService";
import { checkAuthorities } from "../../service/securityService";
import {
  getAllCountries,
  getAllPayModes,
  getAllSystemModules,
  getdashboardMetrics,
} from "../../service/settingsService";
import NavigationContext from "../../store/NavigationContext";
import { setAlertMessage, setAlertTitle } from "../../store/alertSlice";
import {
  setCountries,
  setModules,
  setPaymentModes,
} from "../../store/settingsSlice";
import { setTickets } from "../../store/ticketSlice";
import { setTotalPages } from "../../store/userSlice";

let backPageIndex = 0;
let requestedSize = 240;
const sessionToken = sessionStorage.getItem("token");

const AdministratorDashboard = () => {
  const navigationContext = useContext(NavigationContext);
  const { sidebar } = navigationContext;
  const user = useSelector((state) => state.user);
  const shSettings = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alert);
  const [dashboard, setDashboard] = useState({});
  const [emptyToken, setEmptyToken] = useState(true);
  const [rerunCheck, setRerunCheck] = useState();

  
  useEffect(() => { 
    checkAuthorities({
      can_create: "TICKETS_MODULE:CAN_CREATE",
      can_read: "TICKETS_MODULE:CAN_READ",
      can_update: "TICKETS_MODULE:CAN_UPDATE",
      can_delete: "TICKETS_MODULE:CAN_DELETE",
      can_export: "TICKETS_MODULE:CAN_EXPORT",
      can_approve: "TICKETS_MODULE:CAN_APPROVE",
    });
  }, []);

  useEffect(() => {
    getAllCountries({ size: requestedSize, pageIndex: backPageIndex }).then(
      (response) => {
        if(response.data.operation_code === 0){
          dispatch(setCountries(response?.data?.data));
          dispatch(setAlertTitle("Success"));
          dispatch(setAlertMessage(response?.operation_description));        
        } else {
          dispatch(setAlertTitle("Error"));
          dispatch(setAlertMessage(response?.operation_description));
        }
      }
    );
  }, []);

  useEffect(() => {
    getdashboardMetrics().then((response) => {
      setDashboard(response);
    });
  }, []);

  useEffect(() => {
    getAllPayModes({ size: requestedSize, pageIndex: backPageIndex }).then(
      (response) => {
        if(response?.data?.operation_code === 0){
          dispatch(setPaymentModes(response?.data.data));
          dispatch(setAlertTitle("Success"));
          dispatch(setAlertMessage(response?.data?.operation_description));        
        } else {
          dispatch(setAlertTitle("Error"));
          dispatch(setAlertMessage(response?.data?.operation_description));
        }
      }
    );
  }, []);

  useEffect(() => {
    getAllTickets({ size: requestedSize, pageIndex: backPageIndex }).then(
      (response) => {
        if(response.data.operation_code === 0){
          dispatch(setTickets(response?.data));
          dispatch(setAlertTitle("Success"));
          dispatch(setAlertMessage(response?.data?.operation_description));
        
        } else {
          dispatch(setAlertTitle("Error"));
          dispatch(setAlertMessage(response?.data?.operation_description));
        }
        dispatch(setTotalPages(response?.data?.total_pages));
        
      }
    );
  }, []);

  useEffect(() => {
    getAllSystemModules({ size: requestedSize, pageIndex: backPageIndex }).then(
      (response) => {
        if(response?.data?.operation_code === 0){
          dispatch(setModules(response.data.data));
          dispatch(setAlertTitle("Success"));
          dispatch(setAlertMessage(response?.data?.operation_description));        
        } else {
          dispatch(setAlertTitle("Error"));
          dispatch(setAlertMessage(response?.data?.operation_description));
        }        
      }
    );
  }, []);

  const columns = [
    { field: "ticket_number", headerName: "Ticket Number", width: 150 },
    { field: "created_on", headerName: "Request Date", width: 300 },
    { field: "created_by", headerName: "Requested By", width: 150 },
    {
      field: "workflow_status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => <TicketsStatus {...{ params }} />,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 200,
      renderCell: (params) => <TicketsActions {...{ params }} />,
    },
  ];

  const rows = user.tickets;
  const metrics = dashboard.shareholder_status;

  const ticketsTableStyles = {
    height: "400px",
    marginBottom: "20px",
    backgroundColor: "white",
  };
  const data = [
    ["Task", ""],
    ["Company", metrics?.company_shareholders],
    ["SCD", metrics?.scd_shareholders],
  ];

  const options = {
    title: "Shareholder status",
  };
  const ImmobilizationData = [
    ["Status", "Total", "Immobilized"],
    ["Immobilized", metrics?.total_shareholders, metrics?.scd_shareholders],
  ];

  const ImmobilizationOptions = {
    title: "Imobilization status",
    chartArea: { width: "65%" },
    colors: ["#0033A1", "#052A6C"],
    hAxis: {
      title: "Total Shareholders",
      minValue: 0,
    },
    vAxis: {
      title: "Registration No",
    },
  };
  const ResidencyData = [
    ["Residency", "Number", { role: "style" }],
    ["Ugandan Residency", metrics?.resident_shareholders, "#AA0050"],
    ["Foreigners", metrics?.foreign_shareholders, "#B150C5"],
  ];

  const ResidencyOptions = {
    title: "Residency status",
    chartArea: { width: "65%" },
    colors: ["#0033A1", "#052A6C"],
    hAxis: {
      title: "Total Shareholders",
      minValue: 0,
    },
    vAxis: {
      title: "Registration No",
    },
  };


  return (
    <MiniDrawer>
      {alert.open === true ? (
        <AlertModalTest
          sx={{ margin: "20px", width: "50%", align: "right" }}
        ></AlertModalTest>
      ) : (
        <></>
      )}
      <Grid container spacing={2}>
           <Grid item md={3}>
            <ShareholderCard
              icon={<GroupsIcon fontSize="large" sx={{ color: "#01422A" }} />}
              shareholderCount={
                dashboard?.shareholder_status?.total_shareholders
              }
              name={"Shareholders"}
              path={"shareholders"}
            />
          </Grid>
          <Grid item md={3}>
            <ShareholderCard
              icon={<PeopleIcon fontSize="large" sx={{ color: "#FF9E0C" }} />}
              shareholderCount={dashboard?.shareholder_status?.scd_shareholders}
              name={"SCD Shareholders"}
            />
          </Grid>
          <Grid item md={3}>
            <ShareholderCard
              icon={
                <ApartmentIcon fontSize="large" sx={{ color: "#10C682" }} />
              }
              shareholderCount={
                dashboard?.shareholder_status?.company_shareholders
              }
              name={"Certificate Shareholders"}
            />
          </Grid>
          <Grid item md={3}>
            <ShareholderCard
              icon={<GroupsIcon fontSize="large" sx={{ color: "#D37B13" }} />}
              shareholderCount={
                dashboard?.shareholder_status?.foreign_shareholders
              }
              name={"Foreigner Shareholders"}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Card sx={{ height: "250px", marginTop: "2%" }}>
              <CardContent>
                <Chart
                  chartType="BarChart"
                  data={ImmobilizationData}
                  options={ImmobilizationOptions}
                  width={"100%"}
                  height={"200px"}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={6}>
            <Card sx={{ height: "250px", marginTop: "2%" }}>
              <CardContent>
                <Chart
                  chartType="ColumnChart"
                  data={ResidencyData}
                  width={"100%"}
                  height={"200px"}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginTop: "20px" }}>
          <Grid item md={3}>
            <TicketsCard
              name={"Closed Tickets"}
              shareholderCount={dashboard?.ticket_status?.closed}
              chip={
                !dashboard?.ticket_status?.hasOwnProperty(
                  "closed_percentage"
                ) ? (
                  <Chip
                    label={0 + "%"}
                    sx={{ color: "#D0021B", backgroundColor: "#FEA2A2" }}
                  />
                ) : (
                  <Chip
                    label={dashboard?.ticket_status?.closed_percentage + "%"}
                    sx={{ color: "#D0021B", backgroundColor: "#FEA2A2" }}
                  />
                )
              }
            >
              {dashboard?.ticket_status?.closed_percentage}
            </TicketsCard>
            <TicketsCard
              shareholderCount={dashboard?.ticket_status?.pending}
              chip={
                !dashboard?.ticket_status?.hasOwnProperty(
                  "pending_percentage"
                ) ? (
                  <Chip
                    label={0 + "%"}
                    sx={{ color: "#00AD6C", backgroundColor: "#ACE4CF" }}
                  />
                ) : (
                  <Chip
                    label={dashboard?.ticket_status?.pending_percentage + "%"}
                    sx={{ color: "#00AD6C", backgroundColor: "#ACE4CF" }}
                  />
                )
              }
              name={"Pending Tickets"}
            />
          </Grid>
          <Grid item md={8.9}>
            <Card sx={{ marginTop: "20px" }}>
              <CardHeader
                title={
                  <Typography
                    sx={{
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "20px",
                      color: " #0A2240",
                      textAlign: "center",
                    }}
                  >
                    Shareholder Status
                  </Typography>
                }
              />
              <CardContent>
                <Chart
                  chartType="PieChart"
                  data={data}
                  options={options}
                  width={"100%"}
                  height={"400px"}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={0.1}></Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginTop: "20px" }}>
          <Grid item md={12}>
            <Card>
              <CardHeader
                title={
                  <Typography
                    sx={{
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "20px",
                      color: " #0A2240",
                    }}
                  >
                    Recent Tickets
                  </Typography>
                }
              />
              <CardContent>
                <ListTicketsTable />
              </CardContent>
            </Card>
          </Grid>
        </Grid>      
    </MiniDrawer>
  )
};
export default AdministratorDashboard;
