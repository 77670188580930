import { Grid, Typography } from '@mui/material';
import React from 'react';

function UpdateTicket({
  ticket_number = 'N/A',
  shareholder_name = 'N/A',
  primary_phone_number = 'N/A',
  primary_email = 'N/A',
  primary_address = 'N/A',
  tin = 'N/A',
  shareholder_com_number = 'N/A',
  shareholder_scd_number = 'N/A',
  broker_code = 'N/A',
  payment_type = 'N/A',
  payment_mode_name = 'N/A',
  description = 'N/A',
  user_comment = 'N/A',
  country_name = 'N/A',
  tax_rate = 'N/A',
}) {
  const ticketDetailsStyles = {
    marginBottom: '10px',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    color: '#000000',
  };

  return (
    <Grid container spacing={2} sx={{ marginBottom: '40px' }}>
      <Grid item lg={6}>
        <Typography sx={ticketDetailsStyles}>Ticket Number</Typography>
        <Typography sx={ticketDetailsStyles}>Shareholder Name</Typography>
        <Typography sx={ticketDetailsStyles}>Email</Typography>
        <Typography sx={ticketDetailsStyles}>Phone</Typography>
        <Typography sx={ticketDetailsStyles}>Address</Typography>
        <Typography sx={ticketDetailsStyles}>Comment</Typography>
        <Typography sx={ticketDetailsStyles}>Tin</Typography>
        <Typography sx={ticketDetailsStyles}>Shareholder COM Number</Typography>
        <Typography sx={ticketDetailsStyles}>Shareholder SCD Number</Typography>
        <Typography sx={ticketDetailsStyles}>Broker Code</Typography>
        <Typography sx={ticketDetailsStyles}>Payment Mode</Typography>
        <Typography sx={ticketDetailsStyles}>Payment Name</Typography>
        <Typography sx={ticketDetailsStyles}>Tax Category</Typography>
        <Typography sx={ticketDetailsStyles}>Tax Country</Typography>
        <Typography sx={ticketDetailsStyles}>Tax Rate</Typography>
      </Grid>
      <Grid item lg={6}>
        <Typography sx={ticketDetailsStyles}>{ticket_number}</Typography>
        <Typography sx={ticketDetailsStyles}>{shareholder_name}</Typography>
        <Typography sx={ticketDetailsStyles}>{primary_email}</Typography>
        <Typography sx={ticketDetailsStyles}>{primary_phone_number}</Typography>
        <Typography sx={ticketDetailsStyles}>{primary_address}</Typography>
        <Typography sx={ticketDetailsStyles}>{user_comment}</Typography>
        <Typography sx={ticketDetailsStyles}>{tin}</Typography>
        <Typography sx={ticketDetailsStyles}>
          {shareholder_com_number}
        </Typography>
        <Typography sx={ticketDetailsStyles}>
          {shareholder_scd_number}
        </Typography>
        <Typography sx={ticketDetailsStyles}>{broker_code}</Typography>
        <Typography sx={ticketDetailsStyles}>{payment_type}</Typography>
        <Typography sx={ticketDetailsStyles}>{payment_mode_name}</Typography>
        <Typography sx={ticketDetailsStyles}>{description}</Typography>
        <Typography sx={ticketDetailsStyles}>{country_name}</Typography>
        <Typography sx={ticketDetailsStyles}>{`${tax_rate} %`}</Typography>
      </Grid>
    </Grid>
  );
}

export default UpdateTicket;
