// import { TabPanel } from "@mui/lab";
import { Avatar, Box, Card, CardContent, CardMedia, Divider, Grid, Skeleton, Tab, Table, TableBody, TableHead, TableRow, Tabs, Typography } from "@mui/material";
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Fragment } from "react";
import { useParams } from "react-router-dom";
import shareholderBanner from '../assets/images/shareholderBanner.jpg';
import { getShareholderHistory } from "../service/userService";



function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const ShareholderHistoryCard = () => {
    const [value, setValue] = useState(0);
    const [revisions, setRevisions] = useState();
    const dispatch = useDispatch();
    const locale = useParams();
    const shareholder = useSelector(state => state.user.dynamicUser);
    let accountOfficers = [];


    useEffect(() => {
      getShareholderHistory({shareholderCode: locale.shareholder_code,size:5,pageIndex:0}).then(response => {
        // console.log('Revisions response: ', response);
        setRevisions(response.data.data);
      })
    }, [shareholder])


    const handleChange = (event, newValue) => {
        setValue(newValue);
    }
    if (!revisions) {
        return(
            <Skeleton variant="rectangular" width="100%" height="100%"></Skeleton>
          );
    }
    return(
        <>
            <Card>
                <CardMedia
                    component='img'
                    height='180'
                    image={shareholderBanner}
                    alt='Shareholder Banner'></CardMedia>
                <CardContent>
                    <Avatar></Avatar>
                    <Typography>{shareholder.shareholder_name}</Typography>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange}>
                            <Tab label='Personal' {...a11yProps(0)} />
                            <Tab label='Payment Account' {...a11yProps(1)}/>
                            <Tab label='Shares' {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <Table>
                            <TableBody>
                                {revisions?.map(revision =>
                                    (
                                        <Fragment key={revision.required_revision_number}>
                                        <TableRow>
                                            <Grid container>
                                                <Grid item xs={12} md={4} sx={{paddingBottom: 2}}>
                                                    <Typography fontSize='small'><strong>Action type:</strong></Typography>
                                                    <Typography color='GrayText' fontSize='small' >{revision.revision_type}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={4} sx={{paddingBottom: 2}}></Grid>
                                                <Grid item xs={12} md={4} sx={{paddingBottom: 2}}>
                                                    <Typography fontSize='small'><strong>Date:</strong></Typography>
                                                    <Typography color='GrayText' fontSize='small' >{revision.required_revision_instant}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={8} sx={{paddingBottom: 2}}>
                                                    <Typography fontSize='small'><strong>Shareholder name:</strong></Typography>
                                                    <Typography color='GrayText' fontSize='small' >{revision.entity.shareholder_name}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={4} sx={{paddingBottom: 2}}>
                                                    <Typography fontSize='small'><strong>Country:</strong></Typography>
                                                    {revision.entity.country_tax_rate === null ? 
                                                    (<></>):(<Typography color='GrayText' fontSize='small' >{revision.entity.country_tax_rate.country_name}</Typography>) }
                                                </Grid>
                                                <Grid item xs={12} md={4} sx={{paddingBottom: 2}}>
                                                    <Typography fontSize='small'><strong>Phone Number:</strong></Typography>
                                                    <Typography color='GrayText' fontSize='small' >{revision.entity.primary_phone_number}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={4} sx={{paddingBottom: 2}}>
                                                    <Typography fontSize='small'><strong>Email:</strong></Typography>
                                                    <Typography color='GrayText' fontSize='small' >{revision.entity.primary_email}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={4} sx={{paddingBottom: 2}}>
                                                    <Typography fontSize='small'><strong>Address:</strong></Typography>
                                                    <Typography color='GrayText' fontSize='small' >{revision.entity.primary_address}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={4} sx={{paddingBottom: 2}}>
                                                    <Typography fontSize='small'><strong>Secondary Phone Number:</strong></Typography>
                                                    <Typography color='GrayText' fontSize='small' >{revision.entity.secondary_phone_number}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={4} sx={{paddingBottom: 2}}>
                                                    <Typography fontSize='small'><strong>Secondary Email:</strong></Typography>
                                                    <Typography color='GrayText' fontSize='small' >{revision.entity.primary_email}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={4} sx={{paddingBottom: 2}}>
                                                    <Typography fontSize='small'><strong>Secondary Address:</strong></Typography>
                                                    <Typography color='GrayText' fontSize='small' >{revision.entity.primary_address}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={4} sx={{paddingBottom: 2}}>
                                                    <Typography fontSize='small'><strong>Tax Identification Number (TIN):</strong></Typography>
                                                    <Typography color='GrayText' fontSize='small' >{revision.entity.tin}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={4} sx={{paddingBottom: 2}}>
                                                    <Typography fontSize='small'><strong>Shareholder COM Number:</strong></Typography>
                                                    <Typography color='GrayText' fontSize='small' >{revision.entity.shareholder_code}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={4} sx={{paddingBottom: 2}}>
                                                    <Typography fontSize='small'><strong>Shareholder SCD Number:</strong></Typography>
                                                    <Typography color='GrayText' fontSize='small' >{revision.entity.shareholder_scd_number}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={4} sx={{paddingBottom: 2}}>
                                                    <Typography fontSize='small'><strong>Shareholder Type:</strong></Typography>
                                                    <Typography color='GrayText' fontSize='small' >{revision.entity.is_company}</Typography>
                                                </Grid>
                                            </Grid>
                                        </TableRow>
                                        <Divider sx={{padding: 2}}></Divider>
                                        </Fragment>
                                    ))}
                            </TableBody>
                        </Table>
                    </TabPanel>
                    <TabPanel value={value} index={1}>                            
                        <Table>
                                <TableBody>
                                {revisions?.map(revision =>
                                    (
                                        <Fragment key={revision.required_revision_number}>
                                        <TableRow>
                                            <Grid container>
                                                <Grid item xs={12} md={4} sx={{paddingBottom: 2}}>
                                                    <Typography fontSize='small'><strong>Action type:</strong></Typography>
                                                    <Typography color='GrayText' fontSize='small' >{revision.revision_type}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={4} sx={{paddingBottom: 2}}></Grid>
                                                <Grid item xs={12} md={4} sx={{paddingBottom: 2}}>
                                                    <Typography fontSize='small'><strong>Date:</strong></Typography>
                                                    <Typography color='GrayText' fontSize='small' >{revision.required_revision_instant}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={8} sx={{paddingBottom: 2}}>
                                                    <Typography fontSize='small'><strong>Preferred Payment Channel:</strong></Typography>
                                                    <Typography color='GrayText' fontSize='small' >{revision.entity.shareholder_name}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={4} sx={{paddingBottom: 2}}>
                                                    <Typography fontSize='small'><strong>Payment Bank Name:</strong></Typography>
                                                    {revision.entity.country_tax_rate === null ? 
                                                    (<></>):(<Typography color='GrayText' fontSize='small' >{revision.entity.country_tax_rate.country_name}</Typography>) }
                                                </Grid>
                                                <Grid item xs={12} md={4} sx={{paddingBottom: 2}}>
                                                    <Typography fontSize='small'><strong>Payment Account Name:</strong></Typography>
                                                    <Typography color='GrayText' fontSize='small' >{revision.entity.primary_phone_number}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={4} sx={{paddingBottom: 2}}>
                                                    <Typography fontSize='small'><strong>Payment Account Number:</strong></Typography>
                                                    <Typography color='GrayText' fontSize='small' >{revision.entity.primary_email}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={4} sx={{paddingBottom: 2}}>
                                                    <Typography fontSize='small'><strong>Tax Rate Code:</strong></Typography>
                                                    {revision.entity.country_tax_rate === null ? 
                                                    (<></>):(<Typography color='GrayText' fontSize='small' >{revision.entity.country_tax_rate.country_name}</Typography>) }
                                                </Grid>
                                                <Grid item xs={12} md={4} sx={{paddingBottom: 2}}>
                                                    <Typography fontSize='small'><strong>ISO Code:</strong></Typography>
                                                    {revision.entity.country_tax_rate === null ? 
                                                    (<></>):(<Typography color='GrayText' fontSize='small' >{revision.entity.country_tax_rate.iso_code}</Typography>) }                                                    
                                                </Grid>
                                                <Grid item xs={12} md={4} sx={{paddingBottom: 2}}>
                                                    <Typography fontSize='small'><strong>Country code:</strong></Typography>
                                                    {revision.entity.country_tax_rate === null ? 
                                                    (<></>):(<Typography color='GrayText' fontSize='small' >{revision.entity.country_tax_rate.country_name}</Typography>) }
                                                </Grid>
                                                <Grid item xs={12} md={4} sx={{paddingBottom: 2}}>
                                                    <Typography fontSize='small'><strong>Country Name:</strong></Typography>
                                                    {revision.entity.country_tax_rate === null ? 
                                                    (<></>):(<Typography color='GrayText' fontSize='small' >{revision.entity.country_tax_rate.country_name}</Typography>) }
                                                </Grid>
                                                <Grid item xs={12} md={4} sx={{paddingBottom: 2}}>
                                                    <Typography fontSize='small'><strong>Tax Rate:</strong></Typography>
                                                    {revision.entity.country_tax_rate === null ? 
                                                    (<></>):(<Typography color='GrayText' fontSize='small' >{revision.entity.country_tax_rate.tax_rate}</Typography>) }
                                                    
                                                </Grid>
                                                <Grid item xs={12} md={4} sx={{paddingBottom: 2}}>
                                                    <Typography fontSize='small'><strong>Tax Description:</strong></Typography>
                                                    {revision.entity.country_tax_rate === null ? 
                                                    (<></>):(<Typography color='GrayText' fontSize='small' >{revision.entity.country_tax_rate.description}</Typography>) }
                                                    
                                                </Grid>
                                            </Grid>
                                        </TableRow>
                                        <Divider sx={{padding: 2}}></Divider>
                                        </Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                    </TabPanel>
                    <TabPanel value={value} index={2}>                            
                        <Table>
                            <TableBody>
                                {revisions?.map(revision =>
                                    (
                                        <Fragment key={revision.required_revision_number}>
                                        <TableRow>
                                            <Grid container>
                                                <Grid item xs={12} md={4} sx={{paddingBottom: 2}}>
                                                    <Typography fontSize='small'><strong>Action type:</strong></Typography>
                                                    <Typography color='GrayText' fontSize='small' >{revision.revision_type}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={4} sx={{paddingBottom: 2}}></Grid>
                                                <Grid item xs={12} md={4} sx={{paddingBottom: 2}}>
                                                    <Typography fontSize='small'><strong>Date:</strong></Typography>
                                                    <Typography color='GrayText' fontSize='small' >{revision.required_revision_instant}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={8} sx={{paddingBottom: 2}}>
                                                    <Typography fontSize='small'><strong>Shares:</strong></Typography>
                                                    <Typography color='GrayText' fontSize='small' >{revision.entity.shares_held}</Typography>
                                                </Grid>
                                            </Grid>
                                        </TableRow>
                                        <Divider sx={{padding: 2}}></Divider>
                                        </Fragment>
                                    ))}
                            </TableBody>
                        </Table>
                    </TabPanel>
                    <TabPanel value={value} index={3}>                           
                        <Table>
                            <TableHead>
                                <TableRow>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow></TableRow>
                            </TableBody>
                        </Table>
                    </TabPanel>
                </CardContent>
            </Card>
        </>
    )

}
export default ShareholderHistoryCard;