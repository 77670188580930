// import { TabPanel } from "@mui/lab";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Grid,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tabs,
    Typography
} from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import shareholderBanner from "../assets/images/shareholderBanner.jpg";
import { getAccountOfficerById } from "../service/userService";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AccountOfficerDetailsCard = () => {
  const [value, setValue] = useState(0);
  const [accountOfficer, setAccountOfficer] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locale = useParams();

  useEffect(() => {
    getAccountOfficerById(locale.officer_id).then((response) => {
      setAccountOfficer(response);
    });
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleEdit = () => {
    navigate("/edit-account-officer");
  };
  if (!accountOfficer) return "loading";
  return (
    <>
      <Card>
        <CardMedia
          component="img"
          height="180"
          image={shareholderBanner}
          alt="Shareholder Banner"
        ></CardMedia>
        <CardContent>
          <Avatar></Avatar>
          <Typography>
            {accountOfficer.first_name + " " + accountOfficer.last_name}
          </Typography>
          <Typography fontSize="small">{accountOfficer.username}</Typography>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Personal" {...a11yProps(0)} />
              <Tab label="Security" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                        sx={{ paddingBottom: 2 }}
                      >
                        <Typography fontSize="small">
                          <strong>Shareholder Name:</strong>
                        </Typography>
                        <Typography color="GrayText" fontSize="small">
                          {accountOfficer.shareholder.shareholder_name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6} sx={{ paddingBottom: 2 }}>
                        <Typography fontSize="small">
                          <strong>Email:</strong>
                        </Typography>
                        <Typography color="GrayText" fontSize="small">
                          {accountOfficer.primary_email}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6} sx={{ paddingBottom: 2 }}>
                        <Typography fontSize="small">
                          <strong>Phone Number:</strong>
                        </Typography>
                        <Typography color="GrayText" fontSize="small">
                          {accountOfficer.primary_phone_number}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6} sx={{ paddingBottom: 2 }}>
                        <Typography fontSize="small">
                          <strong>Company ID:</strong>
                        </Typography>
                        <Typography color="GrayText" fontSize="small">
                          {accountOfficer.officer_code}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      onClick={() => {
                        handleEdit();
                      }}
                      disabled={!sessionStorage.getItem("can_update")}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <></>
          </TabPanel>
        </CardContent>
      </Card>
    </>
  );
};
export default AccountOfficerDetailsCard;
