import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import './Footer.css';
import Icon from '@mdi/react';
import {
  mdiEmailOutline,
  mdiPhone,
  mdiMapMarker,
  mdiFacebook,
  mdiTwitter,
  mdiInstagram,
  mdiYoutube,
} from '@mdi/js';
import logo from '../assets/images/logo.png';

const Footer = () => {
  return (
    <div>
      <MDBFooter
        //bgColor="light"
        className="text-center text-lg-start text-muted"
        style={{ backgroundColor: '#0A2240' }}
      >
        <section className="">
          <MDBContainer className="text-center text-md-start mt-5">
            <MDBRow className="mt-3">
              <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4">
                <h6 className="footer-title ">
                  <img
                    src={logo}
                    width="50px"
                    height="60px"
                    alt="Stanbic logo"
                    // className="d-inline-block align-top"
                  />
                  SUHL Dividend Payment Solution
                </h6>
                <p className="footer-text ">
                  The Dividend Payment Platform is here to help our Shareholders
                  manage and be in control of their Shares and personal
                  information at their confort
                </p>
                <Icon
                  className="me-2"
                  path={mdiFacebook}
                  title="User Profile"
                  size="20px"
                  color="#FFFFFF"
                />
                <Icon
                  className="me-2"
                  path={mdiTwitter}
                  title="User Profile"
                  size="20px"
                  color="#FFFFFF"
                />
                <Icon
                  className="me-2"
                  path={mdiInstagram}
                  title="User Profile"
                  size="20px"
                  color="#FFFFFF"
                />
                <Icon
                  className="me-2"
                  path={mdiYoutube}
                  title="User Profile"
                  size="20px"
                  color="#FFFFFF"
                />
              </MDBCol>

              <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
                <h6 className="footer-title">Support</h6>
                <p>
                  <a href="#!" className="footer-text ">
                    How to Claim Unpaid Dividends
                  </a>
                </p>
                <p>
                  <a href="#!" className="footer-text ">
                    SHUL News
                  </a>
                </p>
                <p>
                  <a href="#!" className="footer-text ">
                    FAQs
                  </a>
                </p>
                <p>
                  <a href="#!" className="footer-text ">
                    Contact Agent
                  </a>
                </p>
              </MDBCol>

              <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-4">
                <h6 className="footer-title">Solutions</h6>
                <p>
                  <a href="#!" className="footer-text ">
                    Profile
                  </a>
                </p>
                <p>
                  <a href="#!" className="footer-text ">
                    Payouts
                  </a>
                </p>
                <p>
                  <a href="#!" className="footer-text ">
                    Shares
                  </a>
                </p>
                <p>
                  <a href="#!" className="footer-text ">
                    Notifications
                  </a>
                </p>
                <p>
                  <a href="#!" className="footer-text ">
                    Password
                  </a>
                </p>
              </MDBCol>

              <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
                <h6 className="footer-title">Contact Us</h6>
                <p className="footer-text ">
                  <Icon
                    className="me-2"
                    path={mdiPhone}
                    title="User Profile"
                    size="20px"
                    color="#FFFFFF"
                  />
                  +256-757 072 773 /760 451 945 /414 237 504
                </p>
                <p>
                  <Icon
                    className="me-2"
                    path={mdiEmailOutline}
                    title="User Profile"
                    size="20px"
                    color="#FFFFFF"
                  />
                  <a href="#!" className="footer-text ">
                    stanbicholdingsltd@gmail.com
                  </a>
                </p>
                <h6 className="footer-title">Find Us</h6>
                <p className="footer-text ">
                  <Icon
                    className="me-2"
                    path={mdiMapMarker}
                    title="User Profile"
                    size="20px"
                    color="#FFFFFF"
                  />
                  Crested Towers 13th Floor, Short Tower
                </p>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>
      </MDBFooter>
    </div>
  );
};

export default Footer;
