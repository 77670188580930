import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink as Link } from "react-router-dom";
import useStyles from "./sideDrawerListItem.module";
import { useSelector } from "react-redux";

function SideDrawerListItemSelect({
  listText = "List Item",
  to = "/",
  children,
  onClick = () => null,
  menu = null, // Add a menu prop
}) {
  const classes = useStyles();
  const myActive = useSelector(state => state.dividend.accountingActive)
  return (
    <Link to={to} className={classes.links}>
      {({ isActive }) => (
        <ListItemButton
          className={
            myActive !== true ? classes.listItemButton : classes.listItemActiveButton
          }
          onClick={onClick}
          sx={{ color: "#ffffff" }}
        >
          <ListItemIcon className="list">{children}</ListItemIcon>
          <ListItemText className="list listText">
            <Typography variant="p" className={classes.listItemText}>
              {listText}
            </Typography>
          </ListItemText>
          {menu && ( // Render the menu if it's provided
            <Menu
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              anchorEl={menu.anchorEl}
              open={menu.isOpen}
              onClose={menu.onClose}
            >
              {menu.items.map((item, index) => (
                <MenuItem key={index}>
                  {item.label}
                </MenuItem>
              ))}
            </Menu>
          )}
        </ListItemButton>
      )}
    </Link>
  );
}

export default SideDrawerListItemSelect;
