import { FormControl, Grid, InputBase, alpha, styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const DataTable = ({ rows, columns, loading, getRowId, sx }) => {
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setCurrentPage(1);
  };

  // Filter rows based on the search query
  const filteredRows = Array.isArray(rows)
    ? rows.filter((row) =>
        Object.values(row).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchQuery.toLowerCase())
        )
      )
    : [];

  return (
    <div>
      {/* Search input field */}

      <Grid container>
        <Grid md={8} lg={5.7}></Grid>
        <Grid md={4} lg={6.3}>
          <FormControl variant="standard" sx={{ width: "100%" }}>
            <BootstrapInput
              id="searchTxt"
              size="small"
              type="text"
              placeholder="Search"
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{ backgroundColor: "#FFFFFF" }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <DataGrid
        rows={filteredRows}
        columns={columns}
        getRowId={getRowId}
        sx={sx}
        pagination
        pageSize={pageSize}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        rowsPerPageOptions={[2, 5, 10]}
        page={currentPage}
        autoHeight={true}
        autoPageSize={true}
      />
    </div>
  );
};

export default DataTable;
