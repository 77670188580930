import { useContext } from 'react';
import NavigationContext from '../../store/NavigationContext'
import ListAdministrators from '../../components/tables/ListAdministratorsTable';
import MiniDrawer from '../../components/newSideDrawer/miniSideDrawer';


function ListAdministratorsPage() {
  const navigationContext = useContext(NavigationContext)
  const { sidebar } = navigationContext
  
  return (
    <MiniDrawer >
      <ListAdministrators />
    </MiniDrawer>
  )
}

export default ListAdministratorsPage;