import { useContext } from 'react';
import { Box, Grid } from '@mui/material';
import NavigationContext from '../../store/NavigationContext';
import PersistentDrawerLeft, { DrawerHeader, Main } from '../../components/navigation/sideDrawer.js';
import ShareholderDetailsCard from '../../components/shareholderDetailsCard';
import ActivityTracker from '../../components/ActivityTracker';
import ShareholderEditCard from '../../components/AdministratorShareholderEditCard';
import MiniDrawer from '../../components/newSideDrawer/miniSideDrawer';


function ShareholderEditPage() {
  const navigationContext = useContext(NavigationContext)
  const { sidebar } = navigationContext
  return (
    <MiniDrawer>
      <ShareholderEditCard />
    </MiniDrawer>
  )
}

export default ShareholderEditPage;