import './LoginPage.css';
import { Box, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import LoginCard from '../components/LoginCard';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import AlertModalTest from '../components/alertModalTest';

const LoginPage = () => {
  const alert = useSelector ((state) => state.alert);
  useEffect(() => {
    sessionStorage.setItem('current_location', '/');
  },[])
  return (
    <Grid 
      container 
      justifyContent="center" 
      sx={{
        minHeight: '100vh',
        backgroundImage: 'linear-gradient(#0033A1, #000029)',
        }}>
      <Grid
        item
        alignSelf='center'
        sx={{
            width:{
                lg: '30%',
                md: '40%',
                sm: '50%',
                xs: '60%'
            },
        }}
      >
        {alert.open === true ? (
          <AlertModalTest
            sx={{ margin: "20px", width: "50%", align: "right" }}
          ></AlertModalTest>
        ) : (
          <></>
        )}
        <LoginCard />
      </Grid>
    </Grid>
  );
};

export default LoginPage;
