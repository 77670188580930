import { createAsyncThunk } from "@reduxjs/toolkit";
import { API,  testApi, unprotectedApi } from "../config/API";
import jwtDecode from "jwt-decode";

export const getFunctionalGroups = createAsyncThunk(
    "/create-administrator",
    async () => {
      try {
        const resp = await API.get(
          `security/functional-groups?size=20&page=0&direction=asc&field_name=functional_group_code`
        );
        return [...resp.data.data];
      } catch (error) {
        return error.message;
      }
    }
  );

  
  export const getModuleAuthorities = createAsyncThunk(
    "/create-module-authority",
    async () => {
      try {
        const resp = await API.get(
          `security/listmodules?size=20&page=0&direction=asc&field_name=module_code`
        );
        return [...resp.data.data];
      } catch (error) {
        return error.message;
      }
    }
  );
  export const getAllFunctionalGroups = (props) => {
    return testApi.get(`security/functional-groups?size=${props.size}&page=${props.pageIndex}&direction=asc&field_name=functional_group_code`);
  }
  export const getAllModules = (props) => {
    return testApi.get(`security/listmodules?size=${props.size}&page=${props.pageIndex}&direction=asc&field_name=module_code`);
  }
  export const addModuleToAdmin = (props) => {
    return testApi.put(`security/add-mod-admin/${props.id}`, props.modAuth);
  }
  export const addFGToAdmin = (props) => {
    return testApi.put(`security/add-fg-admin/${props.id}`, props.fgs);
  }
  export const passOtp = async (props) => {
    return testApi.post(`security/mfa/req-token`, props)
  }
  export const setGoogleAuthenticator = async (props) => {
    return testApi.post(`security/mfa/setup`, props)
  }

  export const checkAuthorities = (props) => {
    sessionStorage.setItem('can_create', false)
    sessionStorage.setItem('can_read', false)
    sessionStorage.setItem('can_update', false)
    sessionStorage.setItem('can_delete', false)
    sessionStorage.setItem('can_export', false)
    sessionStorage.setItem('can_approve', false)
    if (!sessionStorage.getItem('token')) {
    } else {
        const decodedToken = jwtDecode(sessionStorage.getItem('token'));
        let authorities = decodedToken.authorities;
          if (authorities.some(e => e.role === props.can_create)){
            sessionStorage.setItem('can_create', true);      
          }   
          if (authorities.some(e => e.role === props.can_read)){
            sessionStorage.setItem('can_read', true);      
          }   
          if (authorities.some(e => e.role === props.can_update)){
            sessionStorage.setItem('can_update', true);      
          }   
          if (authorities.some(e => e.role === props.can_delete)){
            sessionStorage.setItem('can_delete', true);      
          } 
          if (authorities.some(e => e.role === props.can_export)){
            sessionStorage.setItem('can_export', true);      
          }   
          if (authorities.some(e => e.role === props.can_approve)){
            sessionStorage.setItem('can_approve', true);      
          }
      }
  }
  export const checkModuleRights = (props) => {
    sessionStorage.setItem('can_create', false)
    sessionStorage.setItem('can_read', false)
    sessionStorage.setItem('can_update', false)
    sessionStorage.setItem('can_delete', false)
    sessionStorage.setItem('can_export', false)
    sessionStorage.setItem('can_approve', false)
    if (!sessionStorage.getItem('token')) {
    } else {
      const decodedToken = jwtDecode(sessionStorage.getItem('token'));
    }
    
  }