import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PieChartSharpIcon from '@mui/icons-material/PieChartSharp';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Tab, Typography, styled } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import lodash from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ActivityCard from '../../components/activityCard/ActivityCard';
import officerService from '../../service/officer/officerService';
import shareholderService from '../../service/officer/shareholderService';
import ShareholderWrapper from './ShareholderWrapper';

const useStyles = makeStyles((theme) => {
  return {
    tabContainer: {
      display: 'flex',
      flexDirection: 'column',
      margin: '0 40px',
    },
    activityContainer: {
      display: 'flex',
      alignItems: 'space-between',
      flexDirection: 'column',
    },
    detailsMajorContainer: {
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      position: 'relative',
    },
    detailsMainContainer: {
      backgroundColor: '#FFFFFF',
      display: 'flex',
      minHeight: '80%',
      flexDirection: 'column',
      '& .minorContainer': {
        display: 'flex',
        flexDirection: 'row',
        margin: '0 40px',
        alignItems: 'center',
      },
    },
    userIconContainer: {
      backgroundColor: '#0033A1',
      borderRadius: '50%',
      position: 'absolute',
      bottom: -40,
      left: 40,
    },
    detailsGrid: {
      '& .gridList': {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 0.5,
        marginBottom: 2,
        '& .title': {
          color: '#000',
          fontWeight: 'bold',
          fontSize: '12px',
        },
        '& .value': {
          color: '#444A4A',
          fontWeight: 300,
          fontSize: '16px',
        },
      },
    },
  };
});

const otherStyles = {
  tabStyles: {
    color: '#5F6868',
    textTransform: 'capitalize',
    
    fontSize: {
      xs: 8,
      sm: 12,
      md: 16,
    },
    fontWeight: {
      sm: '300',
      md: '500',
    },
    marginRight: {
      lg: 10,
      sm: 0,
      xs: 0,
    },
  },
  icon: {
    fontSize: {
      lg: '100px',
      md: '80px',
      sm: '60px',
      xs: '60px',
    },
    color: 'white',
  },
  tabListDividerLine: {
    borderBottom: '1.4px solid #90969DED',
  },
};

const ShareholderLandingPage = () => {
  const { tabStyles, icon, tabListDividerLine } = otherStyles;
  const classes = useStyles();
  const [revisions, setRevisions] = useState([]);
  const [searchParams] = useSearchParams();

  const [shareholderData, setShareholderData] = useState({});
  const [shareholder, setShareholder] = useState({});
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [officerData, setOfficerData] = useState({});

  const EditButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.light,
    height: 35,
    width: 100,
  }));

  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = React.useState('1');

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    findOfficerDetailsByOfficerCode();
    findShareholderRevision();
    findShareholderDetailsByShareholderCode();
  }, []);

  /**
   * @description Get shareholder details by the shareholder code
   */
  const findShareholderRevision = async () => {
    const response = await shareholderService.fetchShareholderRevisions({
      shareholderCode:
        searchParams.get('shareholder_code') ??
        sessionStorage.getItem('shareholder_code'),
    });
    const { data } = response?.data;
    const formattedRevisions = data?.map((revision) => ({
      revision_type:
        revision?.revision_type === 'INSERT'
          ? 'CREATED'
          : revision?.revision_type,
      user_comment: revision?.entity?.user_comment,
      required_revision_instant: revision?.required_revision_instant,
      required_revision_number: revision?.required_revision_number,
      shareholder_initials: getFirstAndSecondCharacters(
        revision?.entity?.shareholder_name
      ),
    }));
    setRevisions(formattedRevisions);
  };

  /**
   * @description get the first characters of first and second word
   */
  const getFirstAndSecondCharacters = (sentence = 'No Name') => {
    const wordList = sentence.split(' ');
    return wordList.length > 1
      ? wordList[0][0] + wordList[1][0]
      : wordList[0][0];
  };

  /**
   * @description Get shareholder officer details by officer id
   */
  const findOfficerDetailsByOfficerCode = async () => {
    const response = await officerService.findOfficerByCode({
      officerCode:
        searchParams.get('officer_code') ??
        sessionStorage.getItem('officer_code'),
    });
    setOfficerData(response?.data?.data);
  };

  /**
   * @description Get shareholder details by the shareholder code
   */
  const findShareholderDetailsByShareholderCode = async () => {
    const response = await shareholderService.findShareholderDetails({
      shareholderCode:
        searchParams.get('shareholder_code') ??
        sessionStorage.getItem('shareholder_code'),
    });

    const paymentAndMainShareholderData = {
      ...response?.data?.data?.payment_mode,
      ...response?.data?.data?.country_tax_rate,
    };

    setShareholderData({
      shareholder_code: response?.data?.data?.shareholder_code,
      shareholder_name: response?.data?.data?.shareholder_name,
      shares_held: response?.data?.data?.shares_held,
    });

    const formattedPaymentData = dynamicDataFormatter(
      paymentAndMainShareholderData
    );
    setPaymentDetails(formattedPaymentData);

    const currentData = { ...response?.data?.data };
    setShareholder(currentData);
  };

  const dynamicDataFormatter = (data) => {
    const regex = /_/gi;
    const objectToArrayResults = Object.entries(data);
    return objectToArrayResults.map((result) => {
      return {
        key: lodash.startCase(result[0].replaceAll(regex, ' ')),
        value: result[0].startsWith('is_')
          ? result[1] === true
            ? 'Yes'
            : 'No'
          : result[1],
      };
    });
  };

  const GridListComponent = ({
    title = 'No title',
    value = 'No Value given',
  }) => (
    <Grid
      item
      xs={6}
      className="gridList"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: 0.5,
        marginBottom: 2,
      }}
    >
      <Typography
        variant="p"
        className="title"
        sx={{
          color: '#000',
          fontWeight: 'bold',
          fontSize: '12px',
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="p"
        className="value"
        sx={{
          color: '#444A4A',
          fontWeight: 300,
          fontSize: '16px',
        }}
      >
        {value}
      </Typography>
    </Grid>
  );

  const trimShareholderText = (userdata) => {
    if (userdata && 'shareholder_name' in userdata) {
      return `${userdata?.shareholder_name.slice(0, 20)}...`;
    } else {
      return '';
    }
  };

  const formatDate = (newDate) => {
    if (newDate) {
      return new Intl.DateTimeFormat('UGX').format(newDate);
    } else {
      return 'N/A';
    }
  };

  const formatPrice = (price) => new Intl.NumberFormat('UGX').format(price);

  return (
    <>
      <ShareholderWrapper title="Landing Page" style={{backgroundColor: 'red'}} >
        <Grid
          container
          spacing={2}
          sx={{
            display: 'flex',
            paddingLeft: {
              lg: 2,
              md: 2,
              sm: 2,
              xs: -1
            },
            flexDirection: {
              xs: 'column',
              sm: 'column',
              lg: 'row',
            },
            mb: 20,
          }}
        >
          <Grid
            item
            sx={{
              display: 'flex',
              flexDirection: 'column',
              margin: '1.6em 0',
              flex: 1,
            }}
          >
            <Grid item xs={12} className={classes.detailsMajorContainer} sx={{maxWidth: '100vw'}}>
              <img
                src={require('../../assets/modified-images/header-mobile.png')}
                alt="shareholder cover"
                style={{ width: '100%' }}
              />
              <div className={classes.userIconContainer}>
                <AccountCircleIcon sx={{ ...icon }} />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.detailsMainContainer}
              sx={{
                maxWidth: '100vw',
                backgroundColor: '#FFFFFF',
                display: 'flex',
                minHeight: '100%',
                flexDirection: 'column',
                marginBottom: 10,
              }}
            >
              <Grid
                item
                xs={12}
                className="minorContainer"
                sx={{
                  maxWidth: '100vw',
                  display: 'flex',
                  flexDirection: 'row',
                  margin: '0 40px',
                  alignItems: 'center',
                  height: {
                    md: '10%',
                    sm: '16%',
                  },
                }}
              >
                <Grid item xs={9}>
                  <Typography
                    variant="h3"
                    sx={{
                      color: '#0A2240',
                      fontWeight: 500,
                      fontSize: 24,
                      display: { xs: 'none', sm: 'none', md: 'block' },
                    }}
                  >
                    {shareholderData?.shareholder_name}
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{
                      color: '#0A2240',
                      fontWeight: 500,
                      fontSize: 16,
                      display: {
                        xs: 'block',
                        sm: 'block',
                        md: 'none',
                        marginTop: 60,
                      },
                    }}
                  >
                    {trimShareholderText(shareholderData)}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      display: 'flex',
                      pt: 1,
                      pb: 1,
                      columnGap: 1,
                    }}
                  >
                    {shareholderData?.shares_held && (
                      <>
                        <PieChartSharpIcon />

                        <span
                          style={{
                            color: 'black',
                            fontSize: 16,
                            fontWeight: 'bold',
                          }}
                        >
                          {formatPrice(shareholderData?.shares_held)}
                        </span>
                        <span
                          style={{
                            color: 'black',
                            fontSize: 16,
                          }}
                        >
                          Shares
                        </span>
                      </>
                    )}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    alignSelf: 'stretch',
                    justifyContent: 'center',
                    paddingTop: 2,
                  }}
                >
                  <EditButton
                    variant="contained"
                    type="button"
                    onClick={() =>
                      navigate(
                        `/shareholder-edit-profile?shareholder_code=${shareholderData?.shareholder_code}`
                      )
                    }
                  >
                    Edit
                  </EditButton>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                className={classes.tabContainer}
                sx={{
                  maxWidth: '100vw',
                  display: 'flex',
                  flexDirection: 'column',
                  margin: {
                    sm: 0,
                    md: '0 30px',
                  },
                  width: {
                    xs: '100%',
                    sm: '90%',
                  },
                }}
              >
                <TabContext value={selectedTab}>
                  <Box sx={{ ...tabListDividerLine }}>
                    <TabList
                      onChange={handleChange}
                      aria-label="Shareholder API tabs"
                    >
                      <Tab label="Info" value="1" sx={{ ...tabStyles }} />
                      <Tab
                        label="Payment Channel"
                        value="2"
                        sx={{ ...tabStyles }}
                      />
                      <Tab
                        label="Account Manager"
                        value="3"
                        sx={{ ...tabStyles }}
                      />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <Grid
                      container
                      spacing={2}
                      className={classes.detailsGrid}
                      sx={{
                        display: 'flex',
                        flexDirection: {
                          xs: 'column',
                          sm: 'row',
                        },
                      }}
                    >
                      <GridListComponent
                        title="Shareholder SCD Number"
                        value={shareholder?.shareholder_scd_number}
                      />
                      <GridListComponent
                        title="Shareholder Certificate Number"
                        value={shareholder?.certificate_number}
                      />
                      <GridListComponent
                        title="Primary Phone Number"
                        value={shareholder?.primary_phone_number}
                      />
                      <GridListComponent
                        title="Secondary Phone Number"
                        value={shareholder?.secondary_phone_number}
                      />
                      <GridListComponent
                        title="Primary Email"
                        value={shareholder?.primary_email}
                      />
                      <GridListComponent
                        title="Secondary Email"
                        value={shareholder?.secondary_email}
                      />
                      <GridListComponent
                        title="Primary Address"
                        value={shareholder?.primary_address}
                      />
                      <GridListComponent
                        title="Secondary Address"
                        value={shareholder?.secondary_address}
                      />
                      <GridListComponent
                        title="Company?"
                        value={shareholder?.is_company === true ? 'Yes' : 'No'}
                      />
                      <GridListComponent
                        title="Broker Code"
                        value={shareholder?.broker_code}
                      />
                    </Grid>
                  </TabPanel>
                  <TabPanel value="2">
                    <Grid
                      container
                      spacing={2}
                      className={classes.detailsGrid}
                      sx={{
                        display: 'flex',
                        flexDirection: {
                          xs: 'column',
                          sm: 'row',
                        },
                      }}
                    >
                      <GridListComponent
                        title="Payment Type"
                        value={shareholder?.payment_mode?.payment_type}
                      />
                      <GridListComponent
                        title="Payment Mode Name"
                        value={shareholder?.payment_mode?.payment_mode_name}
                      />
                      <GridListComponent
                        title="Payment Account Name"
                        value={shareholder?.payment_account_name}
                      />
                      <GridListComponent
                        title="Payment Account Number"
                        value={shareholder?.payment_account_number}
                      />
                      <GridListComponent
                        title="Tax Rate"
                        value={shareholder?.country_tax_rate?.tax_rate}
                      />
                      <GridListComponent title="TIN" value={shareholder?.tin} />
                    </Grid>
                  </TabPanel>

                  <TabPanel value="3">
                    <Grid
                      container
                      spacing={2}
                      className={classes.detailsGrid}
                      sx={{
                        display: 'flex',
                        flexDirection: {
                          xs: 'column',
                          sm: 'row',
                        },
                      }}
                    >
                      <GridListComponent
                        title="Officer Code"
                        value={officerData?.officer_code}
                      />
                      <GridListComponent
                        title="Username"
                        value={officerData?.username}
                      />
                      <GridListComponent
                        title="First Name"
                        value={officerData?.first_name}
                      />
                      <GridListComponent
                        title="Last Name"
                        value={officerData?.last_name}
                      />
                      <GridListComponent
                        title="Email"
                        value={officerData?.primary_email}
                      />
                      <GridListComponent
                        title="Phone Number"
                        value={officerData?.primary_phone_number}
                      />
                      <GridListComponent
                        title="Address"
                        value={officerData?.primary_address}
                      />
                    </Grid>
                  </TabPanel>
                </TabContext>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              margin: {
                md: '1.6em 0',
                sm: '0',
              },
              flex: {
                xs: 1,
                sm: 1,
                lg: 0.5,
              },
            }}
          >
            <Paper elevation={1}>
              <Box
                className={classes.activityContainer}
                sx={{
                  bgcolor: (theme) =>
                    theme.palette.mode === 'dark' ? '#101010' : '#ffffff',
                  color: (theme) =>
                    theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                }}
              >
                <Typography variant="h5" style={{ margin: '16px 14px 0' }}>
                  Activity
                </Typography>
                {revisions?.map((revision) => (
                  <ActivityCard
                    key={revision.required_revision_number}
                    {...revision}
                  />
                ))}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </ShareholderWrapper>
    </>
  );
};

export default ShareholderLandingPage;
