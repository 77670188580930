
import { useContext } from 'react'
import NavigationContext from '../../store/NavigationContext'
import { Card, CardContent, Typography } from '@mui/material'
import AccountOfficerRegForm from '../../components/forms/CreateAccountOfficerForm'
import MiniDrawer from '../../components/newSideDrawer/miniSideDrawer'


function CreateAccountOfficerPage(props) {
  const navigationContext = useContext(NavigationContext)
  const { sidebar } = navigationContext
  return (
    <MiniDrawer>
      <Card>
               <CardContent>
                 <Typography variant='h6' >Add new account officer</Typography>
                 <AccountOfficerRegForm />
               </CardContent>
             </Card>
    </MiniDrawer>
  )
}

export default CreateAccountOfficerPage;