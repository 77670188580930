import ListAccountOfficers from "../../components/tables/ListAccountOfficersTable";
import MiniDrawer from "../../components/newSideDrawer/miniSideDrawer";
import FlyTable from "../../components/tables/FlyTable";
import { checkAuthorities } from "../../service/securityService";
import { getAllAccountOfficers } from "../../service/userService";
import { setAccountOfficers } from "../../store/userSlice";
import { useEffect, useState } from "react";

const ListAccountOfficersPage = () => {
    const [accountOfficers, setAccountOfficers] = useState([]);
    const [paginationDetails, setPaginationDetails] = useState({});
    const headers = ['Officer Code', 'Full Name', 'Email', 'Phone', 'Shareholder', 'Active', 'Actions']
    const actions = ['View', 'Edit', 'Delete']
    useEffect(() => {
      checkAuthorities({
        can_create: "ACCOUNT_OFFICER_MODULE:CAN_CREATE",
        can_read: "ACCOUNT_OFFICER_MODULE:CAN_READ",
        can_update: "ACCOUNT_OFFICER_MODULE:CAN_UPDATE",
        can_delete: "ACCOUNT_OFFICER_MODULE:CAN_DELETE",
        can_export: "ACCOUNT_OFFICER_MODULE:CAN_EXPORT",
        can_approve: "ACCOUNT_OFFICER_MODULE:CAN_APPROVE",
      });
      getAllAccountOfficers({ size: 50, pageIndex: 0 }).then(
        (response) => {
            console.log('Account officers: ', response);
            setPaginationDetails({
              total_elements: response.data.total_elements,
              total_pages: response.data.total_pages,
              records_displayed: response.data.records_displayed,
              page_index: response.data.page_index,
            })
            setAccountOfficers(response.data.data.map((element) => ({
                id: element.officer_code,
                Name: element.first_name + ' ' + element.last_name ,
                primary_email: element.primary_email,
                primary_phone_number: element.primary_phone_number,
                shareholder: element.shareholder.shareholder_name,
                status: element.enabled.toString()
              })))
        //   dispatch(setAccountOfficers(response.data.data));
        //   setBackendPagination({
        //     page_Index: response.data.page_index,
        //     total_pages: response.data.total_pages,
        //     records_displayed: response.data.records_displayed,
        //     total_elements: response.data.total_elements,
        //   });
        //   dispatch(
        //     setAccountOfficerBackendPagination({
        //       page_Index: response.data.page_index,
        //       total_pages: response.data.total_pages,
        //       records_displayed: response.data.records_displayed,
        //       total_elements: response.data.total_elements,
        //     })
        //   );
          // dispatch(setBackendPagination(response));
        }
      );
    }, []);
  
    return (
        <MiniDrawer>
            <ListAccountOfficers />
        </MiniDrawer>
    )

}
export default ListAccountOfficersPage;