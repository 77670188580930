import { useContext } from "react";
import { useSelector } from "react-redux";
import AdministratorDetailsCard from "../../components/AdministratorDetailsCard";
import NavigationContext from "../../store/NavigationContext";
import "./AdministratorProfilePage.css";
import MiniDrawer from "../../components/newSideDrawer/miniSideDrawer";
import AlertModalTest from "../../components/alertModalTest";

function AdministratorProfilePage(props) {
  const navigationContext = useContext(NavigationContext);
  const { sidebar } = navigationContext;
  const alert = useSelector((state) => state.alert);
  return (
    <MiniDrawer>
      {alert.open === true ? (
        <AlertModalTest
          sx={{ margin: "20px", width: "50%", align: "right" }}
        ></AlertModalTest>
      ) : (
        <></>
      )}
      <AdministratorDetailsCard />
    </MiniDrawer>
  )
}
export default AdministratorProfilePage;
