import { API, dashboardApi, testApi } from '../config/API';

export const getdashboardMetrics = async () => {
  return dashboardApi.get(`actuator/dashboard`);
};

//country calls
export const getAllCountries = async (props) => {
  return testApi.get(`app-settings/countries?size=${props.size}&page_index=${props.pageIndex}&direction=asc&field_name=sequence`);
};
export const getCountryByIsoCode = async (props) => {
  return testApi.get(`app-settings/countries/${props.iso_code}`);
};
export const updateCountry = async (props) => {
  return testApi.put(`app-settings/countries/${props.iso_code}`, props);
};
export const deleteCountry = async (props) => {
  return testApi.delete(`app-settings/countries/${props.iso_code}`);
};

//tax rate calls
export const addTaxRate = async (props) => {
  return testApi.post(`app-settings/tax-rates`, props);
};
export const getAllTaxRates = async (props) => {
  return testApi.get(`app-settings/tax-rates?size=${props.size}&page_index=${props.pageIndex}&direction=asc&field_name=tax_rate_code`);
};
export const updateTaxRate = async (props) => {
  return testApi.put(`app-settings/tax-rates/${props.tax_rate_code}`, props);
};
export const deleteTaxRate = async (props) => {
  return testApi.delete(`app-settings/tax-rates/${props.tax_rate_code}`);
};

//pay mode calls
export const addPayMode = async (props) => {
  return testApi.post(`app-settings/paymentmodes`, props);
};
export const getAllPayModes = async (props) => {
  return testApi.get(`app-settings/paymentmodes?size=${props.size}&page_index=${props.pageIndex}&direction=asc&field_name=payment_mode_code`);
};
export const getPayModeById = async (props) => {
  return testApi.get(`app-settings/paymentmodes/${props.payment_mode_code}`);
};
export const getPayModeByType = async (props) => {
  return testApi.get(`app-settings/paymentmodes/types?size=${props.size}&page_index=${props.pageIndex}&direction=asc&field_name=payment_mode_code&type=${props.type}`);
};
export const updatePayModeById = async (props) => {
  return testApi.put(`app-settings/paymentmodes/${props.payment_mode_code}`, props);
};

export const getAllSystemModules = async (props) => {
  return testApi.get(`security/listmodules?size=${props.size}&page_index=${props.pageIndex}&direction=asc&field_name=module_code`);
};
export const getAllBrokers = async (props) => {
  return testApi.get(`/brokers?size=${props.size}&page_index=${props.pageIndex}&direction=desc&field_name=broker_code`);
};
export const getpagnatedTaxList = async () => {
  try {
    const resp = await API.get(
      `app-settings/tax-rates?size=100&page_index=0&direction=asc&field_name=tax_rate_code`
    );
    return resp.data;
  } catch (error) {
    return error.message;
  }
};
