import { Card, CardContent, CardHeader } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { getShareholderDividends } from "../service/userService";
import DividendsStatus from "./DividendsActions/DividendsStatus";
import DataTable from "./tables/DataTable";

const ShareholderDividendsTable = () => { 
    const [shareholderDividend, setShareholderDividend] = useState([]);
    const user = useSelector(state => state.user); 
    const locale = useParams();

    useEffect(() => {
        getShareholderDividends({
            shareholderCode: locale.shareholder_code,
            size: 20,
            pageIndex: 0
        }).then(response => {
        setShareholderDividend(response.data.data);
      })
    }, []);

    
    const dividendsTableStyles = {
        height: "250px",

        marginBottom: "20px",
        backgroundColor: "white",
    };

    const columns = [
        { field: "dividend_year", headerName: "Financial Year", width: 150 },
        { field: "paid_on", headerName: "Date of Payment", width: 150 },
        { field: "actual_net_amount", headerName: "Amount Paid", width: 150 },
        { field: "payment_ref_number", headerName: "Transaction ID", width: 150 },
        { field: "payment_mode_name", headerName: "Paid Via", width: 150 },
        { field: "payment_account_number", headerName: "Account Paid To", width: 150 },
        { field: "payment_status", headerName: "Transaction Status", width: 150, renderCell: (params) => <DividendsStatus {...{ params }} />},
    ];
    return (
        <Card sx={{ marginTop: "10px" }}>
        <CardHeader
        title="Dividends"
        sx={{
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "20px",
            color: " #000000",
        }}
        />
        <CardContent>
        <DataTable
            rows={shareholderDividend}
            columns={columns}
            loading={!shareholderDividend}
            getRowId={(row) => row.payment_ref_number}
            sx={dividendsTableStyles}
        />
        </CardContent>
        </Card>
    )
}
export default ShareholderDividendsTable;