import { useContext, useEffect, useState } from 'react';
import NavigationContext from '../../store/NavigationContext'
import MiniDrawer from '../../components/newSideDrawer/miniSideDrawer';
import FileEntries from '../../components/FileEntries';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getFileEntriesByFileId } from '../../service/fileService';
import FlyTable from '../../components/tables/FlyTable';
import { setViolations } from '../../store/fileSlice';
import { Skeleton } from '@mui/material';


  
const requiredResults = 20;
function FileEntriesPage() {
  const navigationContext = useContext(NavigationContext)
  const { sidebar } = navigationContext

  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const locale = useParams();
  const [entries, setEntries] = useState([]);

  const [fileEntries, setFileEntries] = useState([]);
  const [paginationDetails, setPaginationDetails] = useState({});
  const headers = ['SCD/COM Number', 'Violation', 'Email', 'Shares', 'pay code', 'tax code', 'Status', 'Actions']
  const actions = ['View']

  
  useEffect(() => {
    setLoading(true);
    console.log('Loading: ', loading);
  getFileEntriesByFileId({ fileId:locale.file_id, size: requiredResults, pageIndex: 0 }).then(
      (response) => {
        console.log('Our file entries in components/FileEntries.js: ', response);
        // setEntries(response.data.data)
        // setFileEntries(response.data.data)
        saveViolations(response.data.data);
        setFileEntries(response.data?.data?.map((element) => ({
          id: element.content.shareholder_scd_number ? element.content.shareholder_scd_number : element.content.certificate_number,
          shareholder_name: element.content.shareholder_name,
          primary_email: element.content.primary_email,
          shares: element.content.shares_held.toString(),
          pay_code: element.content.payment_mode_code,
          tax_code: element.content.tax_rate_code,
          // violations: element.content.violations,
          status: element.file_upload_status
        })))
        setLoading(false);
      }
    );
  }, []);

  const saveViolations = (entries) => {
    let allViolations = [];
    for (let index = 0; index < entries.length; index++) {
      const element = entries[index];
      if (element.violations.length > 0) {
        console.log('Violations: ', element);
        let entry = {id: element.content.shareholder_scd_number ? element.content.shareholder_scd_number : element.content.certificate_number}
        let violations = element.violations
        for (let index = 0; index < violations.length; index++) {
          const element = violations[index];
          entry = {...entry, violation: element.message}
          allViolations.push(entry)
          console.log('Entry: ',entry);          
        }
      }
      
    }    
    console.log('allViolations: ', allViolations);
    dispatch(setViolations(allViolations));

  }
  
  return (
    <MiniDrawer >
      {/* <FileEntries /> */}
      {loading ? 
          <Skeleton
            variant="rectangle"
            height={"80vh"}
            width={"80vw"}
          ></Skeleton> : 
          <FlyTable
            header='File Entries'
            data={fileEntries}
            headers={headers}
            actions={actions}
            requiredResults={requiredResults}
            paginationDetails={paginationDetails} />
      }
      
    </MiniDrawer>
  )
}

export default FileEntriesPage;